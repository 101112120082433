import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { IonCardContent, IonContent, IonIcon, IonItem } from '@ionic/react';
import Button from 'components/Button/Button';

export const CardContent = styled(IonCardContent)`
  img {
    width: 500px;
    height: 300px;
  }
`;

export const DocumentIcon = styled(IonIcon)`
  margin-right: 12px;
`;

export const ButtonIcon = styled(IonIcon)`
  margin-right: 10px;
`;

export const SaveBeamButton = styled(Button)<{
  displayButton?: boolean;
}>`
  margin: 16px;
  display: ${({ displayButton }) => (displayButton ? 'block' : 'none')};
`;

export const Content = styled(IonContent)`
  --padding-end: 0;
  --padding-start: 0;
`;

export const PDFButton = styled(PDFDownloadLink)`
  text-decoration: none;
`;

export const WhiteSpace = styled.div`
  height: 170px;
`;

export const PDFLink = styled(Button)`
  display: inline-block;
  cursor: pointer;
  color: #428cff;
`;

export const DocumentContainer = styled(IonItem)`
  display: none;
`;

export const ButtonsCardContent = styled(IonCardContent)`
  text-align: center;
`;
