import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import ReactGa from 'react-ga';
import { addOutline, closeCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportProps, SupportType } from '../../interfaces';

import * as S from './style';

interface Props {
  beam?: number;
  supports: SupportProps[];
  setSupports: (supports: SupportProps[]) => void;
  showMultipleSupportAlert: boolean;
  setShowMultipleSupportAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultipleSupportsModal: React.FC<Props> = ({
  beam,
  showMultipleSupportAlert,
  setShowMultipleSupportAlert,
  supports,
  setSupports
}) => {
  const { t } = useTranslation();
  const [startPosition, setStartPosition] = useState<number>();
  const [supportType, setSupportType] = useState<SupportType>();
  const [spacing, setSpacing] = useState<number>();

  const allowSupport = (): boolean => {
    let isValid = true;

    if (
      startPosition === undefined ||
      (beam && startPosition > beam) ||
      startPosition < 0
    ) {
      isValid = false;
    }
    if (spacing === undefined || spacing === 0 || spacing <= 0) {
      isValid = false;
    }

    return isValid;
  };

  const onDidDismiss = (): void => {
    setShowMultipleSupportAlert(false);
    setStartPosition(undefined);
    setSpacing(undefined);
    setSupportType(undefined);
  };

  return (
    <IonModal onDidDismiss={onDidDismiss} isOpen={showMultipleSupportAlert}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('multiple supports')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>{t('type of support')}</IonLabel>
            <IonSelect
              interface="popover"
              onIonChange={ev => setSupportType(ev.detail.value)}
            >
              <IonSelectOption value="pin">{t('pin')}</IonSelectOption>
              <IonSelectOption value="roller">{t('roller')}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t('start position')}</IonLabel>
            <IonInput
              value={startPosition}
              min="0"
              max={`${beam}`}
              type="number"
              inputMode="numeric"
              onIonChange={(e: any) => setStartPosition(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t('spacings')}</IonLabel>
            <IonInput
              value={spacing}
              min="0"
              step="1"
              type="number"
              inputMode="numeric"
              onIonChange={(e: any) => setSpacing(e.detail.value!)}
            ></IonInput>
          </IonItem>
        </IonList>
      </IonContent>
      <S.Footer>
        <IonButton color="danger" onClick={onDidDismiss}>
          <IonIcon slot="start" icon={closeCircleOutline} />
          {t('cancel')}
        </IonButton>
        <IonButton
          onClick={(): void => {
            const isValid = allowSupport();
            if (
              beam &&
              isValid &&
              startPosition !== undefined &&
              supportType !== undefined &&
              spacing !== undefined
            ) {
              let iteractions = Math.floor((beam - startPosition) / spacing);
              let supportPosition = startPosition;
              let newSupports: SupportProps[] = [];

              for (let i = 0; i <= iteractions; i++) {
                newSupports.push({
                  position: Math.round(supportPosition * 100) / 100,
                  type: supportType,
                  id: Math.floor(Math.random() * Date.now()),
                  reactionForce: 0,
                  reactionMoment: 0,
                  force: 0,
                  moment: 0
                });
                supportPosition = Number(supportPosition) + Number(spacing);
              }

              setSupports([...supports, ...newSupports]);
            } else {
              alert(t('beam error four'));
            }
            onDidDismiss();
            ReactGa.event({
              category: 'XBeam - Multiple Support Modal',
              action: 'User pressed "add" button'
            });
          }}
        >
          <IonIcon slot="start" icon={addOutline} />
          {t('add')}
        </IonButton>
      </S.Footer>
    </IonModal>
  );
};

export default MultipleSupportsModal;
