import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  addOutline,
  closeCircleOutline,
  pencilOutline,
  trashOutline
} from 'ionicons/icons';
import ReactGa from 'react-ga';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'providers/AuthProvider';
import { updateUser, getMaterials } from 'providers/UserProvider';
import * as S from './style';

interface Props {
  hasBeam: boolean;
  showBeamAlert: boolean;
  initialValue?: number;
  initialMaxDeflection?: number;
  inertiaMomentInitialValue: string;
  elasticModulusInitialValue: string;
  onDidDismiss: any;
  activeSectionId?: string;
  setActiveSectionId: (id: string) => void;
  setBeamValue: (beam: number) => void;
  setMaxDeflectionValue: (deflection: number | undefined) => void;
  setPropertiesValue: (
    inertia: string,
    elastic: string,
    area?: string,
    maxMoment?: string,
    maxReaction?: string,
    maxShearForce?: string
  ) => void;
  resetBeam: () => void;
}

interface Section {
  name: string;
  id?: string;
  inertiaMoment: string;
  elasticModulus: string;
}

const BeamModal: React.FC<Props> = ({
  hasBeam,
  showBeamAlert,
  initialValue,
  initialMaxDeflection,
  elasticModulusInitialValue,
  inertiaMomentInitialValue,
  onDidDismiss,
  activeSectionId,
  setActiveSectionId,
  resetBeam,
  setMaxDeflectionValue,
  setBeamValue,
  setPropertiesValue
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [maxDeflection, setmaxDeflection] = useState<number | undefined>();
  const [value, setValue] = useState<number | undefined>(initialValue);
  const [propertyType, setPropertyType] = useState<'manual' | 'saved'>(
    'manual'
  );
  const [sectionName, setSectionName] = useState<string>('');
  const [activedSection, setActivedSection] = useState<{
    name: string;
    id?: string;
    inertiaMoment: string;
    elasticModulus: string;
    area?: string;
    maxMoment?: string;
    maxReaction?: string;
    maxShearForce?: string;
  }>();
  const [inertiaMoment, setInertiaMoment] = useState<string>(
    inertiaMomentInitialValue
  );
  const [elasticModulus, setElasticModulus] = useState<string>(
    elasticModulusInitialValue
  );
  const [materials, setMaterials] = useState<Section[]>([]);

  useEffect((): void => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect((): void => {
    setmaxDeflection(initialMaxDeflection);
  }, [initialMaxDeflection]);

  useEffect((): void => {
    if (user) {
      getMaterials(user?.uid).then(res => {
        const data: { materials: Section[] | undefined } = res.data();

        if (data.materials) {
          setMaterials(data.materials);
          setActivedSection(data.materials[0]);
        }
      });
    }
  }, [user]);

  useEffect((): void => {
    const filteredSection = materials.find(
      (material: Section) => material.id === activeSectionId
    );
    const activeSection =
      materials !== undefined ? filteredSection : materials[0];
    const propertyType =
      materials !== undefined && filteredSection ? 'saved' : 'manual';

    if (activeSection) {
      setActivedSection(activeSection);
    }
    setPropertyType(propertyType);
  }, [activeSectionId, materials]);

  const handleSaveSection = (): void => {
    try {
      if (
        parseFloat(elasticModulus) > 0 &&
        parseFloat(inertiaMoment) > 0 &&
        user
      ) {
        const material = {
          name: sectionName,
          id: new Date().toISOString(),
          inertiaMoment,
          elasticModulus
        };

        const materialsFiltered = [material, ...materials];

        updateUser(user?.uid, {
          materials: materialsFiltered
        }).then(() => {
          setMaterials(materialsFiltered);
          setActivedSection(materialsFiltered[0]);
        });

        setSectionName('');
        alert(t('beam material successfully'));
      } else {
        // TODO: Error handling
      }
    } catch (error) {
      alert(t('beam material unsuccessfully'));
    }
  };

  const handleSectionChange = (name: string): void => {
    const activeSection = materials.find(material => material.name === name);

    setActivedSection(activeSection);
  };

  return (
    <IonModal
      swipeToClose={true}
      onDidDismiss={(): void => {
        onDidDismiss(false);
        setInertiaMoment(inertiaMomentInitialValue);
        setElasticModulus(elasticModulusInitialValue);
        setmaxDeflection(initialMaxDeflection);
        setValue(initialValue);
      }}
      isOpen={showBeamAlert}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{hasBeam ? t('edit beam') : t('add beam')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItemGroup>
          <IonItemDivider>
            <IonLabel>Beam</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>{t('length')} (m)</IonLabel>
            <IonInput
              value={value}
              type="number"
              inputMode="numeric"
              placeholder={t('enter the length')}
              onIonChange={(e: any) => setValue(e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Max. Deflection</IonLabel>
            </IonItemDivider>
          </IonItemGroup>
          <IonItem>
            <S.Label>Max Span / </S.Label>
            <IonInput
              value={maxDeflection}
              type="number"
              inputMode="numeric"
              onIonChange={(e: any) => setmaxDeflection(e.detail.value!)}
            ></IonInput>
          </IonItem>
        </IonItemGroup>
        <IonItemGroup>
          <IonItemDivider>
            <IonLabel>{t('properties')}</IonLabel>
          </IonItemDivider>
          <IonRadioGroup
            value={propertyType}
            onIonChange={e => setPropertyType(e.detail.value)}
          >
            <IonItem>
              <IonLabel>{t('section-manual')}</IonLabel>
              <IonRadio slot="start" value="manual" />
            </IonItem>
            <IonItem disabled={user === null}>
              <IonLabel>
                {user === null
                  ? t('section-saved-login-required')
                  : t('section-saved')}
              </IonLabel>
              <IonRadio slot="start" value="saved" />
            </IonItem>
          </IonRadioGroup>
        </IonItemGroup>
        {propertyType === 'manual' && (
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{t('section-manual')}</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel position="floating">
                {t('elastic modulus (GPa)')}
              </IonLabel>
              <IonInput
                value={elasticModulus}
                type="number"
                inputMode="numeric"
                min="0"
                onIonChange={(e: any): void =>
                  setElasticModulus(e.detail.value!)
                }
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">
                {t('moment of inertia (mm4)')}
              </IonLabel>
              <IonInput
                value={inertiaMoment}
                type="number"
                inputMode="numeric"
                min="0"
                onIonChange={(e: any): void =>
                  setInertiaMoment(e.detail.value!)
                }
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                value={sectionName}
                type="text"
                disabled={user === null}
                placeholder={t('section-name')}
                onIonChange={(e: any) => setSectionName(e.detail.value!)}
              ></IonInput>
              <IonButton
                disabled={user === null || sectionName === ''}
                onClick={(): void => {
                  handleSaveSection();
                  ReactGa.event({
                    category: 'XBeam - Beam Modal',
                    action: 'User pressed "save section" button'
                  });
                }}
              >
                <IonIcon slot="start" icon={addOutline} />
                {t('section-save')}
              </IonButton>
            </IonItem>
          </IonItemGroup>
        )}
        {propertyType === 'saved' && activedSection && (
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{t('section-saved')}</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel color="tertiary">{t('section-name')}</IonLabel>
              <IonSelect
                value={activedSection.name}
                okText="Okay"
                cancelText="Cancel"
                onIonChange={e => {
                  handleSectionChange(e.detail.value);
                }}
              >
                {materials.map(material => (
                  <IonSelectOption
                    key={material.id || material.name}
                    value={material.name}
                  >
                    {material.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>
                {t('elastic modulus (GPa)')}:
                <b>{activedSection?.elasticModulus}</b>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                {t('moment of inertia (mm4)')}:
                <b>{activedSection?.inertiaMoment}</b>
              </IonLabel>
            </IonItem>
          </IonItemGroup>
        )}
        {propertyType === 'saved' && !activedSection && (
          <S.NoSections color="primary">{t('sections-no')}</S.NoSections>
        )}
      </IonContent>
      <S.Footer>
        {hasBeam ? (
          <>
            <IonButton
              color="primary"
              onClick={(): void => {
                setInertiaMoment(inertiaMomentInitialValue);
                setElasticModulus(elasticModulusInitialValue);

                onDidDismiss();
              }}
            >
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              color="danger"
              onClick={(): void => {
                resetBeam();
                onDidDismiss();
                ReactGa.event({
                  category: 'XBeam - Beam Modal',
                  action: 'User pressed "remove" button'
                });
              }}
            >
              <IonIcon slot="start" icon={trashOutline} />
              {t('remove')}
            </IonButton>
            <IonButton
              color="tertiary"
              onClick={(): void => {
                const elastic =
                  propertyType === 'saved'
                    ? activedSection?.elasticModulus
                    : elasticModulus;
                const inertial =
                  propertyType === 'saved'
                    ? activedSection?.inertiaMoment
                    : inertiaMoment;
                const area =
                  propertyType === 'saved' ? activedSection?.area : '0';
                const maxMoment =
                  propertyType === 'saved' ? activedSection?.maxMoment : '0';
                const maxReaction =
                  propertyType === 'saved' ? activedSection?.maxReaction : '0';
                const maxShearForce =
                  propertyType === 'saved'
                    ? activedSection?.maxShearForce
                    : '0';

                if (
                  value &&
                  elastic &&
                  parseFloat(elastic) > 0 &&
                  inertial &&
                  parseFloat(inertial) > 0
                ) {
                  setPropertiesValue(
                    inertial,
                    elastic,
                    area,
                    maxMoment,
                    maxReaction,
                    maxShearForce
                  );
                  setBeamValue(value);
                  setMaxDeflectionValue(maxDeflection);
                  if (propertyType === 'saved' && activedSection?.id) {
                    setActiveSectionId(activedSection.id);
                  }
                  if (propertyType === 'manual' && activedSection?.id) {
                    setActiveSectionId('0');
                  }
                } else {
                  alert(t('beam error four'));
                }

                onDidDismiss();
                ReactGa.event({
                  category: 'XBeam - Beam Modal',
                  action: 'User pressed "edit" button'
                });
              }}
            >
              <IonIcon slot="start" icon={pencilOutline} />
              {t('edit')}
            </IonButton>
          </>
        ) : (
          <>
            <IonButton color="danger" onClick={onDidDismiss}>
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              onClick={(): void => {
                const elastic =
                  propertyType === 'saved'
                    ? activedSection?.elasticModulus
                    : elasticModulus;
                const inertial =
                  propertyType === 'saved'
                    ? activedSection?.inertiaMoment
                    : inertiaMoment;
                const area =
                  propertyType === 'saved' ? activedSection?.area : '0';
                const maxMoment =
                  propertyType === 'saved' ? activedSection?.maxMoment : '0';
                const maxReaction =
                  propertyType === 'saved' ? activedSection?.maxReaction : '0';
                const maxShearForce =
                  propertyType === 'saved'
                    ? activedSection?.maxShearForce
                    : '0';

                if (
                  value &&
                  elastic &&
                  parseFloat(elastic) > 0 &&
                  inertial &&
                  parseFloat(inertial) > 0
                ) {
                  setPropertiesValue(
                    inertial,
                    elastic,
                    area,
                    maxMoment,
                    maxReaction,
                    maxShearForce
                  );
                  setBeamValue(value);
                  setMaxDeflectionValue(maxDeflection);
                  if (propertyType === 'saved' && activedSection?.id) {
                    setActiveSectionId(activedSection.id);
                  }
                  if (propertyType === 'manual' && activedSection?.id) {
                    setActiveSectionId('0');
                  }
                } else {
                  alert(t('beam error four'));
                }

                onDidDismiss();
                ReactGa.event({
                  category: 'XBeam - Beam Modal',
                  action: 'User pressed "add" button'
                });
              }}
            >
              <IonIcon slot="start" icon={addOutline} />
              {t('add')}
            </IonButton>
          </>
        )}
      </S.Footer>
    </IonModal>
  );
};

export default BeamModal;
