import { IonContent, IonItem } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ContactForm from 'components/ContactForm/ContactForm';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import StoreCards from 'components/StoreCards/StoreCards';

const Contact = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('contact')} - XBeam</title>
        <meta name="description" content={t('meta description - contact')} />
      </Helmet>
      <Header />
      <IonContent className="ion-padding">
        <IonItem lines="none">
          <h1>{t('contact')}</h1>
        </IonItem>
        <ContactForm />
        <StoreCards />
      </IonContent>
      <Footer />
    </>
  );
};

export default Contact;
