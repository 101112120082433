import { IonCard } from '@ionic/react';
import styled from 'styled-components';

export const Title = styled.h1`
  margin: 16px;
`;

export const Card = styled(IonCard)`
  text-align: center;
  padding: 12px;
  margin: 16px;
`;

export const Image = styled.img`
  width: 256px;
  height: 256px;
`;
