import React from 'react';
import { useTranslation } from 'react-i18next';
import ScatterChartCard from '../../components/ScatterChartCard/ScatterChartCard';
import * as S from './style';

interface Props {
  maxMomentValue?: string;
  maxShearForceValue?: string;
  maxAllowedDeflection?: string;
  global_Q: number[][];
  global_M: number[][];
  global_V: number[][];
  setImages: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        image: string;
      }[]
    >
  >;
}

const Cards: React.FC<Props> = ({
  global_Q,
  global_M,
  global_V,
  maxMomentValue,
  maxShearForceValue,
  maxAllowedDeflection,
  setImages
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ScatterChartCard
        title={t('cutting effort')}
        data={global_Q}
        swlValue={maxShearForceValue}
        setImages={setImages}
      />
      <ScatterChartCard
        title={t('bending moment')}
        data={global_M}
        swlValue={maxMomentValue}
        setImages={setImages}
        reversed={true}
      />
      <ScatterChartCard
        title={t('displacements')}
        data={global_V}
        swlValue={maxAllowedDeflection}
        setImages={setImages}
      />
      <S.WhiteSpace />
    </>
  );
};

export default Cards;
