import { SupportProps, LoadProps, DistributesProps } from '../../interfaces';
import { multiply, inv } from 'mathjs';

// const E = 20; //Elastic Modulus (GPa);
// const I = 225000000; //Moment of Inertia (mm4);
// const i = 0.05; //Will break the Beam in a L / (i/2) nodes. lower this value higher is the memory consuming and calcs precision..
const i = 0.01; //beam segments length (m)

class Node {
  position;
  force;
  moment;
  supportType;
  reactionForce;
  reactionMoment;

  constructor(
    position: any,
    force: any,
    moment: any,
    supportType: any,
    reactionForce: any,
    reactionMoment: any
  ) {
    this.position = position;
    this.force = force;
    this.moment = moment;
    this.supportType = supportType;
    this.reactionForce = reactionForce;
    this.reactionMoment = reactionMoment;
  }
}

interface CalculateBeam {
  E: number;
  I: number;
  L: number;
  loads: LoadProps[];
  sups: SupportProps[];
  distributes: DistributesProps[];
}

export const calculateBeam = ({
  loads,
  distributes,
  sups,
  E,
  I,
  L
}: CalculateBeam): {
  global_Q: number[][];
  global_M: number[][];
  global_V: number[][];
  beamSupports: any;
} => {
  const distributedLoads = distributes;
  const pointLoads = loads;
  const EI = E * 10 ** 6 * I * 10 ** -12;
  let supports = sups;
  let globalNodes: string | any[] = []; //this will save the nodes has been created for each load analysed individually

  function FixedSupportChecker(): string {
    let hasFixed;
    //the case there is only 1 support. (so, it is a fixed support)
    if (supports.length === 1) {
      return (hasFixed = supports[0].position === 0 ? 'left' : 'right');
    }

    //there are 2 or more supports, then..
    if (
      supports[0].type === 'fixed' &&
      supports[supports.length - 1].type === 'fixed'
    )
      hasFixed = 'both';
    else if (supports[0].type === 'fixed') hasFixed = 'left';
    else if (supports[supports.length - 1].type === 'fixed') hasFixed = 'right';
    else hasFixed = 'none';

    return hasFixed;
  }

  function getGlobalNodes(globalNodes: any[]) {
    //firstly, place the support's globalNodes
    for (let j = 0; j < supports.length; j++) {
      globalNodes.push(
        new Node(supports[j].position, 0, 0, supports[j].type, 0, 0)
      );
    }

    //now, place the point loads globalNodes
    for (let j = 0; j < pointLoads.length; j++) {
      let existingNode = false;
      for (let m = 0; m < globalNodes.length; m++) {
        if (globalNodes[m].position === pointLoads[j].position) {
          //globalNodes[m].force = pointLoads[j].force;
          //globalNodes[m].moment = pointLoads[j].moment;
          existingNode = true;
        }
      }
      if (!existingNode) {
        globalNodes.push(new Node(pointLoads[j].position, 0, 0, 'n/a', 0, 0));
      }
    }

    //now, place the dist loads globalNodes
    for (let j = 0; j < distributedLoads.length; j++) {
      let existingStartNode = false,
        existingEndNode = false;
      for (let m = 0; m < globalNodes.length; m++) {
        if (globalNodes[m].position === distributedLoads[j].startPosition) {
          existingStartNode = true;
        }
        if (globalNodes[m].position === distributedLoads[j].endPosition) {
          existingEndNode = true;
        }
      }
      if (!existingStartNode) {
        globalNodes.push(
          new Node(distributedLoads[j].startPosition, 0, 0, 'n/a', 0, 0)
        );
      }
      if (!existingEndNode) {
        globalNodes.push(
          new Node(distributedLoads[j].endPosition, 0, 0, 'n/a', 0, 0)
        );
      }
    }

    globalNodes.sort((node1: any, node2: any) =>
      node1.position > node2.position ? 1 : -1
    );

    //it is time to check whether it has globalNodes placed at the ends of the beam or not. Otherwise, add them.
    if (globalNodes[0].position > 0) {
      globalNodes.unshift(new Node(0, 0, 0, 'n/a', 0, 0));
    }

    if (globalNodes[globalNodes.length - 1].position < L) {
      globalNodes.push(new Node(L, 0, 0, 'n/a', 0, 0));
    }
  }

  function NodesForPL(nodes: any, thisLoad: LoadProps) {
    let tempLoad = true;

    //firstly, place the support's nodes
    for (let f = 0; f < nodes.length; f++) {
      if (thisLoad.position === nodes[f].position) {
        nodes[f].force = thisLoad.force;
        nodes[f].moment = thisLoad.moment;
        tempLoad = false;
      }
    } //for
    if (tempLoad) {
      nodes.push(
        new Node(
          thisLoad.position,
          thisLoad.force,
          thisLoad.moment,
          'n/a',
          0,
          0
        )
      );
    } // if
    nodes.sort((node1: any, node2: any) =>
      node1.position > node2.position ? 1 : -1
    );
  }

  function StifnessMatrix(nodes: string | any[]) {
    const K = new Array(nodes.length * 2)
      .fill(0)
      .map(() => new Array(nodes.length * 2).fill(0)); //zero matrix constructor
    let y = 0;
    for (let x = 0; x < nodes.length - 1; x++) {
      //Adding values to the stifness matrix
      let barLength = nodes[x + 1].position - nodes[x].position;
      K[0 + y][0 + y] += 12 / barLength ** 3;
      K[0 + y][1 + y] += -6 / barLength ** 2;
      K[0 + y][2 + y] += -12 / barLength ** 3;
      K[0 + y][3 + y] += -6 / barLength ** 2;
      K[1 + y][0 + y] += -6 / barLength ** 2;
      K[1 + y][1 + y] += 4 / barLength;
      K[1 + y][2 + y] += 6 / barLength ** 2;
      K[1 + y][3 + y] += 2 / barLength;
      K[2 + y][0 + y] += -12 / barLength ** 3;
      K[2 + y][1 + y] += 6 / barLength ** 2;
      K[2 + y][2 + y] += 12 / barLength ** 3;
      K[2 + y][3 + y] += 6 / barLength ** 2;
      K[3 + y][0 + y] += -6 / barLength ** 2;
      K[3 + y][1 + y] += 2 / barLength;
      K[3 + y][2 + y] += 6 / barLength ** 2;
      K[3 + y][3 + y] += 4 / barLength;
      y += 2;
    }
    return K;
  }

  function LoadsArray(nodes: string | any[]) {
    const S = new Array(nodes.length * 2)
      .fill(0)
      .map(() => new Array(1).fill(0)); //zero matrix constructor
    let index = 0;
    for (let x = 0; x < nodes.length; x++) {
      //Adding values to the Load array
      S[index][0] += nodes[x].force;
      S[index + 1][0] += nodes[x].moment;
      index += 2;
    } //for
    return S;
  }

  function MatrixShortener(
    K: any[][],
    S: any[][],
    hasFixed: string,
    nodes: string | any[]
  ) {
    let Kr = JSON.parse(JSON.stringify(K)); //a true copy of the K matrix
    let Sr = JSON.parse(JSON.stringify(S)); //a true copy of the S matrix
    let indexCompensator = 0; //Index compensator. As the matrices indexes are been shortened after each interaction its indexes needs to be decreased as well.
    switch (hasFixed) {
      case 'none':
        for (let x = 0; x < nodes.length; x++) {
          if (nodes[x].supportType !== 'n/a') {
            for (let j = 0; j < Kr.length; j++) {
              //it removes the entire column of Kr matrix where the displacement is 0
              Kr[j].splice(x + indexCompensator, 1);
            } //for
            Kr.splice(x + indexCompensator, 1); //it removes the entire row index where the displacement is 0
            Sr.splice(x + indexCompensator, 1); //it removes the entire row index where displacement = 0
          } else indexCompensator++;
        } //for
        return { Kr, Sr };

      case 'left':
        for (let j = 0; j < Kr.length; j++) {
          //it removes the first 2 columns of Kr matrix
          Kr[j].splice(0, 2);
        } //for
        Kr.splice(0, 2); //it removes the first 2 rows of Kr matrix
        Sr.splice(0, 2); //it removes the first 2 rows of Sr matrix

        indexCompensator = -1;
        for (let x = 1; x < nodes.length; x++) {
          if (nodes[x].supportType !== 'n/a') {
            for (let j = 0; j < Kr.length; j++) {
              //it removes the entire column of Kr matrix where the displacement is 0
              Kr[j].splice(x + indexCompensator, 1);
            } //for
            Kr.splice(x + indexCompensator, 1); //it removes the entire row index where the displacement is 0
            Sr.splice(x + indexCompensator, 1); //it removes the entire row index where displacement is 0
          } else indexCompensator++;
        } //for
        return { Kr, Sr };

      case 'right':
        for (let j = 0; j < Kr.length; j++) {
          //it removes the first & last 2 columns of Kr matrix
          Kr[j].splice(Kr.length - 2, 2);
        } //for
        Kr.splice(Kr.length - 2, 2); //it removes the last 2 rows of Kr matrix
        Sr.splice(Sr.length - 2, 2); //it removes the last 2 rows of Sr matrix
        for (let x = 0; x < nodes.length - 1; x++) {
          if (nodes[x].supportType !== 'n/a') {
            for (let j = 0; j < Kr.length; j++) {
              //it removes the entire column of Kr matrix where the displacement is 0
              Kr[j].splice(x + indexCompensator, 1);
            } //for
            Kr.splice(x + indexCompensator, 1); //it removes the entire row index where the displacement is 0
            Sr.splice(x + indexCompensator, 1); //it removes the entire row index where displacement is 0
          } else indexCompensator++;
        } //for
        return { Kr, Sr };

      case 'both':
        Kr.splice(0, 2); //it removes the first 2 rows of Kr matrix
        Sr.splice(0, 2); //it removes the first 2 rows of Sr matrix
        for (let j = 0; j < Kr.length; j++) {
          //it removes the first & last 2 columns of Kr matrix
          Kr[j].splice(0, 2);
          Kr[j].splice(Kr.length - 2, 2);
        } //for
        Kr.splice(Kr.length - 2, 2); //it removes the last 2 rows of Kr matrix
        Sr.splice(Sr.length - 2, 2); //it removes the last 2 rows of Sr matrix

        indexCompensator = -1;
        for (let x = 1; x < nodes.length - 1; x++) {
          if (nodes[x].supportType !== 'n/a') {
            for (let j = 0; j < Kr.length; j++) {
              //it removes the entire column of Kr matrix where the displacement is 0
              Kr[j].splice(x + indexCompensator, 1);
            } //for
            Kr.splice(x + indexCompensator, 1); //it removes the entire row index where the displacement is 0
            Sr.splice(x + indexCompensator, 1); //it removes the entire row index where displacement is 0
          } else indexCompensator++;
        } //for
        return { Kr, Sr };
    }
  }

  function KeyDisplacements(
    D: number[][],
    hasFixed: string,
    nodes: string | any[]
  ) {
    let indexCompensator = 0;
    switch (hasFixed) {
      case 'none':
        for (let x = 0; x < nodes.length; x++) {
          if (nodes[x].supportType !== 'n/a') {
            D.splice(x + indexCompensator, 0, [0]);
            indexCompensator++;
          } else indexCompensator++;
        } //for
        break;

      case 'left':
        indexCompensator = 1;
        D.unshift([0], [0]);
        for (let x = 1; x < nodes.length; x++) {
          if (nodes[x].supportType !== 'n/a') {
            D.splice(x + indexCompensator, 0, [0]);
            indexCompensator++;
          } else indexCompensator++;
        } //for
        break;

      case 'right':
        D.push([0], [0]);
        for (let x = 0; x < nodes.length - 1; x++) {
          if (nodes[x].supportType !== 'n/a') {
            D.splice(x + indexCompensator, 0, [0]);
            indexCompensator++;
          } else indexCompensator++;
        } //for
        break;

      case 'both':
        indexCompensator = 1;
        D.unshift([0], [0]);
        D.push([0], [0]);
        for (let x = 1; x < nodes.length - 1; x++) {
          if (nodes[x].supportType !== 'n/a') {
            D.splice(x + indexCompensator, 0, [0]);
            indexCompensator++;
          } else indexCompensator++;
        } //for
        break;
    } //switch
  }

  function FirstReactions(
    K: string | any[],
    D: any,
    hasFixed: string,
    nodes: string | any[]
  ) {
    let indexCompensator = 0;
    switch (hasFixed) {
      case 'none':
        for (let x = 0; x < nodes.length; x++) {
          if (nodes[x].supportType !== 'n/a') {
            nodes[x].reactionForce += multiply(
              K[x + indexCompensator],
              D
            ).shift();
            indexCompensator++;
          } else indexCompensator++;
        }
        break;

      case 'left':
        nodes[0].reactionForce += multiply(K[0], D).shift(); //First Support force reaction
        nodes[0].reactionMoment += multiply(K[1], D).shift(); //First Support moment reaction
        indexCompensator++;
        for (let x = 1; x < nodes.length; x++) {
          if (nodes[x].supportType !== 'n/a') {
            nodes[x].reactionForce += multiply(
              K[x + indexCompensator],
              D
            ).shift();
            indexCompensator++;
          } else indexCompensator++;
        }
        break;

      case 'right':
        for (let x = 0; x < nodes.length - 1; x++) {
          if (nodes[x].supportType !== 'n/a') {
            nodes[x].reactionForce += multiply(
              K[x + indexCompensator],
              D
            ).shift();
            indexCompensator++;
          } else indexCompensator++;
        }
        nodes[nodes.length - 1].reactionForce += multiply(
          K[K.length - 2],
          D
        ).shift(); //Last Support force reaction
        nodes[nodes.length - 1].reactionMoment += multiply(
          K[K.length - 1],
          D
        ).shift(); //Last Support moment reaction
        break;

      case 'both':
        nodes[0].reactionForce += multiply(K[0], D).shift(); //First Support force reaction
        nodes[0].reactionMoment += multiply(K[1], D).shift(); //First Support moment reaction
        indexCompensator++;
        for (let x = 1; x < nodes.length - 1; x++) {
          if (nodes[x].supportType !== 'n/a') {
            nodes[x].reactionForce += multiply(
              K[x + indexCompensator],
              D
            ).shift();
            indexCompensator++;
          } else indexCompensator++;
        }
        nodes[nodes.length - 1].reactionForce += multiply(
          K[K.length - 2],
          D
        ).shift(); //Last Support force reaction
        nodes[nodes.length - 1].reactionMoment += multiply(
          K[K.length - 1],
          D
        ).shift(); //Last Support moment reaction
        break;
    }
  }

  function FinalReactions(
    hasFixed: string,
    thisLoad: LoadProps,
    nodes: string | any[]
  ) {
    switch (hasFixed) {
      case 'none':
        //check if the force is upon a support
        for (let x = 0; x < nodes.length; x++) {
          if (
            thisLoad.force &&
            thisLoad.position === nodes[x].position &&
            nodes[x].supportType !== 'n/a'
          ) {
            nodes[x].reactionForce -= thisLoad.force;
          }
        } //for
        return;

      case 'left':
        //moment load over fixed support?
        if (thisLoad.moment && thisLoad.position === nodes[0].position) {
          nodes[0].reactionMoment -= thisLoad.moment;
        }
        //check if the force is upon a support
        for (let x = 0; x < nodes.length; x++) {
          if (
            thisLoad.force &&
            thisLoad.position === nodes[x].position &&
            nodes[x].supportType !== 'n/a'
          ) {
            nodes[x].reactionForce -= thisLoad.force;
          }
        } //for
        return;

      case 'right':
        //moment load over fixed support?
        if (thisLoad.moment && thisLoad.position === L) {
          nodes[nodes.length - 1].reactionMoment -= thisLoad.moment;
        }
        //check if the force is upon a support
        for (let x = 0; x < nodes.length; x++) {
          if (
            thisLoad.force &&
            thisLoad.position === nodes[x].position &&
            nodes[x].supportType !== 'n/a'
          ) {
            nodes[x].reactionForce -= thisLoad.force;
          }
        } //for
        return;

      case 'both':
        //moment load over fixed support at left?
        if (thisLoad.moment && thisLoad.position === nodes[0].position) {
          nodes[0].reactionMoment -= thisLoad.moment;
        }
        //moment load over fixed support at right?
        if (thisLoad.moment && thisLoad.position === L) {
          nodes[nodes.length - 1].reactionMoment -= thisLoad.moment;
        }
        //for each force upon a support
        for (let x = 0; x < nodes.length; x++) {
          if (
            thisLoad.force &&
            thisLoad.position === nodes[x].position &&
            nodes[x].supportType !== 'n/a'
          ) {
            nodes[x].reactionForce -= thisLoad.force;
          }
        }
        return;
    }
  }

  function Displacements(D: any[], EI: number, nodes: string | any[]) {
    let indexCompensator = 0;
    const V = [],
      U: never[] = [];

    for (let s = 0; s < nodes.length - 1; s++) {
      let v1 = D[s + indexCompensator];
      let u1 = D[s + indexCompensator + 1];
      let v2 = D[s + indexCompensator + 2];
      let u2 = D[s + indexCompensator + 3];
      let barLength =
        Math.round((nodes[s + 1].position - nodes[s].position) * 100) / 100;
      let span =
        Math.round((nodes[s + 1].position - nodes[s].position) * 100) / 100;

      //trick to get the last beam point
      if (s === nodes.length - 2) {
        span += i;
      }

      for (let x = 0; x < span; x = Math.round((x + i) * 100) / 100) {
        //Deflections
        let N1 =
          ((1 / barLength ** 3) *
            (barLength ** 3 - 3 * barLength * x ** 2 + 2 * x ** 3) *
            1000) /
          EI;
        let N2 =
          ((1 / barLength ** 2) *
            (barLength ** 2 * x - 2 * barLength * x ** 2 + x ** 3) *
            1000) /
          -EI;
        let N3 =
          ((1 / barLength ** 3) *
            (3 * barLength * x ** 2 - 2 * x ** 3) *
            1000) /
          EI;
        let N4 =
          ((1 / barLength ** 2) * (-barLength * x ** 2 + x ** 3) * 1000) / -EI;
        V.push([nodes[s].position + x, N1 * v1 + N2 * u1 + N3 * v2 + N4 * u2]);

        //Rotations
        // N1 = (1 / (barLength**3)) * (-(6 * barLength * x) + (6 * (x**2))) * 1000 / -EI;
        // N2 = (1 / (barLength**2)) * ((barLength**2) - (4 * barLength * x) + (3 * (x**2))) * 1000 / EI;
        // N3 = (1 / (barLength**3)) * ((6 * barLength * x) - (6 * (x**2))) * 1000 / -EI;
        // N4 = (1 / (barLength**2)) * ((-2 * barLength * x) + (3 * (x**2))) * 1000 / EI;
        // U.push([nodes[s].position + x, (N1 * v1) + (N2 * u1) + (N3 * v2) + (N4 * u2)]);
      } //for
      indexCompensator++;
    } //for
    return { V, U };
  }

  function Stresses(
    nodes: string | any[],
    isPointLoad: boolean,
    thisDistLoad:
      | {
          startValue: number;
          endValue: number;
          endPosition: number;
          startPosition: number;
        }
      | undefined = undefined
  ) {
    const M = [],
      Q = [];

    let shearAccumulator = 0;
    let bendingAccumulator = 0;
    let shearValue;
    let momentValue;
    let onDistLoad = false;
    let y = 0; //(this value will fix the load's startValue along the beam in case of non-uniform load placed over multiple nodes)
    let z = 0; //(this value will fix the load's startPostion along the beam in case of non-uniform load placed over multiple nodes)

    for (let s = 0; s < nodes.length - 1; s++) {
      //it checks if the span is under dist. load
      if (nodes[s].supportType === 'loadStart') {
        onDistLoad = true;
      }
      if (nodes[s].supportType === 'loadEnd') {
        onDistLoad = false;
      }

      let span =
        Math.round((nodes[s + 1].position - nodes[s].position) * 100) / 100;

      for (let x = 0; x <= span; x = Math.round((x + i) * 100) / 100) {
        if (isPointLoad) {
          //a true point load (that inserted by the user)
          //Shear Forces
          shearValue =
            nodes[s].reactionForce + nodes[s].force + shearAccumulator;
          Q.push([nodes[s].position + x, shearValue]);

          //Bending Moments
          momentValue =
            (nodes[s].reactionForce + nodes[s].force + shearAccumulator) * x +
            nodes[s].reactionMoment +
            nodes[s].moment +
            bendingAccumulator;
          M.push([nodes[s].position + x, -momentValue]);
        } else {
          if (onDistLoad && thisDistLoad) {
            //dist. load region
            //Shear Forces
            shearValue =
              nodes[s].reactionForce +
              nodes[s].force +
              (thisDistLoad.startValue - y) * x +
              ((thisDistLoad.endValue - (thisDistLoad.startValue - y)) *
                x ** 2) /
                (2 *
                  (thisDistLoad.endPosition -
                    (thisDistLoad.startPosition + z))) +
              shearAccumulator;
            Q.push([nodes[s].position + x, shearValue]);

            //Bending Moments
            momentValue =
              (nodes[s].reactionForce +
                nodes[s].force +
                ((thisDistLoad.startValue - y) * x) / 2 +
                ((thisDistLoad.endValue - (thisDistLoad.startValue - y)) *
                  x ** 2) /
                  (6 *
                    (thisDistLoad.endPosition -
                      (thisDistLoad.startPosition + z))) +
                shearAccumulator) *
                x +
              (nodes[s].reactionMoment + nodes[s].moment + bendingAccumulator);
            M.push([nodes[s].position + x, -momentValue]);
          } else {
            //out of dist. load region
            //Shear Forces
            shearValue =
              nodes[s].reactionForce + nodes[s].force + shearAccumulator;
            Q.push([nodes[s].position + x, shearValue]);

            //Bending Moments
            momentValue =
              shearValue * x +
              nodes[s].reactionMoment +
              nodes[s].moment +
              bendingAccumulator;
            M.push([nodes[s].position + x, -momentValue]);
          }
        }
      }
      bendingAccumulator = momentValue;
      shearAccumulator = shearValue;

      //now it is time to fix the dist. load start value & position for the upcoming nodes. (it will affect those non-uniform loads)
      if (onDistLoad && thisDistLoad) {
        y =
          ((thisDistLoad.startValue - thisDistLoad.endValue) /
            (thisDistLoad.endPosition - thisDistLoad.startPosition)) *
          (nodes[s + 1].position - thisDistLoad.startPosition);
        z = nodes[s + 1].position - thisDistLoad.startPosition;
      }
    }
    return { M, Q };
  }

  function TemporaryPointLoads(
    thisLoad: DistributesProps,
    temporaryPL: { position: number; force: number; moment: number }[]
  ) {
    let loadLength =
      Math.round((thisLoad.startPosition - thisLoad.endPosition) * 100) / 100;
    let loadMagnitude = thisLoad.startValue - thisLoad.endValue;
    let step = i;

    if (((loadLength * 100) & 1) === 0) {
      //check if it is a even number
      for (
        var j = Math.round((thisLoad.startPosition + step) * 100) / 100;
        j <= thisLoad.endPosition;
        j = Math.round((j + step * 2) * 100) / 100
      ) {
        let newForceValue =
          ((loadMagnitude / loadLength) * j +
            thisLoad.startValue -
            thisLoad.startPosition * (loadMagnitude / loadLength)) *
          (step * 2);
        temporaryPL.push({
          position: j,
          force: newForceValue,
          moment: 0
        });
      } //for
    } else {
      for (
        let j = Math.round((thisLoad.startPosition + step) * 100) / 100;
        j < thisLoad.endPosition;
        j = Math.round((j + step * 2) * 100) / 100
      ) {
        let newForceValue =
          ((loadMagnitude / loadLength) * j +
            thisLoad.startValue -
            thisLoad.startPosition * (loadMagnitude / loadLength)) *
          (step * 2);
        temporaryPL.push({
          position: j,
          force: newForceValue,
          moment: 0
        });
      } //for
      //because this is a odd dist. load length the last force value has to be lesser to provide equilibrium
      let newForceValue =
        ((loadMagnitude / loadLength) * thisLoad.endPosition +
          thisLoad.startValue -
          thisLoad.startPosition * (loadMagnitude / loadLength)) *
        step;
      temporaryPL.push({
        position: thisLoad.endPosition,
        force: newForceValue,
        moment: 0
      });
    } //else
  } //function

  function NodesForDL(
    nodes: string | any[],
    thisLoad: DistributesProps,
    distributedReactions: any[][]
  ) {
    let indexCompensator = 0;
    for (let x = 0; x < nodes.length; x++) {
      if (nodes[x].supportType !== 'n/a') {
        nodes[x].reactionForce = distributedReactions[indexCompensator][0];
        nodes[x].reactionMoment = distributedReactions[indexCompensator][1];
        indexCompensator++;
      }
    } //for

    for (let x = 0; x < nodes.length; x++) {
      if (nodes[x].position === thisLoad.startPosition) {
        nodes[x].supportType = 'loadStart';
      }
      if (nodes[x].position === thisLoad.endPosition) {
        nodes[x].supportType = 'loadEnd';
      }
    } //for
  }

  let hasFixed = FixedSupportChecker(); //is there any fixed supports at the beam ends?
  getGlobalNodes(globalNodes);

  const global_V = new Array(L / i + 1).fill(0).map(() => new Array(2).fill(0)); //it will store the deflections
  //const global_U = new Array((L / i) + globalNodes.length - 1).fill(0).map(() => new Array(2).fill(0)); //it will store the rotations
  const global_M = new Array(L / i + globalNodes.length - 1)
    .fill(0)
    .map(() => new Array(2).fill(0)); //it will store the bending moments
  const global_Q = new Array(L / i + globalNodes.length - 1)
    .fill(0)
    .map(() => new Array(2).fill(0)); //it will store the shear forces

  //Point Loads Routine
  for (let k = 0; k < pointLoads.length; k++) {
    let nodes = JSON.parse(JSON.stringify(globalNodes)); //a true copy of global nodes
    let isPointLoad = true;

    //NOW POINT LOADS ALREADY EXIST SO CHANGE THIS FUNCTION TO SUIT
    NodesForPL(nodes, pointLoads[k]); //it will create the beam nodes for the load under analysis

    const K = StifnessMatrix(nodes); //Stifness Matrix Function Call out
    const S = LoadsArray(nodes); //Loading array Function Call out

    //now it is necessary to shorten the Kr & Sr matrices (where the Stress are equal 0)
    const { Kr, Sr }: any = MatrixShortener(K, S, hasFixed, nodes);

    //now it is time to create the matrix containing the beam Stress values
    let D = multiply(inv(Kr), Sr); //by multiplying 'Kr' by 'Sr' give us the shortened Stress matrix
    KeyDisplacements(D, hasFixed, nodes); //backup the global Stress array (will add the missing zero Stress)

    //Calculating the First Support's reactions.
    FirstReactions(K, D, hasFixed, nodes);

    //It has to add the forces & moments which were placed directly over supports.
    FinalReactions(hasFixed, pointLoads[k], nodes);

    //Calculates the beam Displacements (deflections, rotations) on each node
    // const {V, U} = Displacements(D, EI, nodes);
    const { V } = Displacements(D, EI, nodes);

    //top up the global Displacements
    for (let x = 0; x < L / i + 1; x++) {
      global_V[x][0] = V[x][0];
      global_V[x][1] += V[x][1];

      // global_U[x][0] = U[x][0];
      // global_U[x][1] += U[x][1];
    } //for

    //Stresses (SF & BM)
    const { M, Q } = Stresses(nodes, isPointLoad);

    //top up the global Stresses
    for (let x = 0; x < L / i + globalNodes.length - 1; x++) {
      global_M[x][0] = M[x][0];
      global_M[x][1] += M[x][1];

      global_Q[x][0] = Q[x][0];
      global_Q[x][1] += Q[x][1];
    }

    //top up the global Reactions
    let indexCompensator = 0;
    for (let x = 0; x < nodes.length; x++) {
      if (nodes[x].supportType !== 'n/a') {
        supports[indexCompensator].reactionForce += nodes[x].reactionForce;
        supports[indexCompensator].reactionMoment += nodes[x].reactionMoment;
        indexCompensator++;
      }
    }
  }

  for (let f = 0; f < distributedLoads.length; f++) {
    //It will break them down in work equivalent point loads. The purpose here is to calculate the support reactions Only.
    let isPointLoad = false;
    let temporaryPL: any = [];
    let nodes = [];
    const distributedReactions = new Array(supports.length)
      .fill(0)
      .map(() => new Array(2).fill(0)); //will store the reactions generated by this distributed load
    TemporaryPointLoads(distributedLoads[f], temporaryPL); //call out the function that will create equivalent point loads

    for (let j = 0; j < temporaryPL.length; j++) {
      nodes = JSON.parse(JSON.stringify(globalNodes)); //a true copy of the global nodes
      NodesForPL(nodes, temporaryPL[j]); //it will create the beam nodes for the load under analysis

      const K = StifnessMatrix(nodes); //Stifness Matrix Function Call out
      const S = LoadsArray(nodes); //Loading array Function Call out

      //now it is necessary to shorten the Kr & Sr matrices (where the Stress are equal 0)
      const { Kr, Sr }: any = MatrixShortener(K, S, hasFixed, nodes);

      //now it is time to create the matrix containing the beam Stress values
      let D = multiply(inv(Kr), Sr); //by multiplying 'Kr' by 'Sr' give us the shortened Stress matrix
      KeyDisplacements(D, hasFixed, nodes); //backup the global Stress array (will add the missing zero Stress)

      //Calculating the First Support's reactions.
      FirstReactions(K, D, hasFixed, nodes);

      //It has to add the forces & moments which were placed directly over supports.
      FinalReactions(hasFixed, temporaryPL[j], nodes);

      //Calculates the beam Displacements (deflections, rotations) on each node
      // const {V, U} = Displacements(D, EI, nodes);
      const { V } = Displacements(D, EI, nodes);

      //top up the global Displacements
      for (let x = 0; x < L / i + 1; x++) {
        global_V[x][0] = V[x][0];
        global_V[x][1] += V[x][1];

        // global_U[x][0] = U[x][0];
        // global_U[x][1] += U[x][1];
      } //for

      //top up the global Reactions
      let indexCompensator = 0;
      for (let x = 0; x < nodes.length; x++) {
        if (nodes[x].supportType !== 'n/a') {
          supports[indexCompensator].reactionForce += nodes[x].reactionForce;
          supports[indexCompensator].reactionMoment += nodes[x].reactionMoment;

          //also save the reactions for this distributed load for stresses calculations later on
          distributedReactions[indexCompensator][0] += nodes[x].reactionForce;
          distributedReactions[indexCompensator][1] += nodes[x].reactionMoment;
          indexCompensator++;
        }
      } //for
    } //for temp load

    nodes = JSON.parse(JSON.stringify(globalNodes)); //a true copy of the global nodes
    NodesForDL(nodes, distributedLoads[f], distributedReactions);

    //Stresses (SF & BM)
    const { M, Q } = Stresses(nodes, isPointLoad, distributedLoads[f]);

    //top up the global Stresses
    for (let x = 0; x < L / i + globalNodes.length - 1; x++) {
      global_M[x][0] = M[x][0];
      global_M[x][1] += M[x][1];

      global_Q[x][0] = Q[x][0];
      global_Q[x][1] += Q[x][1];
    } //for
  }

  return {
    global_Q,
    global_M,
    global_V,
    beamSupports: supports
  };
};
