import { IonApp, IonPage, IonSplitPane } from '@ionic/react';
import { Route, Routes } from 'react-router-dom';
import ReactGa from 'react-ga';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import {
  // home as homeIcon,
  pauseOutline,
  removeSharp as removeSharpIcon,
  squareOutline
} from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from './components/Menu/Menu';
import Beam from './pages/Beam2';
import XFrame from './pages/XFrame';
import XSection from './pages/XSection';
import Login from './pages/Login';
import Account from './pages/Account';
import Register from './pages/Register';
import BeamResults2 from './pages/BeamResults2/BeamResults';
import ForgotPassword from './pages/ForgotPassword';
// import Home from './pages/Home/Home';
import Contact from './pages/Contact';
import UpdateApp from './pages/UpdateApp';
import SavedBeams from './pages/SavedBeams';
import SavedMaterials from './pages/SavedMaterials';
import { AuthProvider } from './providers/AuthProvider';

import './theme/variables.css';
import './theme/colors.css';
import './theme/app.css';
import { Helmet } from 'react-helmet';

export interface AppPage {
  title: string;
  url: string;
  icon: string;
}

const App: React.FC = () => {
  const { t } = useTranslation();

  useEffect((): void => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
      ReactGa.set({ page: window.location.pathname });
      ReactGa.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const appPages: AppPage[] = [
    // {
    //   title: 'Home',
    //   url: '/',
    //   icon: homeIcon
    // },
    {
      title: 'XBeam',
      url: 'beam',
      icon: removeSharpIcon
    },
    {
      title: t('xsection coming soon'),
      url: 'xsection',
      icon: pauseOutline
    },
    {
      title: t('xframe coming soon'),
      url: 'xframe',
      icon: squareOutline
    }
  ];

  return (
    <IonApp>
      <Helmet>
        <title>XBeam App</title>
        <meta name="description" content={t('meta description - home')} />
      </Helmet>
      <IonSplitPane contentId="main">
        <Menu appPages={appPages} />
        <IonPage id="main">
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Beam />} />
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="updateapp" element={<UpdateApp />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
              <Route path="account" element={<Account />} />
              <Route path="savedbeams" element={<SavedBeams />} />
              <Route path="savedmaterials" element={<SavedMaterials />} />
              <Route path="results" element={<BeamResults2 />} />
              <Route path="beam" element={<Beam />} />
              <Route path="xsection" element={<XSection />} />
              <Route path="xframe" element={<XFrame />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<Beam />} />
            </Routes>
          </AuthProvider>
        </IonPage>
      </IonSplitPane>
    </IonApp>
  );
};

export default App;
