import styled from 'styled-components';
import { IonButton, IonIcon } from '@ionic/react';

export const Button = styled(IonButton)`
  padding: 0 40px;
`;

export const EyeIcon = styled(IonIcon)`
  margin-top: 30px;
`;
