import { useContext, useEffect, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonRow,
  IonToast
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactGa from 'react-ga';
import { useNavigate } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import StoreCards from 'components/StoreCards/StoreCards';
import AccountDetailsCard from 'components/AccountDetailsCard/AccountDetailsCard';
import AccountPasswordCard from 'components/AccountPasswordCard/AccountPasswordCard';
import Button from 'components/Button/Button';
import { UserContext } from 'providers/AuthProvider';
import { logoutUser } from '../../api/userAPI';
import * as S from './styles';

const Account = (): JSX.Element => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const { user, loading } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect((): void => {
    if (loading === false && user === null) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  const handleLogout = (): void => {
    logoutUser()
      .then((): void => {
        setToastMessage(t('logged-out-successfully'));
        setShowToast(true);
        navigate('/');
      })
      .catch((): void => {
        //  TODO: error handling
      });

    ReactGa.event({
      category: 'XBeam - Account',
      action: 'User pressed "logout" button'
    });
  };

  const handlePasswordChange = async (password: string): Promise<void> => {
    try {
      await user?.updatePassword(password);

      setToastMessage('Account password has been changed successfully!');
      setShowToast(true);
    } catch (error) {
      setToastMessage('Password could not be changed at this time');
      setShowToast(true);
    }

    ReactGa.event({
      category: 'XBeam - Account',
      action: 'User pressed "password change" button'
    });
  };

  return (
    <>
      <Helmet>
        <title>{t('account')} - XBeam</title>
      </Helmet>
      <Header />
      <IonContent className="ion-padding">
        <IonItem lines="none">
          <h1>{t('profile')}</h1>
        </IonItem>
        <IonGrid>
          {user && (
            <IonRow>
              <IonCol class="ion-no-padding">
                <AccountDetailsCard user={user} />
              </IonCol>
            </IonRow>
          )}
          {user && (
            <IonRow>
              <IonCol class="ion-no-padding">
                <AccountPasswordCard onPasswordChange={handlePasswordChange} />
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <Button
          onClick={handleLogout}
          color="tertiary"
          expand="full"
          shape="round"
        >
          {t('logout')}
        </Button>
        <StoreCards />
        <S.WhiteSpace />
      </IonContent>
      <Footer />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={4000}
      />
    </>
  );
};

export default Account;
