import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { IonIcon } from '@ionic/react';

export const LoginIcon = styled(IonIcon)`
  margin: 0 12px 0 6px;
  font-size: 18px;
`;

export const Link = styled(RouterLink)`
  display: flex;
  justify-items: center;
  align-items: center;
`;
