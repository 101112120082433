import styled from "styled-components";
import { IonCard } from "@ionic/react";
import breakpoints from "theme/breakpoints";

export const Card = styled(IonCard)`
  display: none;

  @media only screen and (${breakpoints.md}) {
    display: block;
  }
`;
