import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import ReactGa from 'react-ga';
import {
  addOutline,
  closeCircleOutline,
  pencilOutline,
  trashOutline
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setItemInactive } from '../../utils';
import { DistributesProps } from '../../interfaces';

import * as S from './style';

interface Props {
  beam?: number;
  isActive: boolean;
  distributes: DistributesProps[];
  setDistributes: React.Dispatch<React.SetStateAction<DistributesProps[]>>;
  showDistributedAlert: boolean;
  setShowDistributedAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const getInitialValue = (
  loads: DistributesProps[],
  prop: 'startPosition' | 'endPosition' | 'startValue' | 'endValue'
): any => {
  const activeItem =
    loads && loads.find((sup: DistributesProps) => sup.isActive === true);

  return activeItem !== undefined ? activeItem[prop] : undefined;
};

const DistributeModal: React.FC<Props> = ({
  beam,
  isActive,
  distributes,
  setDistributes,
  showDistributedAlert,
  setShowDistributedAlert
}) => {
  const { t } = useTranslation();
  const [startPosition, setStartPosition] = useState<number | undefined>(
    getInitialValue(distributes, 'startPosition')
  );
  const [endPosition, setEndPosition] = useState<number | undefined>(
    getInitialValue(distributes, 'endPosition')
  );
  const [startValue, setStartValue] = useState<number | undefined>(
    getInitialValue(distributes, 'startValue')
  );
  const [endValue, setEndValue] = useState<number | undefined>(
    getInitialValue(distributes, 'endValue')
  );

  const onDidDismiss = () => {
    setShowDistributedAlert(false);
    setItemInactive({ list: distributes, callback: setDistributes });
    setStartPosition(undefined);
    setEndPosition(undefined);
    setStartValue(undefined);
    setEndValue(undefined);
  };

  useEffect(() => {
    setStartPosition(getInitialValue(distributes, 'startPosition'));
    setEndPosition(getInitialValue(distributes, 'endPosition'));
    setStartValue(getInitialValue(distributes, 'startValue'));
    setEndValue(getInitialValue(distributes, 'endValue'));
  }, [distributes]);

  return (
    <IonModal onDidDismiss={onDidDismiss} isOpen={showDistributedAlert}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('distributed load')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position="floating">{`${t(
            'initial position m'
          )}`}</IonLabel>
          <IonInput
            value={startPosition}
            type="number"
            inputMode="numeric"
            placeholder={`${t('initial position m')}`}
            onIonChange={(e: any) => setStartPosition(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">{`${t('end position m')}`}</IonLabel>
          <IonInput
            value={endPosition}
            type="number"
            inputMode="numeric"
            placeholder={`${t('end position m')}`}
            onIonChange={(e: any) => setEndPosition(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">{`${t('initial value n/m')}`}</IonLabel>
          <IonInput
            value={startValue}
            type="number"
            inputMode="numeric"
            placeholder={`${t('initial value n/m')}`}
            onIonChange={(e: any) => setStartValue(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">{`${t('final value n/m')}`}</IonLabel>
          <IonInput
            value={endValue}
            type="number"
            inputMode="numeric"
            placeholder={`${t('final value n/m')}`}
            onIonChange={(e: any) => setEndValue(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonContent>
      <S.Footer>
        {isActive ? (
          <>
            <IonButton color="primary" onClick={onDidDismiss}>
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              color="danger"
              onClick={(): void => {
                setDistributes(
                  distributes.filter(({ isActive }) => isActive !== true)
                );
                setShowDistributedAlert(false);
                ReactGa.event({
                  category: 'XBeam - Distribute Modal',
                  action: 'User pressed "remove" button'
                });
              }}
            >
              <IonIcon slot="start" icon={trashOutline} />
              {t('remove')}
            </IonButton>
            <IonButton
              color="tertiary"
              onClick={(): void => {
                const sPosition =
                  typeof startPosition === 'string'
                    ? parseFloat(startPosition)
                    : startPosition;
                const ePosition =
                  typeof endPosition === 'string'
                    ? parseFloat(endPosition)
                    : endPosition;
                const sValue =
                  typeof startValue === 'string'
                    ? parseFloat(startValue)
                    : startValue;
                const eValue =
                  typeof endValue === 'string'
                    ? parseFloat(endValue)
                    : endValue;

                if (
                  beam &&
                  sPosition &&
                  ePosition &&
                  ePosition <= beam &&
                  sPosition >= 0 &&
                  sPosition < ePosition
                ) {
                  const itemIndex = distributes.findIndex(
                    (i: DistributesProps) => i.isActive === true
                  );
                  let newDistributes = [...distributes];

                  newDistributes[itemIndex] = {
                    ...distributes[itemIndex],
                    startPosition: sPosition
                      ? Math.round(sPosition * 100) / 100
                      : 0,
                    endPosition: ePosition
                      ? Math.round(ePosition * 100) / 100
                      : 0,
                    startValue: sValue ? Math.round(sValue * 100) / 100 : 0,
                    endValue: eValue ? Math.round(eValue * 100) / 100 : 0
                  };

                  setDistributes(newDistributes);
                } else {
                  alert(t('beam error four'));
                }
                setShowDistributedAlert(false);
                ReactGa.event({
                  category: 'XBeam - Distribute Modal',
                  action: 'User pressed "edit" button'
                });
              }}
            >
              <IonIcon slot="start" icon={pencilOutline} />
              {t('edit')}
            </IonButton>
          </>
        ) : (
          <>
            <IonButton color="danger" onClick={onDidDismiss}>
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              onClick={(): void => {
                const sPosition =
                  typeof startPosition === 'string'
                    ? parseFloat(startPosition)
                    : startPosition;
                const ePosition =
                  typeof endPosition === 'string'
                    ? parseFloat(endPosition)
                    : endPosition;
                const sValue =
                  typeof startValue === 'string'
                    ? parseFloat(startValue)
                    : startValue;
                const eValue =
                  typeof endValue === 'string'
                    ? parseFloat(endValue)
                    : endValue;

                if (
                  sPosition !== undefined &&
                  eValue !== undefined &&
                  beam &&
                  ePosition &&
                  ePosition <= beam &&
                  sPosition >= 0 &&
                  sPosition < ePosition
                ) {
                  setDistributes([
                    ...distributes,
                    {
                      isActive: false,
                      startPosition: sPosition
                        ? Math.round(sPosition * 100) / 100
                        : 0,
                      endPosition: ePosition
                        ? Math.round(ePosition * 100) / 100
                        : 0,
                      startValue: sValue ? Math.round(sValue * 100) / 100 : 0,
                      endValue: eValue ? Math.round(eValue * 100) / 100 : 0,
                      id: Math.floor(Math.random() * Date.now())
                    }
                  ]);
                } else {
                  alert(t('beam error four'));
                }
                ReactGa.event({
                  category: 'XBeam - Distribute Modal',
                  action: 'User pressed "add" button'
                });
                onDidDismiss();
              }}
            >
              <IonIcon slot="start" icon={addOutline} />
              {t('add')}
            </IonButton>
          </>
        )}
      </S.Footer>
    </IonModal>
  );
};

export default DistributeModal;
