import React from 'react';
import ReactGa from 'react-ga';
import Button from 'components/Button/Button';
import GooglePlayImg from './assets/googlePlay.png';
import AppStoreImg from './assets/appStore.png';
import * as S from './style';
import { Capacitor } from '@capacitor/core';

const goToStore = (store: 'google' | 'apple'): void => {
  ReactGa.event({
    category: 'XBeam - Store Cards',
    action: `User pressed go to Store (-- ${store} --) button`
  });

  if (store === 'apple') {
    window.open('itms-apps://itunes.apple.com/app/id1620236044', '_blank');
  }

  if (store === 'google') {
    window.open(
      'https://play.google.com/store/apps/details?id=app.xbeam&hl=en_US&gl=US',
      '_blank'
    );
  }
};

const Banner: React.FC = () => {
  const platform = Capacitor.getPlatform();

  if (platform !== 'web') {
    return null;
  }

  return (
    <S.StoreContainer>
      <Button
        type="submit"
        expand="block"
        color="dark"
        onClick={(): void => goToStore('apple')}
      >
        <S.AppStoreImg src={AppStoreImg} slot="start" />
        <S.Label>
          <S.SubTitle>Download on the</S.SubTitle> <br />{' '}
          <S.Title>App Store</S.Title>
        </S.Label>
      </Button>
      <Button
        type="submit"
        expand="block"
        color='dark'
        onClick={(): void => goToStore('google')}
      >
        <S.AppStoreImg src={GooglePlayImg} slot="start" />
        <S.Label>
          <S.SubTitle>Get it on</S.SubTitle> <br />{' '}
          <S.Title>Play Store</S.Title>
        </S.Label>
      </Button>
    </S.StoreContainer>
  );
};

export default Banner;
