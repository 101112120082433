import {
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import ReactGa from 'react-ga';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from 'components/Button/Button';

interface Props {
  showModal: boolean;
  onDidDismiss: () => void;
  onDone: (password: string) => void;
}

const initialValues = {
  password: '',
  confirmPassword: ''
};

const BeamModal: React.FC<Props> = ({ showModal, onDidDismiss, onDone }) => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(t('password-required'))
      .min(6, t('password-min')),
    confirmPassword: yup
      .string()
      .required(t('password-required'))
      .min(6, t('password-min'))
      .oneOf([yup.ref('password'), null], t('password-must-match'))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      const { password } = values;

      onDone(password);
      onDidDismiss();

      ReactGa.event({
        category: 'XBeam - Change Password Modal',
        action: 'User pressed "submit" button'
      });
    }
  });

  const handleDismiss = (): void => {
    formik.resetForm();
    onDidDismiss();

    ReactGa.event({
      category: 'XBeam - Change Password Modal',
      action: 'User pressed "dismiss" button'
    });
  };

  return (
    <IonModal
      swipeToClose={true}
      onDidDismiss={handleDismiss}
      isOpen={showModal}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('password-change')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={formik.handleSubmit}>
          <IonItem className="ion-margin-top">
            <IonLabel position="floating">{t('password-new')}</IonLabel>
            <IonInput
              id="password"
              name="password"
              type="password"
              value={formik.values.password}
              onIonChange={formik.handleChange}
            />
          </IonItem>
          {formik.errors.password && (
            <IonText color="danger" className="ion-padding-start">
              <small>{formik.errors.password}</small>
            </IonText>
          )}
          <IonItem className="ion-margin-top">
            <IonLabel position="floating">{t('confirm-new-password')}</IonLabel>
            <IonInput
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={formik.values.confirmPassword}
              onIonChange={formik.handleChange}
            />
          </IonItem>
          {formik.errors.confirmPassword && (
            <IonText color="danger" className="ion-padding-start">
              <small>{formik.errors.confirmPassword}</small>
            </IonText>
          )}
          <Button
            disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
            type="submit"
            expand="block"
          >
            {t('submit')}
          </Button>
          <Button
            type="button"
            color="danger"
            expand="block"
            onClick={handleDismiss}
          >
            {t('close')}
          </Button>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default BeamModal;
