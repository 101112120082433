import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
  IonContent,
  IonIcon,
  IonList,
  IonText,
  IonToolbar
} from '@ionic/react';

export const Link = styled(RouterLink)`
  display: block;
  background-color: #3880ff;
  text-decoration: none;
  margin-top: 8px;
  padding: 12px 0 12px 24px;
  color: #fff;
`;

export const Icon = styled(IonIcon)`
  margin-right: 12px;
`;

export const Toolbar = styled(IonToolbar)`
  --background: #3880ff;
  color: #fff;
`;

export const Content = styled(IonContent)`
  --background: #3880ff;
  color: #fff;
`;

export const Version = styled(IonText)`
  color: #eb445a;
`;

export const List = styled(IonList)`
  padding; 0;
`;
