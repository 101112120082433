import { auth, GoogleAuthProvider } from '../firebase';
import firebase from 'firebase/app';

export type User = firebase.User;

export const loginUser = (
  email: string,
  password: string
): Promise<firebase.auth.UserCredential> => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const signInWithGoogle = (): Promise<firebase.auth.UserCredential> => {
  const googleProvider = new GoogleAuthProvider();

  return auth.signInWithPopup(googleProvider);
};

export const registerUser = (
  email: string,
  password: string
): Promise<firebase.auth.UserCredential> => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const logoutUser = (): Promise<void> => {
  return auth.signOut();
};

export const forgotPassword = (email: string): Promise<void> => {
  return auth.sendPasswordResetEmail(email);
};

export const updatePasssword = (user: firebase.User): Promise<void> => {
  return auth.updateCurrentUser(user);
};

export const updateProfile = (
  displayName: string
): Promise<void> | undefined => {
  return auth.currentUser?.updateProfile({ displayName });
};
