import {
  IonFooter,
  IonHeader,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppPage } from '../../App';
import * as S from './style';

interface Props {
  appPages: AppPage[];
}

const Menu: React.FC<Props> = ({ appPages }) => {
  const { t } = useTranslation();

  return (
    <IonMenu contentId="main">
      <IonHeader>
        <S.Toolbar>
          <IonTitle>Menu</IonTitle>
        </S.Toolbar>
      </IonHeader>
      <S.Content>
        {appPages.map(
          (appPage: AppPage, index: number): JSX.Element => (
            <IonMenuToggle key={index} auto-hide="false">
              <S.Link to={appPage.url}>
                <IonLabel>
                  <S.Icon icon={appPage.icon} />
                  {appPage.title}
                </IonLabel>
              </S.Link>
            </IonMenuToggle>
          )
        )}
      </S.Content>
      <IonFooter>
        <IonToolbar className="ion-text-center">
          <IonLabel>
            {t('version')}: <S.Version color="danger">2.0.0</S.Version>
          </IonLabel>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
