import { IonContent, IonItem } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from 'components/ForgotPasswordForm/ForgotPasswordForm';
import Footer from 'components/Footer/Footer';
import SecondaryHeader from 'components/SecondaryHeader/SecondaryHeader';

const ForgotPassword = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('forgot password')} - XBeam</title>
        <meta name="description" content={t('meta description')} />
      </Helmet>
      <SecondaryHeader />
      <IonContent className="ion-padding">
        <IonItem lines="none">
          <h1>{t('forgot password')}</h1>
        </IonItem>
        <ForgotPasswordForm />
      </IonContent>
      <Footer />
    </>
  );
};

export default ForgotPassword;
