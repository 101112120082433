import { IonCard, IonCardContent } from '@ionic/react';
import styled from 'styled-components';

export const Card = styled(IonCard)`
  padding: 10px 0;
`;

export const CanvasPDF = styled.canvas`
  display: none;
`;

export const CardContent = styled(IonCardContent)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
`;
