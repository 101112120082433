import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { addOutline, closeCircleOutline } from 'ionicons/icons';
import ReactGa from 'react-ga';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './style';

interface Props {
  showModal: boolean;
  inertiaMomentInitialValue?: string;
  elasticModulusInitialValue?: string;
  nameInitialValue?: string;
  areaInitialValue?: string;
  momentInitialValue?: string;
  maxReactionInitialValue?: string;
  maxShearForceInitialValue?: string;
  onDidDismiss: any;
  onDone: (
    inertiaMoment: string,
    elasticModulus: string,
    name: string,
    area?: string,
    maxShearForce?: string,
    maxMoment?: string,
    maxReaction?: string
  ) => void;
}

const MaterialsModal: React.FC<Props> = ({
  showModal,
  elasticModulusInitialValue,
  inertiaMomentInitialValue,
  nameInitialValue,
  areaInitialValue,
  momentInitialValue,
  maxReactionInitialValue,
  maxShearForceInitialValue,
  onDidDismiss,
  onDone
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string | undefined>(nameInitialValue);
  const [inertiaMoment, setInertiaMoment] = useState<string | undefined>(
    inertiaMomentInitialValue
  );
  const [elasticModulus, setElasticModulus] = useState<string | undefined>(
    elasticModulusInitialValue
  );
  const [area, setArea] = useState<string | undefined>(areaInitialValue);
  const [maxShearForce, setMaxShearForce] = useState<string | undefined>(
    maxShearForceInitialValue
  );
  const [maxMoment, setMoment] = useState<string | undefined>(
    momentInitialValue
  );
  const [maxReaction, setReaction] = useState<string | undefined>(
    maxReactionInitialValue
  );

  useEffect((): void => {
    setMaxShearForce(maxShearForceInitialValue);
  }, [maxShearForceInitialValue]);

  useEffect((): void => {
    setReaction(maxReactionInitialValue);
  }, [maxReactionInitialValue]);

  useEffect((): void => {
    setMoment(momentInitialValue);
  }, [momentInitialValue]);

  useEffect((): void => {
    setArea(areaInitialValue);
  }, [areaInitialValue]);

  useEffect((): void => {
    setElasticModulus(elasticModulusInitialValue);
  }, [elasticModulusInitialValue]);

  useEffect((): void => {
    setInertiaMoment(inertiaMomentInitialValue);
  }, [inertiaMomentInitialValue]);

  useEffect((): void => {
    setName(nameInitialValue);
  }, [nameInitialValue]);

  return (
    <IonModal
      swipeToClose={true}
      onDidDismiss={(): void => {
        onDidDismiss(false);
        setName(nameInitialValue);
        setInertiaMoment(inertiaMomentInitialValue);
        setElasticModulus(elasticModulusInitialValue);
        setMaxShearForce(maxShearForceInitialValue);
        setReaction(maxReactionInitialValue);
        setMoment(momentInitialValue);
        setArea(areaInitialValue);
      }}
      isOpen={showModal}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('properties')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position="floating">{t('section-name')}</IonLabel>
          <IonInput
            value={name}
            onIonChange={(e: any): void => setName(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">{t('elastic modulus (GPa)')}</IonLabel>
          <IonInput
            value={elasticModulus}
            type="number"
            inputMode="numeric"
            min="0"
            onIonChange={(e: any): void => setElasticModulus(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">
            {t('moment of inertia (mm4)')}
          </IonLabel>
          <IonInput
            value={inertiaMoment}
            type="number"
            inputMode="numeric"
            min="0"
            onIonChange={(e: any): void => setInertiaMoment(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Area mm2</IonLabel>
          <IonInput
            value={area}
            type="number"
            inputMode="numeric"
            min="0"
            onIonChange={(e: any): void => setArea(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">{t('max cutting effort')} kN</IonLabel>
          <IonInput
            value={maxShearForce}
            type="number"
            inputMode="numeric"
            min="0"
            onIonChange={(e: any): void => setMaxShearForce(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">
            {t('max bending moment')} kN.m
          </IonLabel>
          <IonInput
            value={maxMoment}
            type="number"
            inputMode="numeric"
            min="0"
            onIonChange={(e: any): void => setMoment(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">{t('max reaction')} kN</IonLabel>
          <IonInput
            value={maxReaction}
            type="number"
            inputMode="numeric"
            min="0"
            onIonChange={(e: any): void => setReaction(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonContent>
      <S.Footer>
        <IonButton color="danger" onClick={onDidDismiss}>
          <IonIcon slot="start" icon={closeCircleOutline} />
          {t('cancel')}
        </IonButton>
        <IonButton
          onClick={(): void => {
            if (
              elasticModulus &&
              parseFloat(elasticModulus) > 0 &&
              inertiaMoment &&
              parseFloat(inertiaMoment) > 0 &&
              name
            ) {
              onDone(
                inertiaMoment,
                elasticModulus,
                name,
                area,
                maxShearForce,
                maxMoment,
                maxReaction
              );
            } else {
              alert(t('beam error four'));
            }

            onDidDismiss();
            ReactGa.event({
              category: 'XBeam - Materials Modal',
              action: 'User pressed "edit" button'
            });
          }}
        >
          <IonIcon slot="start" icon={addOutline} />
          {t('edit')}
        </IonButton>
      </S.Footer>
    </IonModal>
  );
};

export default MaterialsModal;
