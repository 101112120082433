import {
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonToast,
  useIonRouter
} from '@ionic/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReactGa from 'react-ga';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUser } from '../../providers/UserProvider';
import Button from 'components/Button/Button';

const initialValues = {
  email: '',
  password: '',
  confirmPassword: ''
};

const RegisterForm = (): JSX.Element => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const { t } = useTranslation();
  const router = useIonRouter();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('inform email message')),
    password: yup
      .string()
      .required(t('password-required'))
      .min(6, t('password-min')),
    confirmPassword: yup
      .string()
      .required(t('password-required'))
      .min(6, t('password-min'))
      .oneOf([yup.ref('password'), null], t('password-must-match'))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }): void => {
      const { email, password } = values;

      createUser(email, password)
        .then((): void => {
          setToastMessage(t('account-successful-created'));
          setShowToast(true);
          router.push('/beam', 'forward', 'push');
        })
        .catch((): void => {
          setToastMessage(t('account-not-created'));
          setShowToast(true);
          setSubmitting(false);
          resetForm();
        });

      ReactGa.event({
        category: 'XBeam - Register Form',
        action: 'User pressed "submit" button'
      });
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">Email</IonLabel>
        <IonInput
          type="email"
          name="email"
          id="email"
          value={formik.values.email}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.email && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.email}</small>
        </IonText>
      )}
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">{t('password')}</IonLabel>
        <IonInput
          id="password"
          name="password"
          type="password"
          value={formik.values.password}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.password && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.password}</small>
        </IonText>
      )}
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">{t('confirm-password')}</IonLabel>
        <IonInput
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          value={formik.values.confirmPassword}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.confirmPassword && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.confirmPassword}</small>
        </IonText>
      )}
      <Button
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        type="submit"
        expand="block"
      >
        {t('register')}
      </Button>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={4000}
      />
    </form>
  );
};

export default RegisterForm;
