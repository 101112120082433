import styled from 'styled-components';
import Plot from 'react-plotly.js';
import breakpoints from '../../theme/breakpoints';
import { IonCardContent } from '@ionic/react';

export const PlotChart = styled(Plot)`
  width: 350px;
  height: 280px;
  margin-left: -38px;

  @media only screen and (${breakpoints.sm}) {
    width: 500px;
    height: 400px;
    margin-left: unset;
  }

  @media only screen and (${breakpoints.md}) {
    width: 680px;
  }

  @media only screen and (${breakpoints.lg}) {
    width: 780px;
  }

  @media only screen and (${breakpoints.xl}) {
    width: 880px;
  }
`;

export const LineChartContent = styled(IonCardContent)`
  text-align: center;
`;
