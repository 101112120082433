const breakpoints = {
  xs: "min-width: 0",
  sm: "min-width: 576px",
  md: "min-width: 768px",
  lg: "min-width: 992px",
  xl: "min-width: 1200px",
  xxl: "min-width: 1440px",
};

export default breakpoints;
