import React from 'react';
import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar
} from '@ionic/react';

const SecondaryHeader: React.FC = () => (
  <IonHeader>
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton style={{ color: '#000' }} />
      </IonButtons>
      <IonTitle>XBeam</IonTitle>
    </IonToolbar>
  </IonHeader>
);

export default SecondaryHeader;
