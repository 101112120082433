import {
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonToast,
  useIonRouter
} from '@ionic/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReactGa from 'react-ga';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { loginUser } from '../../api/userAPI';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

const initialValues = {
  email: '',
  password: ''
};

const LoginForm = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const router = useIonRouter();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('inform email message')),
    password: yup
      .string()
      .required(t('password-required'))
      .min(6, t('password-min'))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }): void => {
      const { email, password } = values;

      loginUser(email, password)
        .then((): void => {
          router.push('/beam', 'forward', 'push');
        })
        .catch((): void => {
          setToastMessage(t('account-not-logged'));
          setShowToast(true);
          setSubmitting(false);
        });

      ReactGa.event({
        category: 'XBeam - Login Form',
        action: 'User pressed "submit" button'
      });
    }
  });

  const togglePassword = (): void => {
    ReactGa.event({
      category: 'XBeam - Login Form',
      action: 'User pressed "password toggle" button'
    });
    setShowPassword((prev: boolean) => !prev);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">Email</IonLabel>
        <IonInput
          type="email"
          name="email"
          id="email"
          value={formik.values.email}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.email && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.email}</small>
        </IonText>
      )}
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">{t('password')}</IonLabel>
        <IonInput
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onIonChange={formik.handleChange}
        />
        <S.EyeIcon
          onClick={togglePassword}
          slot="end"
          icon={showPassword ? eyeOffOutline : eyeOutline}
        />
      </IonItem>
      {formik.errors.password && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.password}</small>
        </IonText>
      )}
      <S.Button
        disabled={!formik.isValid || formik.isSubmitting}
        className="ion-margin-top"
        type="submit"
        expand="block"
      >
        {t('login')}
      </S.Button>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={4000}
      />
    </form>
  );
};

export default LoginForm;
