import styled from 'styled-components';
import { IonIcon, IonText } from '@ionic/react';
import breakpoints from '../../theme/breakpoints';

export const DropdownIcon = styled(IonIcon)`
  padding-right: 6px;
`;

export const CloseText = styled.span`
  cursor: pointer;
`;

export const LinkContainer = styled.div`
  margin-left: 20px;
  padding: 12px 4px;
  border-bottom: 1px solid #dedede;
`;

export const Name = styled(IonText)`
  display: none;

  @media only screen and (${breakpoints.md}) {
    display: block;
  }
`;
