import React, { useContext, useState } from 'react';
import ReactGa from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonIcon,
  IonRow
} from '@ionic/react';
import {
  add,
  closeOutline,
  cloudUploadOutline,
  pencilOutline,
  pulseOutline,
  trashOutline
} from 'ionicons/icons';
import BeamControllerCard from 'components/BeamControllerCard2';
import BeamCanvas from 'components/BeamCanvas/BeamCanvas';
import BeamModal from 'components/BeamModal/BeamModal';
import SupportModal from 'components/SupportModal/SupportModal';
import MultipleSupportModal from 'components/MultipleSupportModal/MultipleSupportModal';
import LoadModal from 'components/LoadModal/LoadModal';
import DistributeModal from 'components/DistributeModal/DistributeModal';
import BeamSaveModal from 'components/BeamSaveModal/BeamSaveModal';
import StoreCards from 'components/StoreCards/StoreCards';
import useBeamCount from 'hooks/useBeamCount';
import { addBeam } from 'providers/BeamProvider';
import { UserContext } from 'providers/AuthProvider';
import {
  ModalProps,
  SupportProps,
  LoadProps,
  DistributesProps
} from '../../interfaces';
import { isItemActive, setItemActive, isNumberOdd } from '../../utils';

import { calculateBeam } from './calculations';
import * as S from './styles';

const Beam2: React.FC = () => {
  const { state } = useLocation() as {
    state: {
      beam: number;
      supports: SupportProps[];
      loads: LoadProps[];
      distributes: DistributesProps[];
      elasticModulus: string;
      inertiaMoment: string;
      maxDeflection: number;
      maxMomentValue: string;
      maxReactionValue: string;
      maxShearForceValue: string;
      activeSectionId: string;
    };
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { incrementCount } = useBeamCount();
  const [beam, setBeam] = useState<number | undefined>(
    state && state.beam ? state.beam : undefined
  );
  const [maxDeflection, setMaxDeflection] = useState<number | undefined>(
    state && state.maxDeflection ? state.maxDeflection : undefined
  );
  const [activeSectionId, setActiveSectionId] = useState<string>(
    state && state.activeSectionId ? state.activeSectionId : '0'
  );
  const [showBeamAlert, setShowBeamAlert] = useState<boolean>(false);
  const [properties, setProperties] = useState<any>({
    inertia: state && state.inertiaMoment ? state.inertiaMoment : '225000000',
    elastic: state && state.elasticModulus ? state.elasticModulus : '20',
    area: '0',
    maxMoment: state && state.maxMomentValue ? state.maxMomentValue : '0',
    maxReaction: state && state.maxReactionValue ? state.maxReactionValue : '0',
    maxShearForce:
      state && state.maxShearForceValue ? state.maxShearForceValue : '0'
  });
  const [supports, setSupports] = useState<SupportProps[]>(
    state && state.supports ? state.supports : []
  );
  const [showSupportAlert, setShowSupportAlert] = useState<ModalProps>({
    open: false,
    type: undefined
  });
  const [showMultipleSupportAlert, setShowMultipleSupportAlert] =
    useState<boolean>(false);
  const [showSupportPopover, setShowSupportPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined
  });
  const [loads, setLoads] = useState<LoadProps[]>(
    state && state.loads ? state.loads : []
  );
  const [showLoadAlert, setShowLoadAlert] = useState<boolean>(false);
  const [distributes, setDistributes] = useState<DistributesProps[]>(
    state && state.distributes ? state.distributes : []
  );
  const [showDistributedAlert, setShowDistributedAlert] =
    useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);

  const hasBeam: boolean = beam !== undefined && beam > 0;

  const setBeamValue = (beam: number): void => {
    if (beam) {
      const valid1 =
        supports && supports.length > 0
          ? supports.filter(sup => sup.position > beam)
          : [];
      const valid2 =
        loads && loads.length > 0
          ? loads.filter(load => load.position > beam)
          : [];
      const valid3 =
        distributes && distributes.length > 0
          ? distributes.filter(dis => dis.endPosition > beam)
          : [];

      if (
        beam > 0 &&
        valid1.length === 0 &&
        valid2.length === 0 &&
        valid3.length === 0
      ) {
        setBeam(Math.round(beam * 100) / 100);
      } else {
        alert(t('beam error three'));
      }
    } else {
      alert(t('beam error two'));
    }
  };

  const disableButton = (): boolean => {
    const hasFixedSupport =
      supports.some((sup: SupportProps) => sup.type === 'fixed') ||
      supports.length >= 2;

    if (hasFixedSupport && (loads.length > 0 || distributes.length > 0)) {
      return false;
    }

    return true;
  };

  const maxSpanCalc = (): number => {
    let maxSpan = 0;

    if (beam && supports[0].position === 0) {
      for (let i = 1; i < supports.length; i++) {
        if (supports[i].position - supports[i - 1].position > maxSpan) {
          maxSpan = supports[i].position - supports[i - 1].position;
        }
      }
      if (beam - supports[supports.length - 1].position > maxSpan) {
        maxSpan = beam - supports[supports.length - 1].position;
      }
    } else {
      maxSpan = supports[0].position;
      for (let i = 1; i < supports.length; i++) {
        if (supports[i].position - supports[i - 1].position > maxSpan) {
          maxSpan = supports[i].position - supports[i - 1].position;
        }
      }
      if (beam && beam - supports[supports.length - 1].position > maxSpan) {
        maxSpan = beam - supports[supports.length - 1].position;
      }
    }

    return maxSpan;
  };

  const solveBeam = (): void => {
    if (beam) {
      ReactGa.event({
        category: 'XBeam',
        action: 'User pressed "solve beam" button'
      });

      const E = parseFloat(properties.elastic);
      const I = parseFloat(properties.inertia);
      let maxSpan = maxSpanCalc();
      let maxAllowedDeflection =
        maxDeflection !== undefined
          ? ((maxSpan * 1000) / maxDeflection).toFixed(2)
          : undefined;

      const { global_Q, global_M, global_V, beamSupports } = calculateBeam({
        loads,
        distributes,
        sups: supports.sort(
          (a: SupportProps, b: SupportProps) => a.position - b.position
        ),
        E,
        I,
        L: beam
      });

      navigate('/results', {
        state: {
          global_Q,
          global_M,
          global_V,
          beam,
          beamSupports,
          distributes,
          loads,
          properties,
          maxDeflection,
          activeSectionId,
          maxAllowedDeflection
        }
      });

      incrementCount();
    }
  };

  const resetBeam = (): void => {
    setBeam(undefined);
    setMaxDeflection(undefined);
    setLoads([]);
    setSupports([]);
    setDistributes([]);
  };

  const handleSaveBeam = (name: string): void => {
    if (user) {
      const E = parseFloat(properties.elastic);
      const I = parseFloat(properties.inertia);

      addBeam(user?.uid, {
        date: new Date().toISOString(),
        loads,
        name,
        distributes,
        sups: supports.sort(
          (a: SupportProps, b: SupportProps) => a.position - b.position
        ),
        E,
        I,
        L: beam
      });

      alert('Beam successfully saved');
    }

    ReactGa.event({
      category: 'XBeam',
      action: 'User pressed "save beam" button'
    });
  };

  return (
    <>
      <BeamSaveModal
        onSave={handleSaveBeam}
        isActive={showSaveModal}
        setShowSaveModal={setShowSaveModal}
      />
      <BeamModal
        hasBeam={hasBeam}
        initialValue={beam}
        initialMaxDeflection={maxDeflection}
        elasticModulusInitialValue={properties.elastic}
        inertiaMomentInitialValue={properties.inertia}
        showBeamAlert={showBeamAlert}
        resetBeam={resetBeam}
        activeSectionId={activeSectionId}
        setActiveSectionId={setActiveSectionId}
        onDidDismiss={(): void => setShowBeamAlert(false)}
        setMaxDeflectionValue={setMaxDeflection}
        setBeamValue={(beam: number) => setBeamValue(beam)}
        setPropertiesValue={(
          inertia: string,
          elastic: string,
          area: string | undefined,
          maxMoment: string | undefined,
          maxReaction: string | undefined,
          maxShearForce: string | undefined
        ): void => {
          setProperties({
            inertia,
            elastic,
            area,
            maxMoment,
            maxReaction,
            maxShearForce
          });
        }}
      />
      <SupportModal
        beam={beam}
        isActive={isItemActive(supports)}
        supports={supports}
        setSupports={setSupports}
        showSupport={showSupportAlert.open}
        showSupportAlert={showSupportAlert}
        setShowSupportAlert={setShowSupportAlert}
      />
      <MultipleSupportModal
        beam={beam}
        supports={supports}
        setSupports={setSupports}
        showMultipleSupportAlert={showMultipleSupportAlert}
        setShowMultipleSupportAlert={setShowMultipleSupportAlert}
      />
      <LoadModal
        beam={beam}
        isActive={isItemActive(loads)}
        loads={loads}
        setLoads={setLoads}
        showLoadAlert={showLoadAlert}
        setShowLoadAlert={setShowLoadAlert}
      />
      <DistributeModal
        beam={beam}
        isActive={isItemActive(distributes)}
        distributes={distributes}
        setDistributes={setDistributes}
        showDistributedAlert={showDistributedAlert}
        setShowDistributedAlert={setShowDistributedAlert}
      />
      <BeamControllerCard
        hasBeam={hasBeam}
        setShowBeamAlert={setShowBeamAlert}
        setShowSupportModal={setShowSupportAlert}
        setShowMultipleSupportModal={setShowMultipleSupportAlert}
        showSupportPopover={showSupportPopover}
        setShowSupportPopover={setShowSupportPopover}
        setShowPointLoadModal={setShowLoadAlert}
        setShowDistributedLoad={setShowDistributedAlert}
        disableSupportButton={false}
      />
      {beam !== undefined && beam > 0 && (
        <BeamCanvas
          editable={true}
          beam={beam}
          supports={supports}
          loads={loads}
          distributes={distributes}
          setShowBeamModal={setShowBeamAlert}
        />
      )}
      {beam !== undefined && beam > 0 && (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t('edit the values')}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <S.FirstCol colored={true}>{t('beam')}</S.FirstCol>
                <S.MiddleCol colored={true}> {beam}m</S.MiddleCol>
                <S.EditButtonCol colored={true}>
                  <S.EditButton
                    size="small"
                    color="tertiary"
                    fill="clear"
                    onClick={(): void => {
                      setShowBeamAlert(true);
                      ReactGa.event({
                        category: 'XBeam - Edit Table',
                        action: 'User pressed "edit beam" icon'
                      });
                    }}
                  >
                    <IonIcon slot="start" icon={pencilOutline} />
                  </S.EditButton>
                  <S.DeleteButton
                    size="small"
                    color="danger"
                    fill="clear"
                    onClick={(): void => {
                      resetBeam();
                      ReactGa.event({
                        category: 'XBeam - Edit Table',
                        action: 'User pressed "reset beam" icon'
                      });
                    }}
                  >
                    <IonIcon slot="start" icon={trashOutline} />
                  </S.DeleteButton>
                </S.EditButtonCol>
              </IonRow>
              {supports &&
                supports.length > 0 &&
                supports.map((sup, i) => (
                  <IonRow key={sup.id}>
                    <S.FirstCol colored={!isNumberOdd(i)}>
                      {t('support')} {i + 1}
                    </S.FirstCol>
                    <S.MiddleCol colored={!isNumberOdd(i)}>
                      {sup.position}m
                    </S.MiddleCol>
                    <S.EditButtonCol colored={!isNumberOdd(i)}>
                      <S.EditButton
                        size="small"
                        color="tertiary"
                        fill="clear"
                        onClick={(): void => {
                          setItemActive({
                            item: sup,
                            list: supports,
                            callback: setSupports
                          });
                          setShowSupportAlert({
                            open: true,
                            type: sup.type
                          });
                          ReactGa.event({
                            category: 'XBeam - Edit Table',
                            action: 'User pressed "edit support" icon'
                          });
                        }}
                      >
                        <IonIcon slot="start" icon={pencilOutline} />
                      </S.EditButton>
                      <S.DeleteButton
                        size="small"
                        color="danger"
                        fill="clear"
                        onClick={(): void => {
                          setSupports(
                            supports.filter(supState => sup.id !== supState.id)
                          );

                          ReactGa.event({
                            category: 'XBeam - Edit Table',
                            action: 'User pressed "delete support" icon'
                          });
                        }}
                      >
                        <IonIcon slot="start" icon={trashOutline} />
                      </S.DeleteButton>
                    </S.EditButtonCol>
                  </IonRow>
                ))}
              {loads &&
                loads.length > 0 &&
                loads.map((load, i) => (
                  <IonRow key={load.id}>
                    <S.FirstCol colored={!isNumberOdd(i)}>
                      {t('load')} {i + 1}
                    </S.FirstCol>
                    <S.MiddleCol colored={!isNumberOdd(i)}>
                      {load.position}m
                    </S.MiddleCol>
                    <S.EditButtonCol colored={!isNumberOdd(i)}>
                      <S.EditButton
                        size="small"
                        color="tertiary"
                        fill="clear"
                        onClick={() => {
                          setItemActive({
                            item: load,
                            list: loads,
                            callback: setLoads
                          });
                          setShowLoadAlert(true);
                          ReactGa.event({
                            category: 'XBeam - Edit Table',
                            action: 'User pressed "edit load" icon'
                          });
                        }}
                      >
                        <IonIcon slot="start" icon={pencilOutline} />
                      </S.EditButton>
                      <S.DeleteButton
                        size="small"
                        color="danger"
                        fill="clear"
                        onClick={() => {
                          setLoads(
                            loads.filter(loadState => load.id !== loadState.id)
                          );
                          ReactGa.event({
                            category: 'XBeam - Edit Table',
                            action: 'User pressed "delete load" icon'
                          });
                        }}
                      >
                        <IonIcon slot="start" icon={trashOutline} />
                      </S.DeleteButton>
                    </S.EditButtonCol>
                  </IonRow>
                ))}
              {distributes &&
                distributes.length > 0 &&
                distributes.map((distribute, i) => (
                  <IonRow key={distribute.id}>
                    <S.FirstCol colored={!isNumberOdd(i)}>
                      {t('distributed load')} {i + 1}
                    </S.FirstCol>
                    <S.MiddleCol colored={!isNumberOdd(i)}>
                      {distribute.startPosition}m - {distribute.endPosition}m
                    </S.MiddleCol>
                    <S.EditButtonCol colored={!isNumberOdd(i)}>
                      <S.EditButton
                        size="small"
                        color="tertiary"
                        fill="clear"
                        onClick={() => {
                          setItemActive({
                            item: distribute,
                            list: distributes,
                            callback: setDistributes
                          });
                          setShowDistributedAlert(true);
                          ReactGa.event({
                            category: 'XBeam - Edit Table',
                            action: 'User pressed "edit distribute" icon'
                          });
                        }}
                      >
                        <IonIcon slot="start" icon={pencilOutline} />
                      </S.EditButton>
                      <S.DeleteButton
                        size="small"
                        color="danger"
                        fill="clear"
                        onClick={() => {
                          setDistributes(
                            distributes.filter(
                              distribuiteState =>
                                distribute.id !== distribuiteState.id
                            )
                          );
                          ReactGa.event({
                            category: 'XBeam - Edit Table',
                            action: 'User pressed "delete distribute" icon'
                          });
                        }}
                      >
                        <IonIcon slot="start" icon={trashOutline} />
                      </S.DeleteButton>
                    </S.EditButtonCol>
                  </IonRow>
                ))}
            </IonGrid>
          </IonCardContent>
        </IonCard>
      )}
      {beam !== undefined && beam > 0 && (
        <S.SolveBeamButton
          color="danger"
          onClick={(): void => {
            resetBeam();
            navigate('/beam', { state: {} });
          }}
          expand="full"
          shape="round"
        >
          <S.ButtonIcon icon={closeOutline} />
          {t('clear values')}
        </S.SolveBeamButton>
      )}
      <S.SaveBeamButton
        color="tertiary"
        disabled={disableButton()}
        displayButton={!!user}
        onClick={(): void => setShowSaveModal(true)}
        expand="full"
        shape="round"
      >
        <S.ButtonIcon icon={cloudUploadOutline} />
        {t('beam-save')}
      </S.SaveBeamButton>
      <S.SolveBeamButton
        color="primary"
        disabled={disableButton()}
        onClick={(): void => solveBeam()}
        expand="full"
        shape="round"
      >
        <S.ButtonIcon icon={pulseOutline} />
        {t('solve beam')}
      </S.SolveBeamButton>
      <StoreCards />
      <S.WhiteSpace />
      <S.Fab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton class="main-button">
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList side="top">
          <S.FabButton
            disabled={hasBeam}
            data-desc={t('add beam')}
            onClick={() => {
              setShowBeamAlert(true);
              ReactGa.event({
                category: 'XBeam - Fab',
                action: 'User pressed "add beam" icon'
              });
            }}
            class="fab-button"
          >
            <IonIcon icon={add} />
          </S.FabButton>
          <S.FabButton
            data-desc={t('add support')}
            onClick={(
              e: React.MouseEvent<HTMLIonFabButtonElement, MouseEvent>
            ) => {
              setShowSupportPopover({
                open: true,
                event: e.nativeEvent
              });
              ReactGa.event({
                category: 'XBeam - Fab',
                action: 'User pressed "add support" icon'
              });
            }}
            class="fab-button"
          >
            <IonIcon icon={add} />
          </S.FabButton>
          <S.FabButton
            data-desc={t('add multiple support')}
            onClick={() => {
              setShowMultipleSupportAlert(true);
              ReactGa.event({
                category: 'XBeam - Fab',
                action: 'User pressed "add multiple support" icon'
              });
            }}
            class="fab-button"
          >
            <IonIcon icon={add} />
          </S.FabButton>
          <S.FabButton
            data-desc={t('add load')}
            onClick={() => {
              setShowLoadAlert(true);
              ReactGa.event({
                category: 'XBeam - Fab',
                action: 'User pressed "add load" icon'
              });
            }}
            class="fab-button"
          >
            <IonIcon icon={add} />
          </S.FabButton>
          <S.FabButton
            data-desc={t('add distributed load')}
            onClick={() => {
              setShowDistributedAlert(true);
              ReactGa.event({
                category: 'XBeam - Fab',
                action: 'User pressed "add distributed" icon'
              });
            }}
            class="fab-button"
          >
            <IonIcon icon={add} />
          </S.FabButton>
        </IonFabList>
      </S.Fab>
    </>
  );
};

export default Beam2;
