import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonLabel
} from '@ionic/react';
import { useState } from 'react';
import ReactGa from 'react-ga';
import { useTranslation } from 'react-i18next';
import PasswordModal from 'components/ChangePasswordModal/ChangePasswordModal';
import Button from 'components/Button/Button';
// import * as S from './styles';

const AccountPasswordCard = ({
  onPasswordChange
}: {
  onPasswordChange: (password: string) => void;
}): JSX.Element => {
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const showPasswordModal = (): void => {
    setPasswordModal(true);

    ReactGa.event({
      category: 'XBeam - Account Password Card',
      action: 'User pressed "show password modal" button'
    });
  };

  return (
    <>
      <PasswordModal
        showModal={passwordModal}
        onDidDismiss={(): void => setPasswordModal(false)}
        onDone={onPasswordChange}
      ></PasswordModal>
      <IonCard>
        <IonCardHeader>{t('security-privacy')}</IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              {t('password-change-description')}
            </IonLabel>
          </IonItem>
          <Button
            onClick={showPasswordModal}
            color="light"
            expand="full"
            shape="round"
          >
            {t('password-change')}
          </Button>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default AccountPasswordCard;
