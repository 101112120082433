import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem
} from '@ionic/react';
import { useState } from 'react';
import ReactGa from 'react-ga';
import { useTranslation } from 'react-i18next';
import EditAccountModal from 'components/EditAccountModal/EditAccountModal';
import { User, updateProfile } from '../../api/userAPI';
// import * as S from './styles';

const AccountDetailsCard = ({ user }: { user: User }): JSX.Element => {
  const [editModal, setEditModal] = useState<boolean>(false);
  const [name, setName] = useState<string | null>(user?.displayName);

  const { t } = useTranslation();

  const showEditAccountModal = (): void => {
    setEditModal(true);
    ReactGa.event({
      category: 'XBeam - Account Details Card',
      action: 'User pressed "show edit account modal" button'
    });
  };
  const handleAccountDetailsChange = async (
    displayName: string | null
  ): Promise<void> => {
    if (displayName) {
      try {
        await updateProfile(displayName);
        setName(displayName);
      } catch (error: unknown) {
        // TODO: handle error
      }
      ReactGa.event({
        category: 'XBeam - Account Details Card',
        action: 'User pressed "account details submit" button'
      });
    }
  };

  return (
    <>
      <EditAccountModal
        showModal={editModal}
        name={name}
        onDidDismiss={(): void => setEditModal(false)}
        onDone={handleAccountDetailsChange}
      ></EditAccountModal>
      <IonCard>
        <IonCardHeader>{t('account-details')}</IonCardHeader>
        <IonCardContent>
          <IonItem>
            <b>Name: </b> &nbsp; {name}
          </IonItem>
          <IonItem>
            <b>Email: </b> &nbsp; {user?.email}
          </IonItem>
          <IonButton
            onClick={showEditAccountModal}
            color="light"
            expand="full"
            shape="round"
            className="ion-margin"
          >
            {t('edit-account')}
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default AccountDetailsCard;
