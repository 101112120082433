import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast
} from '@ionic/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import emailjs from 'emailjs-com';
import ReactGa from 'react-ga';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  email: '',
  subject: '',
  message: ''
};

const ContactForm = (): JSX.Element => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('inform email message')),
    subject: yup
      .string()
      .min(3, t('short message'))
      .required(t('inform subject message')),
    message: yup
      .string()
      .min(60, t('short message'))
      .required(t('what is your message'))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm, setSubmitting, setStatus }) => {
      const templateParams = {
        from_name: values.email,
        subject: values.subject,
        to_name: 'olivr3@gmail.com',
        message: values.message
      };

      emailjs
        .send(
          'service_wgwdueb',
          'template_e3dabhr',
          templateParams,
          'user_WppK5q4u0MAWc8XISPaJp'
        )
        .then(
          (): void => {
            setToastMessage(t('contact message sent'));
            setShowToast(true);
            setSubmitting(false);
            navigate('/');
            resetForm();

            ReactGa.event({
              category: 'Contact',
              action: 'User pressed "send" button'
            });
          },
          (): void => {
            setToastMessage(t('contact message not send'));
            setShowToast(true);
            setSubmitting(false);
            navigate('/');
            resetForm();
          }
        );
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">Email</IonLabel>
        <IonInput
          type="email"
          name="email"
          id="email"
          value={formik.values.email}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.email && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.email}</small>
        </IonText>
      )}
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">{t('subject')}</IonLabel>
        <IonInput
          id="subject"
          name="subject"
          value={formik.values.subject}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.subject && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.subject}</small>
        </IonText>
      )}
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">{t('message')}</IonLabel>
        <IonTextarea
          name="message"
          id="message"
          rows={6}
          value={formik.values.message}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.message && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.message}</small>
        </IonText>
      )}
      <IonButton
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        className="ion-margin-top"
        type="submit"
        expand="block"
      >
        {t('send')}
      </IonButton>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={4000}
      />
    </form>
  );
};

export default ContactForm;
