import { IonContent } from '@ionic/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import XBeam from './XBeam';

const Beam2: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>XBeam - Beam</title>
        <meta name="description" content={t('meta description - beam')} />
      </Helmet>
      <Header />
      <IonContent>
        <XBeam />
      </IonContent>
      <Footer />
    </>
  );
};

export default Beam2;
