import React, { useState } from 'react';
import { IonButton, IonPopover } from '@ionic/react';
import {
  appsOutline,
  closeOutline,
  personCircleOutline,
  readerOutline,
  reorderFourOutline
} from 'ionicons/icons';
import ReactGa from 'react-ga';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

interface AccountDropdownProps {
  name?: string | null;
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({ name }) => {
  const { t } = useTranslation();
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: any;
  }>({
    showPopover: false,
    event: undefined
  });

  const trackAction = (route: string): void => {
    ReactGa.event({
      category: 'XBeam - Account Dropdown',
      action: `User pressed ${route} button`
    });
  };

  return (
    <>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={(): void =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <S.LinkContainer className="flex">
          <S.DropdownIcon icon={readerOutline} />
          <Link
            className="link black-color"
            to="/account"
            onClick={(): void => trackAction('account')}
          >
            {t('account')}
          </Link>
        </S.LinkContainer>

        <S.LinkContainer className="flex">
          <S.DropdownIcon icon={reorderFourOutline} />
          <Link
            className="link black-color"
            to="/savedbeams"
            onClick={(): void => trackAction('savedbeams')}
          >
            {t('saved-beams')}
          </Link>
        </S.LinkContainer>
        <S.LinkContainer className="flex">
          <S.DropdownIcon icon={appsOutline} />
          <Link
            className="link black-color"
            to="/savedmaterials"
            onClick={(): void => trackAction('savedmaterials')}
          >
            {t('saved-materials')}
          </Link>
        </S.LinkContainer>
        <S.LinkContainer
          className="flex"
          onClick={(e: any): void => {
            e.persist();
            trackAction('close');
            setShowPopover({ showPopover: false, event: e });
          }}
        >
          <S.DropdownIcon color="danger" icon={closeOutline} />
          <S.CloseText className="link black-color">{t('close')}</S.CloseText>
        </S.LinkContainer>
      </IonPopover>
      <IonButton
        onClick={(e: any): void => {
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
      >
        <S.DropdownIcon icon={personCircleOutline} />
        {name && <S.Name>{name}</S.Name>}
      </IonButton>
    </>
  );
};

export default AccountDropdown;
