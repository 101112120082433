import React, { memo } from 'react';
import { IonButton } from '@ionic/react';

interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  color?: string;
  shape?: 'round';
  fill?: 'clear' | 'outline' | 'solid' | 'default';
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  expand?: 'block' | 'full';
}

const Button: React.FC<ButtonProps> = ({
  disabled = false,
  children,
  type,
  shape,
  fill,
  color,
  expand,
  onClick
}): JSX.Element => (
  <IonButton
    disabled={disabled}
    type={type}
    color={color}
    shape={shape}
    fill={fill}
    expand={expand}
    onClick={onClick}
    className="ion-margin"
  >
    {children}
  </IonButton>
);

export default memo(
  Button,
  (prevProps, nextProps) => prevProps.disabled === nextProps.disabled
);
