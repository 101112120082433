import styled from 'styled-components';
import { IonImg, IonLabel, IonText } from '@ionic/react';

export const StoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled(IonLabel)`
  margin-left: 12px;
  text-align: left;
`;

export const Title = styled(IonText)``;

export const SubTitle = styled(IonText)`
  font-size: 11px;
`;

export const GooglePlayImg = styled(IonImg)`
  width: 24px;
`;

export const AppStoreImg = styled(IonImg)`
  width: 24px;
`;
