import React, { memo } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { getUnitLabel } from '../../utils';
import { DistributesProps, LoadProps, SupportProps } from '../../interfaces';
import xbeamImage from './assets/xbeam.png';

const getSWLText = (
  maxValue: string | undefined,
  value: string,
  type: string
): JSX.Element => {
  const unit: string = getUnitLabel(type);

  if (maxValue && maxValue !== '0') {
    if (Math.abs(parseFloat(value)) > Math.abs(parseFloat(maxValue))) {
      return (
        <span>
          {`(SWL =+/-${maxValue}${unit})`}{' '}
          <span className="red-color">Failed!</span>
        </span>
      );
    } else {
      return (
        <>
          {`(SWL =+/-${maxValue}${unit})`}{' '}
          <span className="success-color">Pass!</span>
        </>
      );
    }
  }

  return <></>;
};

interface Props {
  graphs: Array<{ name: string; image: string }>;
  maxQ?: { value: string; pos: string };
  maxM?: { value: string; pos: string };
  maxV?: { value: string; pos: string };
  maxMoment?: any;
  maxMomentValue?: any;
  maxReaction?: any;
  maxReactionValue?: string;
  maxShearForceValue?: string;
  maxDeflection: string;
  maxAllowedDeflection: string;
  elasticModulus: string;
  inertiaMoment: string;
  beam: number;
  onPDFRender: any;
  canvasImage: string;
  distributes: DistributesProps[];
  loads: LoadProps[];
  supports: SupportProps[];
}

const styles = StyleSheet.create({
  page: {
    padding: 30
  },
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch'
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase'
  },
  name: {
    fontSize: 24
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  paddingTop10: {
    marginTop: 20
  },
  imageColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: 5,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end'
  },
  entryContainer: {
    marginBottom: 20,
    marginTop: 20,
    flexDirection: 'row'
  },
  detailContainer: {
    fontSize: 14,
    flexDirection: 'row'
  },
  image: {
    width: '45px',
    height: '45px'
  },
  graphImage: {
    width: '500px',
    height: '300px'
  }
});

const MyDocument: React.FC<Props> = ({
  onPDFRender,
  graphs,
  canvasImage,
  beam,
  maxQ,
  maxMoment,
  maxMomentValue,
  maxReaction,
  maxReactionValue,
  maxShearForceValue,
  maxDeflection,
  maxAllowedDeflection,
  maxM,
  maxV,
  loads,
  supports,
  inertiaMoment,
  elasticModulus,
  distributes
}) => {
  const date: Date = new Date();
  const { t } = useTranslation();

  const hasFixedSupport =
    supports && supports.length > 0
      ? supports.some((sup: SupportProps) => sup?.type === 'fixed')
      : false;

  return (
    <Document onRender={onPDFRender}>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.detailColumn}>
            <Text style={styles.name}>XBeam</Text>
          </View>
          <View style={styles.imageColumn}>
            <Image style={styles.image} source={xbeamImage} />
          </View>
        </View>
        <View style={styles.container}>
          <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
            <Text style={styles.title}>
              {t('date')}:{' '}
              {`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`}
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
            <Image style={styles.graphImage} src={canvasImage}></Image>
          </View>
        </View>
        <View style={styles.container}>
          <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
            <Text style={styles.title}>{t('properties')}</Text>
          </View>
        </View>
        <View style={styles.entryContainer}>
          <Text key={12} style={styles.detailContainer}>
            {t('length')}: {beam}m
          </Text>
        </View>
        <View style={styles.entryContainer}>
          <Text key={12} style={styles.detailContainer}>
            {t('elastic modulus')}: {elasticModulus} GPa
          </Text>
        </View>
        <View style={styles.entryContainer}>
          <Text key={12} style={styles.detailContainer}>
            {t('moment of inertia')}: {inertiaMoment} mm4
          </Text>
        </View>
        <View style={styles.container}>
          <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
            <Text style={styles.title}>{t('summary')}</Text>
          </View>
        </View>
        {maxMoment !== undefined && hasFixedSupport && (
          <View style={styles.entryContainer}>
            <Text key={13} style={styles.detailContainer}>
              <b>{t('max moment reaction')}</b> {maxMoment.value}kN.m @{' '}
              {maxMoment.pos}m{' '}
              {getSWLText(
                maxMomentValue,
                maxMoment.value,
                'max-moment-reaction'
              )}
              <br />
              <br />
            </Text>
          </View>
        )}
        {maxReaction !== undefined && (
          <View style={styles.entryContainer}>
            <Text key={123} style={styles.detailContainer}>
              <>
                <b>{t('max force reaction')}</b> {maxReaction.value}kN @{' '}
                {maxReaction.pos}m{' '}
                {getSWLText(
                  maxReactionValue,
                  maxReaction.value,
                  'max-force-reaction'
                )}
                <br />
                <br />
              </>
            </Text>
          </View>
        )}
        {maxQ !== undefined && (
          <View style={styles.entryContainer}>
            <Text key={1125} style={styles.detailContainer}>
              <>
                <b>{t('max cutting effort')}</b> {maxQ.value}kN @ {maxQ.pos}m{' '}
                {getSWLText(
                  maxShearForceValue,
                  maxQ.value,
                  'max-cutting-effort'
                )}
                <br />
                <br />
              </>
            </Text>
          </View>
        )}
        {maxM !== undefined && (
          <View style={styles.entryContainer}>
            <Text key={1125} style={styles.detailContainer}>
              <>
                <b>{t('max bending moment')}</b>{' '}
                {Number(maxM.value) > 0
                  ? `-${maxM.value}kN.m`
                  : `${Math.abs(Number(maxM.value))}kN.m`}{' '}
                @ {maxM.pos}m{' '}
                {getSWLText(maxMomentValue, maxM.value, 'max-bending-moment')}{' '}
                <br />
                <br />
              </>
            </Text>
          </View>
        )}
        {maxV !== undefined && (
          <View style={styles.entryContainer}>
            <Text key={1125} style={styles.detailContainer}>
              <>
                <b>{t('max displacement')}</b> {maxV.value}mm @ {maxV.pos}m{' '}
                {maxDeflection > '0' &&
                  getSWLText(
                    maxAllowedDeflection,
                    maxV.value,
                    'max-displacement'
                  )}
                <br />
                <br />
              </>
            </Text>
          </View>
        )}
        {loads &&
          loads.length > 0 &&
          loads.filter(load => load.force !== 0).length > 0 && (
            <View style={styles.container}>
              <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
                <Text style={styles.title}>{t('punctual load')}</Text>
              </View>
            </View>
          )}
        {loads &&
          loads.length > 0 &&
          loads.map((load: LoadProps, i: number) => {
            if (load.force !== 0) {
              return (
                <View style={styles.entryContainer} key={load.id}>
                  <Text key={i} style={styles.detailContainer}>
                    ({i + 1}) {`${load.force}kN @ ${load.position}m`}
                  </Text>
                </View>
              );
            }
            return null;
          })}
        {loads &&
          loads.length > 0 &&
          loads.filter(load => load.moment !== 0).length > 0 && (
            <View style={styles.container}>
              <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
                <Text style={styles.title}>{t('moment load')}</Text>
              </View>
            </View>
          )}
        {loads &&
          loads.length > 0 &&
          loads.map((load: LoadProps, i: number) => {
            if (load.moment !== 0) {
              return (
                <View style={styles.entryContainer} key={load.id}>
                  <Text key={i} style={styles.detailContainer}>
                    ({i + 1}) {`${load.moment}kN.m @ ${load.position}m`}
                  </Text>
                </View>
              );
            }
            return null;
          })}
        {loads && loads.length === 0 && (
          <View style={styles.entryContainer}>
            <Text>-</Text>
          </View>
        )}
        <View style={styles.container}>
          <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
            <Text style={styles.title}>{t('distributed load')}</Text>
          </View>
        </View>
        {distributes &&
          distributes.length > 0 &&
          distributes.map((distribute: DistributesProps, i: number) => (
            <View key={distribute.id} style={styles.entryContainer}>
              <Text key={i} style={styles.detailContainer}>
                ({i + 1}){' '}
                {`${distribute.startValue}kN @ ${distribute.startPosition}m --> ${distribute.endValue}kN @ ${distribute.endPosition}m`}
              </Text>
            </View>
          ))}
        {distributes && distributes.length === 0 && (
          <View style={styles.entryContainer}>
            <Text>-</Text>
          </View>
        )}
        <View style={styles.container}>
          <View style={{ ...styles.detailColumn, ...styles.paddingTop10 }}>
            <Text style={styles.title}>{t('reactions')}</Text>
          </View>
        </View>
        {supports &&
          supports.length > 0 &&
          supports.map((sup: SupportProps, i: number) => {
            if (sup.reactionMoment !== 0) {
              return (
                <span key={sup.id}>
                  <View style={styles.entryContainer}>
                    <Text style={styles.detailContainer}>
                      ({i + 1}){' '}
                      {`${Math.round(sup.reactionMoment * 100) / 100}kN.m @ ${
                        sup.position
                      }m`}
                    </Text>
                  </View>
                  <View style={styles.entryContainer}>
                    <Text style={styles.detailContainer}>
                      ({i + 1}){' '}
                      {`${Math.round(sup.reactionForce * 100) / 100}kN @ ${
                        sup.position
                      }m`}
                    </Text>
                  </View>
                </span>
              );
            }

            return (
              <View key={sup.id} style={styles.entryContainer}>
                <Text style={styles.detailContainer}>
                  ({i + 1}){' '}
                  {`${Math.round(sup.reactionForce * 100) / 100}kN @ ${
                    sup.position
                  }m`}
                </Text>
              </View>
            );
          })}
        {graphs.map((graph: { name: string; image: string }, i: number) => (
          <View key={i}>
            <View style={styles.container}>
              <View style={styles.detailColumn}>
                <Text style={styles.title}>{graph.name}</Text>
              </View>
            </View>
            <Image style={styles.graphImage} src={graph.image}></Image>
          </View>
        ))}
      </Page>
    </Document>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => true;

export default memo(MyDocument, areEqual);
