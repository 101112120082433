import { IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import ReactGa from 'react-ga';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from 'components/Button/Button';

interface Props {
  name: string | null;
  onDone: (name: string | null) => void;
}

const EditAccountForm: React.FC<Props> = ({ onDone, name }) => {
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    name: yup.string().required(t('name-required')).min(3, t('name-min'))
  });

  const formik = useFormik({
    initialValues: {
      name
    },
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      onDone(values.name);

      ReactGa.event({
        category: 'XBeam - Edit Account Modal',
        action: 'User pressed "submit" button'
      });
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <IonItem className="ion-margin-top">
        <IonLabel position="floating">{t('name-new')}</IonLabel>
        <IonInput
          id="name"
          name="name"
          type="text"
          value={formik.values.name}
          onIonChange={formik.handleChange}
        />
      </IonItem>
      {formik.errors.name && (
        <IonText color="danger" className="ion-padding-start">
          <small>{formik.errors.name}</small>
        </IonText>
      )}
      <Button
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        type="submit"
        expand="block"
      >
        {t('submit')}
      </Button>
    </form>
  );
};

export default EditAccountForm;
