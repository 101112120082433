import { IonLabel, IonToolbar } from '@ionic/react';
import * as S from './styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.Footer>
      <IonToolbar className="ion-text-center">
        <IonLabel>{t('all rights reserved')}</IonLabel>
      </IonToolbar>
    </S.Footer>
  );
};

export default Footer;
