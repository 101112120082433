import { useContext, useEffect, useState } from 'react';
import { IonContent, IonItem } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactGa from 'react-ga';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import SavedBeamsCard from 'components/SavedBeamsCard';
import StoreCards from 'components/StoreCards/StoreCards';
import { UserContext } from 'providers/AuthProvider';
import { getBeams, deleteBeam } from 'providers/BeamProvider';
import { BeamProps } from '../../interfaces';
import { useNavigate, Link } from 'react-router-dom';

const SavedBeams = (): JSX.Element => {
  const { user, loading } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [beams, setBeams] = useState<BeamProps[]>([]);

  const handleDelete = (beamId: string): void => {
    if (user) {
      const filteredBeams = beams.filter(beam => beam.id !== beamId);

      deleteBeam(beamId).then(() => {
        setBeams(filteredBeams);
      });
    }
  };

  const handleEdit = (beam: BeamProps): void => {
    navigate('/beam', {
      state: {
        beam: beam.L,
        supports: beam.sups,
        loads: beam.loads,
        distributes: beam.distributes,
        elasticModulus: beam.E,
        inertiaMoment: beam.I
      }
    });

    ReactGa.event({
      category: 'XBeam - SaveBeam page',
      action: `User pressededit button`
    });
  };

  useEffect((): void => {
    if (loading === false && user === null) {
      navigate('/beam');
    }
  }, [user, loading, navigate]);

  useEffect((): void => {
    if (user) {
      getBeams(user?.uid).then(res => {
        const beamsData = res.map((beam: any) => beam.data());
        setBeams(beamsData);
      });
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <title>{t('saved-beams')} - XBeam</title>
      </Helmet>
      <Header />
      <IonContent className="ion-padding">
        <IonItem lines="none">
          <h1>{t('saved-beams')}</h1>
        </IonItem>
        {beams.length > 0 && (
          <SavedBeamsCard
            beams={beams}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )}
        {beams.length === 0 && (
          <Link
            to="/beam"
            className="link-button-secondary flex ion-justify-content-center ion-align-items-center ion-margin-vertical"
            onClick={(): void => {
              ReactGa.event({
                category: 'XBeam - SaveBeam page',
                action: `User pressed edit button`
              });
            }}
          >
            {t('saved-no-beams')}
          </Link>
        )}
        <StoreCards />
      </IonContent>
      <Footer />
    </>
  );
};

export default SavedBeams;
