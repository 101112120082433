import {
  IonAlert,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonToast
} from '@ionic/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReactGa from 'react-ga';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { chevronBackOutline } from 'ionicons/icons';
import { forgotPassword } from '../../api/userAPI';

const initialValues = {
  email: ''
};

const ForgotPasswordForm = () => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('inform email message'))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ email }, { setSubmitting }) => {
      forgotPassword(email)
        .then((): void => {
          setShowAlert(true);
        })
        .catch((): void => {
          setToastMessage(t('password-forgot-error'));
          setShowToast(true);
          setSubmitting(false);
        });

      ReactGa.event({
        category: 'XBeam - Forgot Password',
        action: 'User pressed "submit" button'
      });
    }
  });

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={(): void => {
          setShowAlert(false);
          navigate('/login');
        }}
        header={'Success'}
        message={t('password-forgot-success')}
        buttons={['Ok']}
      />
      <form onSubmit={formik.handleSubmit}>
        <IonItem className="ion-margin-top">
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            type="email"
            name="email"
            id="email"
            value={formik.values.email}
            onIonChange={formik.handleChange}
          />
        </IonItem>
        {formik.errors.email && (
          <IonText color="danger" className="ion-padding-start">
            <small>{formik.errors.email}</small>
          </IonText>
        )}

        <IonButton
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
          className="ion-margin-top"
          type="submit"
          expand="block"
        >
          {t('submit')}
        </IonButton>
        <br />
        <Link
          onClick={(): void => {
            ReactGa.event({
              category: 'XBeam - Forgot Password',
              action: 'User pressed "back to login" button'
            });
          }}
          to="/login"
          className="link flex ion-justify-content-center ion-align-items-center ion-margin-top"
        >
          <IonIcon icon={chevronBackOutline} />
          <IonText className="ion-margin-start">{t('back-login')}</IonText>
        </Link>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={4000}
        />
      </form>
    </>
  );
};

export default ForgotPasswordForm;
