import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonText
} from '@ionic/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import HookImg from './assets/hook.png';
import * as S from './styles';

const XFrame = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>XFrame - XBeam</title>
        <meta name="description" content={t('meta description - xframe')} />
      </Helmet>
      <Header />
      <IonContent className="ion-padding">
        <S.Title>XFrame</S.Title>
        <S.Card>
          <S.Image src={HookImg} alt="Hook" />
          <IonCardHeader>
            <IonCardSubtitle>
              Page is under construction process
            </IonCardSubtitle>
            <IonCardTitle>
              UNDER <IonText color="danger">CONSTRUCTION</IonText>
            </IonCardTitle>
          </IonCardHeader>
        </S.Card>
      </IonContent>
      <Footer />
    </>
  );
};

export default XFrame;
