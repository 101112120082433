import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonGrid,
  IonIcon,
  IonRow
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import { BeamProps } from '../../interfaces';
import * as S from './styles';

interface SavedBeamsCardProps {
  beams: BeamProps[];
  onDelete: (beamId: string) => void;
  onEdit: (beamId: BeamProps) => void;
}

const SavedBeamsCard: React.FC<SavedBeamsCardProps> = ({
  beams,
  onDelete,
  onEdit
}) => {
  const { t } = useTranslation();

  return (
    <IonCard>
      <IonCardHeader>{t('edit the values')}</IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <S.HeaderNameCol>{t('name')}</S.HeaderNameCol>
            <S.HeaderDateCol>{t('date')}</S.HeaderDateCol>
            <S.HeaderActionsCol>{t('actions')}</S.HeaderActionsCol>
          </IonRow>
          {beams.map((beam: BeamProps, i: number) => (
            <IonRow key={`${beam.name}${i}`}>
              <S.FirstCol colored={i % 2 === 0}> {beam.name} </S.FirstCol>
              <S.MiddleCol colored={i % 2 === 0}>
                {new Date(beam.date).getDate()}/{new Date(beam.date).getMonth()}
                /{new Date(beam.date).getFullYear()}
              </S.MiddleCol>
              <S.LastCol colored={i % 2 === 0}>
                <S.EditButton
                  size="small"
                  color="tertiary"
                  fill="clear"
                  onClick={(): void => {
                    onEdit(beam);
                  }}
                >
                  <IonIcon slot="start" icon={pencilOutline} />
                </S.EditButton>
                <S.DeleteButton
                  size="small"
                  color="danger"
                  fill="clear"
                  onClick={(): void => {
                    onDelete(beam.id);
                  }}
                >
                  <IonIcon slot="start" icon={trashOutline} />
                </S.DeleteButton>
              </S.LastCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default SavedBeamsCard;
