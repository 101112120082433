import { useEffect, useState } from 'react';
import { db, increment } from '../firebase';

const docKey = 'IqAOacOKwR7JU58Nq3Zr';

const useBeamCount = (): {
  count: number | undefined;
  incrementCount: () => Promise<void>;
} => {
  const [count, setCount] = useState<number | undefined>(0);
  const ref = db.collection('count').doc(docKey);

  useEffect(() => {
    ref.get().then(doc => {
      setCount(doc.data()?.resolved);
    });
  }, [ref]);

  const incrementCount = (): Promise<void> =>
    ref.update({ resolved: increment });

  return {
    count,
    incrementCount
  };
};

export default useBeamCount;
