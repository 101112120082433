import { useContext, useEffect } from 'react';
import { IonContent, IonIcon, IonItem } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { logoGoogle } from 'ionicons/icons';
import ReactGa from 'react-ga';
import { Capacitor } from '@capacitor/core';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from 'providers/AuthProvider';
import LoginForm from 'components/LoginForm/LoginForm';
import Footer from 'components/Footer/Footer';
import SecondaryHeader from 'components/SecondaryHeader/SecondaryHeader';
import Button from 'components/Button/Button';
import { createUserWithGoogle } from '../../providers/UserProvider';
import * as S from './styles';

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      navigate('/');
    }
  }, [navigate, user]);

  const loginWithGoogle = async (): Promise<void> => {
    try {
      await createUserWithGoogle();

      ReactGa.event({
        category: 'XBeam - Login',
        action: 'User pressed "sign in with google" button'
      });
    } catch (error) {
      // TODO: error handling
    }
  };

  return (
    <>
      <Helmet>
        <title>XBeam Platform</title>
        <meta name="description" content={t('meta description - login')} />
      </Helmet>
      <SecondaryHeader />
      <IonContent className="ion-padding">
        <IonItem lines="none">
          <h1>{t('sign in')}</h1>
        </IonItem>
        <LoginForm />
        <S.ButtonContainer>
          {Capacitor.getPlatform() !== 'ios' && (
            <Button expand="block" fill="outline" onClick={loginWithGoogle}>
              <IonIcon slot="start" icon={logoGoogle} />
              {t('sign-in-google')}
            </Button>
          )}
          <br />
          <Link
            className="link primary-color ion-text-center"
            onClick={(): void => {
              ReactGa.event({
                category: 'XBeam - Login',
                action: 'User pressed "Dont have account" button'
              });
            }}
            to="/register"
          >
            {t('no-account')}
          </Link>
          <br />
          <Link
            className="link medium-color ion-text-center ion-margin-top"
            onClick={(): void => {
              ReactGa.event({
                category: 'XBeam - Login',
                action: 'User pressed "Forgot Password" button'
              });
            }}
            to="/forgotpassword"
          >
            {t('forgot password')}
          </Link>
        </S.ButtonContainer>
      </IonContent>
      <Footer />
    </>
  );
};

export default Login;
