import styled from 'styled-components';
import { IonFabButton, IonButton, IonFab, IonCol, IonIcon } from '@ionic/react';
import breakpoints from 'theme/breakpoints';

export const SolveBeamButton = styled(IonButton)`
  margin: 16px;
`;

export const ButtonIcon = styled(IonIcon)`
  margin-right: 10px;
`;

export const SaveBeamButton = styled(IonButton)<{
  displayButton?: boolean;
}>`
  margin: 16px;
  display: ${({ displayButton }) => (displayButton ? 'block' : 'none')};
`;

export const WhiteSpace = styled.div`
  height: 170px;
`;

export const EditButton = styled(IonButton)`
  margin: 0;
`;

export const DeleteButton = styled(IonButton)`
  margin: 0;
`;

export const FirstCol = styled(IonCol)<{
  colored?: boolean;
}>`
  background-color: ${({ colored }) => (colored ? 'aliceblue' : '#fff')};
  font-weight: bold;
`;

export const MiddleCol = styled(IonCol)<{
  colored?: boolean;
}>`
  text-align: center;
  background-color: ${({ colored }) => (colored ? 'aliceblue' : '#fff')};
  font-weight: bold;
`;

export const EditButtonCol = styled(IonCol)<{
  colored?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ colored }) => (colored ? 'aliceblue' : '#fff')};
  padding: 0;
`;

export const Fab = styled(IonFab)`
  @media only screen and (${breakpoints.md}) {
    display: none;
  }

  margin-bottom: 100px;
  margin-right: 10px;
`;

export const FabButton = styled(IonFabButton)`
  position: relative;
  overflow: visible;
  contain: layout;
  --background: var(--ion-color-tertiary);
  --color: #ffffff;

  &:after {
    position: absolute;
    content: attr(data-desc);
    z-index: 1;
    background-color: var(--ion-color-primary);
    padding: 9px;
    border-radius: 15px;
    color: white;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  @media only screen and (min-height: 0) {
    width: 28px;
    height: 28px;

    &:after {
      right: 35px;
      height: 15px;
      padding: 5px 10px;
      bottom: -2px;
    }
  }

  @media only screen and (min-height: 378px) {
    width: 40px;
    height: 40px;

    &:after {
      right: 55px;
      bottom: 4px;
      height: 16px;
      padding: 9px;
    }
  }
`;
