import React, { createRef, useEffect, useState } from 'react';
import { SupportProps, LoadProps, DistributesProps } from '../../interfaces';
import * as S from './style';

interface XBeamProps {
  editable?: boolean;
  showDistLoads?: boolean;
  showPointLoads?: boolean;
  showSupports?: boolean;
  showGrid?: boolean;
  showReactions?: boolean;
  beam: number;
  supports?: SupportProps[];
  loads?: LoadProps[];
  distributes?: DistributesProps[];
  setShowBeamModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCanvasImage?: React.Dispatch<React.SetStateAction<string>>;
}

const getCanvasSize = (): number[] => {
  if (window.innerWidth <= 390) {
    return [280, 200];
  }
  if (window.innerWidth > 390 && window.innerWidth < 576) {
    return [370, 250];
  }
  if (window.innerWidth >= 576 && window.innerWidth < 768) {
    return [550, 450];
  }
  if (window.innerWidth >= 768 && window.innerWidth < 992) {
    return [720, 500];
  }
  if (window.innerWidth >= 992 && window.innerWidth < 1200) {
    return [700, 550];
  }
  if (window.innerWidth >= 1200 && window.innerWidth < 1800) {
    return [900, 600];
  }
  return [1200, 700];
};

export const BeamCanvas: React.FC<XBeamProps> = ({
  editable = false,
  showDistLoads = true,
  showPointLoads = true,
  showSupports = true,
  showGrid = true,
  showReactions = true,
  beam,
  supports,
  loads,
  distributes,
  setCanvasImage
}) => {
  const beamChartRef = createRef<HTMLCanvasElement>();
  const beamChartPDFRef = createRef<HTMLCanvasElement>();
  const cardContentRef = createRef<HTMLIonCardContentElement>();
  const [w, setCanvasWidth] = useState<number>(getCanvasSize()[0]);
  const [h, setCanvasLength] = useState<number>(getCanvasSize()[1]);

  const step = w / 20;
  const origin = {
    x: 2 * step,
    y: 4 * step
  };
  let bar: { x0: number; y0: number; x1: number; y1: number };

  const findMaxForce = (): number => {
    let maxForce = 0;
    let minForce = 0;

    //In order to create a force's scale in the canvas we have to find the max absolute global force value.
    let maxStartValue = distributes
      ? Math.max.apply(
          Math,
          distributes.map(function (o) {
            return o.startValue;
          })
        )
      : 0;
    let maxEndValue = distributes
      ? Math.max.apply(
          Math,
          distributes.map(function (o) {
            return o.endValue;
          })
        )
      : 0;
    let minStartValue = distributes
      ? Math.min.apply(
          Math,
          distributes.map(function (o) {
            return o.startValue;
          })
        )
      : 0;
    let minEndValue = distributes
      ? Math.min.apply(
          Math,
          distributes.map(function (o) {
            return o.endValue;
          })
        )
      : 0;

    if (minStartValue < 0) {
      if (maxStartValue < -minStartValue) {
        maxStartValue = -minStartValue;
      }
    }
    if (minEndValue < 0) {
      if (maxEndValue < -minEndValue) {
        maxEndValue = -minEndValue;
      }
    }
    if (maxStartValue < maxEndValue) {
      maxStartValue = maxEndValue;
    }

    //and then, the max absolute Point Load - Force.
    maxForce = loads
      ? Math.max.apply(
          Math,
          loads.map(function (o) {
            return o.force ? o.force : 0;
          })
        )
      : 0;
    minForce = loads
      ? Math.min.apply(
          Math,
          loads.map(function (o) {
            return o.force ? o.force : 0;
          })
        )
      : 0;

    if (minForce < 0) {
      if (maxForce < -minForce) {
        maxForce = -minForce;
      }
    }

    //compare and keep the maximum value between max Point Load and max Dist. Load
    if (maxForce < maxStartValue) {
      maxForce = maxStartValue;
    }

    return maxForce;
  };

  const drawPointLoad = (
    ctx: CanvasRenderingContext2D,
    pointLoad: LoadProps
  ): void => {
    let relativePosition = ((16 * pointLoad.position) / beam) * step; //the value used to position the load on screen

    //force
    if (pointLoad.force && pointLoad.force !== 0) {
      const maxForce = findMaxForce();
      const forceScale = pointLoad.force / maxForce;

      if (pointLoad.force > 0) {
        ctx.translate(relativePosition, w / 160); //reset the origin
        ctx.beginPath();
        ctx.moveTo(0, 0); //move to load position
        ctx.lineTo(0, (w / 10) * forceScale); //point load's vertical line

        ctx.moveTo(w / 80, w / 80); //tick 1
        ctx.lineTo(0, 0);
        ctx.lineTo(-w / 80, w / 80); //tick 2

        ctx.strokeStyle = 'blue';
        ctx.stroke();

        let font = w >= 550 ? w / 55 : w / 40,
          text = pointLoad.force.toFixed(2) + 'kN';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'blue';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, (w / 10) * forceScale + w / 60);
      } else {
        ctx.translate(relativePosition, -w / 160);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, (-w / 10) * -forceScale);

        ctx.moveTo(-w / 80, -w / 80);
        ctx.lineTo(0, 0);
        ctx.lineTo(w / 80, -w / 80);

        ctx.strokeStyle = 'blue';
        ctx.stroke();

        let font = w >= 550 ? w / 55 : w / 40,
          text = pointLoad.force.toFixed(2) + 'kN';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'blue';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, (-w / 10) * -forceScale - w / 300);
      }

      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    }

    //moment
    if (pointLoad.moment && pointLoad.moment !== 0) {
      ctx.translate(relativePosition, w / 160);

      if (pointLoad.moment > 0) {
        ctx.beginPath();
        ctx.strokeStyle = 'blue';
        ctx.arc(0, 0, w / 40, -Math.PI / 2, Math.PI);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(-w / 33, w / 100);
        ctx.lineTo(-w / 40, 0);
        ctx.lineTo(-w / 80, w / 100);
        ctx.stroke();
      } else {
        ctx.beginPath();
        ctx.strokeStyle = 'blue';
        ctx.arc(0, 0, w / 40, 0, (3 * Math.PI) / 2);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(w / 33, w / 100);
        ctx.lineTo(w / 40, 0);
        ctx.lineTo(w / 80, w / 100);
        ctx.stroke();
      }

      if (pointLoad.force && pointLoad.force <= 0) {
        let font = w >= 550 ? w / 55 : w / 40,
          text = pointLoad.moment.toFixed(2) + 'kN.m';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'blue';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, w / 20);
      } else {
        let font = w >= 550 ? w / 55 : w / 40,
          text = pointLoad.moment.toFixed(2) + 'kN.m';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'blue';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, -w / 28);
      }

      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    }

    //show the position
    ctx.translate(relativePosition, w / 160);

    ctx.beginPath();
    ctx.moveTo(0, 3.6 * step);
    ctx.lineTo(0, 4.2 * step);
    ctx.lineWidth = w / 800;
    ctx.strokeStyle = 'blue';
    ctx.stroke();

    let font = w >= 550 ? w / 55 : w / 40,
      text = pointLoad.position.toFixed(2);
    ctx.font = font + 'px Arial';
    ctx.fillStyle = 'blue';
    let textWidth = ctx.measureText(text).width;
    ctx.fillText(text, -textWidth / 2, 3.5 * step);

    ctx.resetTransform();
    ctx.translate(origin.x, origin.y);
  };

  const drawDistLoad = (
    ctx: CanvasRenderingContext2D,
    distributedLoad: DistributesProps
  ): void => {
    const maxForce = findMaxForce();

    let relativeStartPosition =
      ((16 * distributedLoad.startPosition) / beam) * step;
    let relativeEndPosition =
      ((16 * distributedLoad.endPosition) / beam) * step;
    let startForceScale = distributedLoad.startValue / maxForce;
    let endForceScale = distributedLoad.endValue / maxForce;

    if (distributedLoad.startValue !== 0) {
      if (distributedLoad.startValue > 0) {
        ctx.translate(relativeStartPosition, w / 160); //change the origin
        ctx.beginPath();
        ctx.moveTo(0, 0); //move to load position
        ctx.lineTo(0, (w / 10) * startForceScale); //point load's vertical line

        ctx.moveTo(w / 80, w / 80); //tick 1
        ctx.lineTo(0, 0);
        ctx.lineTo(-w / 80, w / 80); //tick 2

        ctx.strokeStyle = 'red';
        ctx.stroke();

        let font = w >= 550 ? w / 55 : w / 40,
          text = distributedLoad.startValue.toFixed(2) + 'kN/m';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'red';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, (w / 10) * startForceScale + w / 60);
      } else {
        ctx.translate(relativeStartPosition, -w / 160);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, (-w / 10) * -startForceScale);

        ctx.moveTo(-w / 80, -w / 80);
        ctx.lineTo(0, 0);
        ctx.lineTo(w / 80, -w / 80);

        ctx.strokeStyle = 'red';
        ctx.stroke();

        let font = w >= 550 ? w / 55 : w / 40,
          text = distributedLoad.startValue.toFixed(2) + 'kN/m';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'red';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(
          text,
          -textWidth / 2,
          (-w / 10) * -startForceScale - w / 300
        );
      }

      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    } else {
      // start load = 0
      ctx.translate(relativeStartPosition, -w / 160);
      let font = w >= 550 ? w / 55 : w / 40,
        text = distributedLoad.startValue.toFixed(2) + 'kN/m';
      ctx.font = font + 'px Arial';
      ctx.fillStyle = 'red';
      let textWidth = ctx.measureText(text).width;
      ctx.fillText(text, -textWidth / 2, -w / 160);
      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    }

    //end Load
    if (distributedLoad.endValue !== 0) {
      if (distributedLoad.endValue > 0) {
        ctx.translate(relativeEndPosition, w / 160); //reset the origin
        ctx.beginPath();
        ctx.moveTo(0, 0); //move to load position
        ctx.lineTo(0, (w / 10) * endForceScale); //point load's vertical line

        ctx.moveTo(w / 80, w / 80); //tick 1
        ctx.lineTo(0, 0);
        ctx.lineTo(-w / 80, w / 80); //tick 2

        ctx.strokeStyle = 'red';
        ctx.stroke();

        let font = w >= 550 ? w / 55 : w / 40,
          text = distributedLoad.endValue.toFixed(2) + 'kN/m';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'red';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, (w / 10) * endForceScale + w / 60);
      } else {
        ctx.translate(relativeEndPosition, -w / 160);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, (-w / 10) * -endForceScale);

        ctx.moveTo(-w / 80, -w / 80);
        ctx.lineTo(0, 0);
        ctx.lineTo(w / 80, -w / 80);

        ctx.strokeStyle = 'red';
        ctx.stroke();
        let font = w >= 550 ? w / 55 : w / 40,
          text = distributedLoad.endValue.toFixed(2) + 'kN/m';
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'red';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(
          text,
          -textWidth / 2,
          (-w / 10) * -endForceScale - w / 300
        );
      } //else

      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    } else {
      // end load = 0
      ctx.translate(relativeEndPosition, -w / 160);
      let font = w >= 550 ? w / 55 : w / 40,
        text = distributedLoad.endValue.toFixed(2) + 'kN/m';
      ctx.font = font + 'px Arial';
      ctx.fillStyle = 'red';
      let textWidth = ctx.measureText(text).width;
      ctx.fillText(text, -textWidth / 2, -w / 160);

      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    }

    //show the start position
    ctx.translate(relativeStartPosition, w / 160);

    ctx.beginPath();
    ctx.moveTo(0, 3.6 * step);
    ctx.lineTo(0, 4.2 * step);
    ctx.lineWidth = w / 800;
    ctx.strokeStyle = 'red';
    ctx.stroke();

    let font = w >= 550 ? w / 55 : w / 40,
      text = distributedLoad.startPosition.toFixed(2);
    ctx.font = font + 'px Arial';
    ctx.fillStyle = 'red';
    let textWidth = ctx.measureText(text).width;
    ctx.fillText(text, -textWidth / 2, 3.5 * step);

    ctx.resetTransform();
    ctx.translate(origin.x, origin.y);

    //show the end position
    ctx.translate(relativeEndPosition, w / 160);

    ctx.beginPath();
    ctx.moveTo(0, 3.6 * step);
    ctx.lineTo(0, 4.2 * step);
    ctx.lineWidth = w / 800;
    ctx.strokeStyle = 'red';
    ctx.stroke();

    let text2 = distributedLoad.endPosition.toFixed(2);
    ctx.font = font + 'px Arial';
    ctx.fillStyle = 'red';
    let textWidth2 = ctx.measureText(text2).width;
    ctx.fillText(text2, -textWidth2 / 2, 3.5 * step);

    ctx.resetTransform();
    ctx.translate(origin.x, origin.y);

    //drawing the joining line
    ctx.resetTransform();
    ctx.translate(origin.x, origin.y);

    ctx.beginPath();
    if (distributedLoad.startValue > 0) {
      ctx.translate(relativeStartPosition, w / 160); //change the origin
      ctx.moveTo(0, (w / 10) * startForceScale);
    }
    if (distributedLoad.startValue < 0) {
      ctx.translate(relativeStartPosition, -w / 160);
      ctx.moveTo(0, (-w / 10) * -startForceScale);
    }
    if (distributedLoad.startValue === 0) {
      ctx.translate(relativeStartPosition, 0); //change the origin
      ctx.moveTo(0, 0);
    }

    ctx.resetTransform();
    ctx.translate(origin.x, origin.y);

    if (distributedLoad.endValue > 0) {
      ctx.translate(relativeEndPosition, w / 160); //change the origin
      ctx.lineTo(0, (w / 10) * endForceScale);
    }
    if (distributedLoad.endValue < 0) {
      ctx.translate(relativeEndPosition, -w / 160);
      ctx.lineTo(0, (-w / 10) * -endForceScale);
    }
    if (distributedLoad.endValue === 0) {
      ctx.translate(relativeEndPosition, 0); //change the origin
      ctx.lineTo(0, 0);
    }

    ctx.strokeStyle = 'red';
    ctx.stroke();

    ctx.resetTransform();
    ctx.translate(origin.x, origin.y);
  };

  const drawSupport = (
    ctx: CanvasRenderingContext2D,
    supports: SupportProps
  ): void => {
    let relativePosition = ((16 * supports.position) / beam) * step;
    switch (supports.type) {
      case 'roller':
        ctx.translate(relativePosition, w / 160);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-w / 40, w / 40);
        ctx.lineTo(w / 40, w / 40);
        ctx.closePath();
        ctx.strokeStyle = 'green';
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(w / 50, w / 33, w / 200, 0, 2 * Math.PI);
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(-w / 50, w / 33, w / 200, 0, 2 * Math.PI);
        ctx.stroke();

        //show the position
        ctx.beginPath();
        ctx.moveTo(0, 3.6 * step);
        ctx.lineTo(0, 4.2 * step);
        ctx.lineWidth = w / 800;
        ctx.strokeStyle = 'green';
        ctx.stroke();

        let font2 = w >= 550 ? w / 55 : w / 40,
          text2 = supports.position.toFixed(2);
        ctx.font = font2 + 'px Arial';
        ctx.fillStyle = 'green';
        let textWidth2 = ctx.measureText(text2).width;
        ctx.fillText(text2, -textWidth2 / 2, 3.5 * step);

        ctx.resetTransform();
        ctx.translate(origin.x, origin.y);
        break;

      case 'pin':
        ctx.translate(relativePosition, w / 160);

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-w / 40, w / 40);
        ctx.lineTo(w / 40, w / 40);
        ctx.closePath();

        ctx.moveTo(-w / 40, w / 40);
        ctx.lineTo(-w / 32, w / 32);

        ctx.moveTo(-w / 80, w / 40);
        ctx.lineTo(-w / 53, w / 32);

        ctx.moveTo(0, w / 40);
        ctx.lineTo(-w / 160, w / 32);

        ctx.moveTo(w / 80, w / 40);
        ctx.lineTo(w / 160, w / 32);

        ctx.moveTo(w / 40, w / 40);
        ctx.lineTo(w / 53, w / 32);

        ctx.strokeStyle = 'green';
        ctx.stroke();

        //show the position
        ctx.beginPath();
        ctx.moveTo(0, 3.6 * step);
        ctx.lineTo(0, 4.2 * step);
        ctx.lineWidth = w / 800;
        ctx.strokeStyle = 'green';
        ctx.stroke();

        let font = w >= 550 ? w / 55 : w / 40,
          text = supports.position.toFixed(2);
        ctx.font = font + 'px Arial';
        ctx.fillStyle = 'green';
        let textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, 3.5 * step); //-w/40
        ctx.resetTransform();
        ctx.translate(origin.x, origin.y);
        break;

      case 'fixed':
        ctx.translate(relativePosition, 0);
        if (supports.position === 0) {
          ctx.rotate(Math.PI / 2);
        } else ctx.rotate(-Math.PI / 2);

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-w / 27, 0);
        ctx.lineTo(w / 27, 0);
        ctx.closePath();

        ctx.moveTo(-w / 27, 0);
        ctx.lineTo(-w / 23, w / 80);

        ctx.moveTo(-w / 40, 0);
        ctx.lineTo(-w / 32, w / 80);

        ctx.moveTo(-w / 80, 0);
        ctx.lineTo(-w / 53, w / 80);

        ctx.moveTo(0, 0);
        ctx.lineTo(-w / 160, w / 80);

        ctx.moveTo(w / 80, 0);
        ctx.lineTo(w / 160, w / 80);

        ctx.moveTo(w / 40, 0);
        ctx.lineTo(w / 53, w / 80);

        ctx.moveTo(w / 27, 0);
        ctx.lineTo(w / 32, w / 80);

        ctx.strokeStyle = 'green';
        ctx.stroke();

        ctx.resetTransform();
        ctx.translate(origin.x, origin.y);
        break;
    }
  };

  const drawGrid = (ctx: CanvasRenderingContext2D): void => {
    ctx.beginPath();
    for (let x = 0; x <= w; x += step) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, h);
    }
    // set the color of the line
    ctx.strokeStyle = 'rgb(240, 240, 245)';
    ctx.lineWidth = 1;
    // the stroke will actually paint the current path
    ctx.stroke();
    // for the sake of the example 2nd path
    ctx.beginPath();
    for (let y = 0; y <= h; y += step) {
      ctx.moveTo(0, y);
      ctx.lineTo(w, y);
    }
    // set the color of the line
    ctx.strokeStyle = 'rgb(240, 240, 245)';
    // just for fun
    ctx.lineWidth = 1;
    // for your original question - you need to stroke only once
    ctx.stroke();
  };

  const drawBar = (ctx: CanvasRenderingContext2D, isPDF: boolean): void => {
    const L =
      Math.round(parseFloat(JSON.parse(JSON.stringify(beam))) * 100) / 100; //copy of tempL

    //starting by erasing everything on screen
    ctx.resetTransform();
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    if (showGrid && !isPDF) drawGrid(ctx);
    ctx.translate(origin.x, origin.y);

    //step 1 - it will design the bar
    bar = { x0: 0, y0: 0, x1: 16 * step, y1: 0 };
    ctx.beginPath();
    ctx.moveTo(bar.x0, bar.y0);
    ctx.lineTo(bar.x1, bar.y1);
    ctx.lineWidth = w / 80;
    ctx.strokeStyle = 'rgb(0, 0, 102)';
    ctx.stroke();

    //step 2 - it will design the quotation line below
    ctx.beginPath();
    ctx.moveTo(0, 4 * step);
    ctx.lineTo(16 * step, 4 * step);
    ctx.lineWidth = w / 800;
    ctx.strokeStyle = 'black';
    ctx.stroke();

    //step 3 - it will design the left tick
    ctx.beginPath();
    ctx.moveTo(0, 3.7 * step);
    ctx.lineTo(0, 4.3 * step);
    ctx.lineWidth = w / 800;
    ctx.strokeStyle = 'black';
    ctx.stroke();

    //step 4 - it will design the right tick
    ctx.beginPath();
    ctx.moveTo(16 * step, 3.7 * step);
    ctx.lineTo(16 * step, 4.3 * step);
    ctx.lineWidth = w / 800;
    ctx.strokeStyle = 'black';
    ctx.stroke();

    //step 5 - show text in the middle
    ctx.resetTransform();
    let font = w >= 550 ? w / 50 : w / 30,
      text = 'Beam Length = ' + L.toFixed(2) + ' m';
    ctx.font = font + 'px Arial';
    ctx.fillStyle = 'black';
    let textWidth = ctx.measureText(text).width;
    ctx.fillText(text, w / 2 - textWidth / 2, 9.5 * step);
    ctx.translate(origin.x, origin.y);
  };

  const drawReactions = (
    ctx: CanvasRenderingContext2D,
    support: SupportProps
  ): void => {
    let relativePosition = ((16 * support.position) / beam) * step; //the value used to position the load on screen

    if (support.reactionForce >= 0) {
      ctx.translate(relativePosition, w / 160);
      ctx.beginPath();
      ctx.moveTo(0, w / 35);
      ctx.lineTo(0, w / 10);

      ctx.moveTo(w / 80, w / 80 + w / 35);
      ctx.lineTo(0, w / 35);
      ctx.lineTo(-w / 80, w / 80 + w / 35);

      ctx.strokeStyle = 'purple';
      ctx.stroke();

      let font = w >= 550 ? w / 55 : w / 40;
      let text = support.reactionForce.toFixed(2) + 'kN';

      ctx.font = font + 'px Arial';
      ctx.fillStyle = 'purple';
      let textWidth = ctx.measureText(text).width;
      ctx.fillText(text, -textWidth / 2, w / 10 + w / 60);
      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    } else {
      ctx.translate(relativePosition, w / 160);
      ctx.beginPath();
      ctx.moveTo(0, w / 35);
      ctx.lineTo(0, w / 10);
      ctx.moveTo(w / 80, w / 10 - w / 80);
      ctx.lineTo(0, w / 10);
      ctx.lineTo(-w / 80, w / 10 - w / 80);
      ctx.strokeStyle = 'purple';
      ctx.stroke();
      let font = w >= 550 ? w / 55 : w / 40,
        text = support.reactionForce.toFixed(2) + 'kN';
      ctx.font = font + 'px Arial';
      ctx.fillStyle = 'purple';
      let textWidth = ctx.measureText(text).width;
      ctx.fillText(text, -textWidth / 2, w / 10 + w / 60);
      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    }

    if (support.type === 'fixed') {
      if (support.reactionMoment <= 0) {
        ctx.translate(relativePosition, w / 160);
        ctx.beginPath();
        ctx.strokeStyle = 'purple';
        ctx.arc(0, 0, w / 40, -Math.PI / 2, Math.PI);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(-w / 33, w / 100);
        ctx.lineTo(-w / 40, 0);
        ctx.lineTo(-w / 80, w / 100);
        ctx.stroke();
      } else {
        ctx.translate(relativePosition, w / 160);
        ctx.beginPath();
        ctx.strokeStyle = 'purple';
        ctx.arc(0, 0, w / 40, 0, (3 * Math.PI) / 2);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(w / 33, w / 100);
        ctx.lineTo(w / 40, 0);
        ctx.lineTo(w / 80, w / 100);
        ctx.stroke();
      }

      let font = w >= 550 ? w / 55 : w / 40,
        text = support.reactionMoment.toFixed(2) + 'kN.m';
      ctx.font = font + 'px Arial';
      ctx.fillStyle = 'purple';
      let textWidth = ctx.measureText(text).width;
      ctx.fillText(text, -textWidth / 2, -w / 20);

      ctx.resetTransform();
      ctx.translate(origin.x, origin.y);
    }
  };

  const drawCanvas = (): void => {
    const ctx = beamChartRef.current?.getContext('2d');
    const ctxPDF = beamChartPDFRef.current?.getContext('2d');

    if (ctx) {
      if (showGrid) {
        drawGrid(ctx);
      }

      ctx.translate(origin.x, origin.y);

      drawBar(ctx, false);

      if (showSupports && supports) {
        supports.forEach((sup: SupportProps) => {
          drawSupport(ctx, sup);
        });
      }
      if (showReactions && supports && !editable) {
        supports.forEach((sup: SupportProps) => {
          drawReactions(ctx, sup);
        });
      }
      if (showPointLoads && loads) {
        loads.forEach((load: LoadProps) => {
          drawPointLoad(ctx, load);
        });
      }
      if (showDistLoads && distributes) {
        distributes.forEach((distribute: DistributesProps) => {
          drawDistLoad(ctx, distribute);
        });
      }
    }

    if (ctxPDF) {
      ctxPDF.translate(origin.x, origin.y);

      drawBar(ctxPDF, true);

      if (supports) {
        supports.forEach((sup: SupportProps, i) => {
          drawSupport(ctxPDF, sup);
          drawReactions(ctxPDF, sup);
        });
      }
      if (loads) {
        loads.forEach((load: LoadProps) => {
          drawPointLoad(ctxPDF, load);
        });
      }
      if (distributes) {
        distributes.forEach((distribute: DistributesProps) => {
          drawDistLoad(ctxPDF, distribute);
        });
      }

      if (setCanvasImage) {
        setCanvasImage(ctxPDF.canvas.toDataURL());
      }
    }
  };

  const handleResize = (): void => {
    const [w, h] = getCanvasSize();

    setCanvasWidth(w);
    setCanvasLength(h);
  };

  //  TODO: Version 2
  // const handleEdit = (event: any): void => {
  //   let x = event.offsetX;
  //   let y = event.offsetY;

  //   console.log({ x, y, bar });

  //   // TODO: find coordinates for line
  //   if (x > bar.x0 && x < bar.x1 && y > 230 && y < 250) {
  //     if (setShowBeamModal) setShowBeamModal(true);
  //   }
  // };

  // initial useEffect
  useEffect(() => {
    const removeEventListeners = () => {
      window.removeEventListener('resize', handleResize);
      // if (editable)
      //   beamChartRef.current?.removeEventListener('click', handleEdit);
    };

    window.addEventListener('resize', handleResize);
    // if (editable) beamChartRef.current?.addEventListener('click', handleEdit);

    // scale();
    drawCanvas();

    return () => removeEventListeners();
  }, [beam, supports, distributes, loads]);

  // useEffect resize
  useEffect((): void => {
    drawCanvas();
  }, [w, h]);

  // useEffect filtering
  useEffect((): void => {
    drawCanvas();
  }, [showDistLoads, showPointLoads, showSupports, showGrid, showReactions]);

  return (
    <S.Card>
      <S.CardContent ref={cardContentRef}>
        <canvas width={w} height={h} ref={beamChartRef}></canvas>
        <S.CanvasPDF width={w} height={h} ref={beamChartPDFRef}></S.CanvasPDF>
      </S.CardContent>
    </S.Card>
  );
};

export default BeamCanvas;
