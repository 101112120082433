import { registerUser, signInWithGoogle } from '../api/userAPI';
import { db } from '../firebase';

const usersRef = db.collection('users');

export const createUser = async (
  email: string,
  password: string
): Promise<void> => {
  try {
    const result = await registerUser(email, password);

    await usersRef.doc(result.user?.uid).set({
      email,
      name: '',
      lastName: '',
      createAt: new Date().toISOString(),
      image: null
    });
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (
  userId: string,
  fields: object
): Promise<void> => {
  try {
    await usersRef.doc(userId).update({
      ...fields
    });
  } catch (error) {
    throw error;
  }
};

export const getMaterials = async (userId: string): Promise<any> => {
  try {
    const user = await usersRef.doc(userId).get();

    return user;
  } catch (error) {
    throw error;
  }
};

export const createUserWithGoogle = async (): Promise<void> => {
  try {
    const result = await signInWithGoogle();

    await usersRef.doc(result.user?.uid).set({
      email: result.user?.email,
      name: '',
      lastName: '',
      createAt: new Date().toISOString(),
      image: null
    });
  } catch (error) {
    throw error;
  }
};
