import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactGa from 'react-ga';
import { IonAlert, IonButton, IonCardContent, IonIcon } from '@ionic/react';
import {
  ellipsisHorizontal,
  removeOutline,
  swapVerticalOutline
} from 'ionicons/icons';
import { SupportType } from '../../interfaces';
import * as S from './style';

const supportOptions: SupportType[] = ['pin', 'roller', 'fixed'];

interface Props {
  setShowBeamAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDistributedLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPointLoadModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMultipleSupportModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSupportPopover: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      event: Event | undefined;
    }>
  >;
  setShowSupportModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: SupportType | undefined;
    }>
  >;
  showSupportPopover: any;
  hasBeam: boolean;
  disableSupportButton: boolean;
}

const BeamControllerCard: React.FC<Props> = ({
  hasBeam,
  setShowBeamAlert,
  showSupportPopover,
  setShowSupportPopover,
  setShowSupportModal,
  setShowMultipleSupportModal,
  setShowPointLoadModal,
  setShowDistributedLoad,
  disableSupportButton
}) => {
  const { t } = useTranslation();

  const getSupportName = (sup: SupportType): string => {
    if (sup === 'pin') {
      return t('pin');
    }
    if (sup === 'roller') {
      return t('roller');
    }
    return t('fixed');
  };

  const SupportButton = ({ name }: { name: string }): JSX.Element => (
    <>
      <IonButton
        color="primary"
        disabled={!hasBeam || disableSupportButton}
        onClick={e =>
          setShowSupportPopover({
            open: true,
            event: e.nativeEvent
          })
        }
      >
        <IonIcon slot="start" icon={ellipsisHorizontal} />
        {name}
      </IonButton>
      <IonAlert
        isOpen={showSupportPopover.open}
        onDidDismiss={() =>
          setShowSupportPopover({
            open: false,
            event: undefined
          })
        }
        header={t('choose the type of support')}
        inputs={supportOptions.map((sup: SupportType) => ({
          name: getSupportName(sup),
          type: 'radio',
          label: getSupportName(sup),
          value: sup
        }))}
        buttons={[
          t('cancel'),
          {
            text: t('choose'),
            handler: (res: SupportType | undefined) => {
              if (res !== undefined) {
                setShowSupportModal({
                  open: true,
                  type: res
                });
              }
              setShowSupportPopover({
                open: false,
                event: undefined
              });

              ReactGa.event({
                category: 'XBeam - Beam Support Type Alert',
                action: `User clicked on ${res} button`
              });
            }
          }
        ]}
      />
    </>
  );

  const buttons = [
    {
      name: t('add beam'),
      icon: removeOutline,
      handler: (): void => {
        setShowBeamAlert(true);
        ReactGa.event({
          category: 'XBeam - Beam Controller Card',
          action: 'User pressed "add button" button'
        });
      }
    },
    {
      name: t('add support'),
      icon: ellipsisHorizontal,
      handler: (e: any): void => {
        setShowSupportPopover({
          open: true,
          event: e.nativeEvent
        });
        ReactGa.event({
          category: 'XBeam - Beam Controller Card',
          action: 'User pressed "add support" button'
        });
      }
    },
    {
      name: t('add multiple support'),
      icon: ellipsisHorizontal,
      handler: (): void => {
        setShowMultipleSupportModal(true);
        ReactGa.event({
          category: 'XBeam - Beam Controller Card',
          action: 'User pressed "add multiple support" button'
        });
      }
    },
    {
      name: t('add load'),
      icon: swapVerticalOutline,
      handler: (): void => {
        setShowPointLoadModal(true);
        ReactGa.event({
          category: 'XBeam - Beam Controller Card',
          action: 'User pressed "add load" button'
        });
      }
    },
    {
      name: t('add distributed load'),
      icon: swapVerticalOutline,
      handler: (): void => {
        setShowDistributedLoad(true);
        ReactGa.event({
          category: 'XBeam - Beam Controller Card',
          action: 'User pressed "add distributed load" button'
        });
      }
    }
  ];

  return (
    <S.Card>
      <IonCardContent>
        {buttons.map(button => {
          if (button.name === 'Suporte' || button.name === 'Add Support') {
            return <SupportButton key={button.name} name={button.name} />;
          }

          return (
            <IonButton
              key={button.name}
              color={button.name === t('add beam') ? 'tertiary' : 'primary'}
              disabled={
                button.name === 'Adicionar Viga' || button.name === 'Add Beam'
                  ? hasBeam
                  : !hasBeam
              }
              onClick={button.handler}
            >
              <IonIcon slot="start" icon={button.icon} />
              {button.name}
            </IonButton>
          );
        })}
      </IonCardContent>
    </S.Card>
  );
};

export default BeamControllerCard;
