import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyD8aie6O-8wGsrndwT8LhWfryj0aqmQ1cg',
  authDomain: 'no-name-d24d4.firebaseapp.com',
  databaseURL: 'https://no-name-d24d4.firebaseio.com',
  projectId: 'no-name-d24d4',
  storageBucket: 'no-name-d24d4.appspot.com',
  messagingSenderId: '57075835085',
  appId: '1:57075835085:web:b54da2ee3d0b4956cc8984',
  measurementId: 'G-LF0YWVV3RS'
};

export const app = firebase.initializeApp(firebaseConfig);

export const increment = firebase.firestore.FieldValue.increment(1);
export const db = app.firestore();
export const auth = firebase.auth();
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
// export const TwitterAuthProvider = firebase.auth.TwitterAuthProvider;
// export const FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
