import React from "react";

interface Props {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  srcSet?: string;
  sizes?: string;
  type?: "image/jpg" | "image/png";
}

const Image = ({
  src,
  srcSet,
  sizes,
  alt = "",
  type = "image/jpg",
  width,
  height,
}: Props) => (
  <picture>
    {srcSet && <source type={type} srcSet={srcSet} sizes={sizes} />}
    <img
      src={src}
      alt={alt}
      loading="lazy"
      decoding="async"
      width={width}
      height={height}
    />
  </picture>
);

export default Image;
