import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import ReactGa from 'react-ga';
import {
  addOutline,
  closeCircleOutline,
  pencilOutline,
  trashOutline
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setItemInactive } from '../../utils';
import { SupportProps } from '../../interfaces';

import * as S from './style';

interface Props {
  beam?: number;
  isActive: boolean;
  showSupport: boolean;
  supports: SupportProps[];
  setSupports: (supports: SupportProps[]) => void;
  showSupportAlert: any;
  setShowSupportAlert: any;
}

const getInitialValue = (supports: SupportProps[]): number | undefined => {
  const activeItem = supports.find(
    (sup: SupportProps) => sup.isActive === true
  );

  return activeItem !== undefined ? activeItem.position : undefined;
};

const BeamModal: React.FC<Props> = ({
  beam,
  isActive,
  showSupport,
  setShowSupportAlert,
  supports,
  setSupports,
  showSupportAlert
}) => {
  const { t } = useTranslation();
  const [supportPosition, setSupportPosition] = useState<number | undefined>(
    getInitialValue(supports)
  );

  const allowSupport = (supportPosition: number | string): boolean => {
    let isValid = false;
    // TODO: refactor 'always type of string'
    const position =
      typeof supportPosition === 'string'
        ? parseFloat(supportPosition)
        : supportPosition;

    const hasSupport = supports.some(
      (sup: SupportProps) => sup.position === position
    );

    if (
      !hasSupport &&
      showSupportAlert.type === 'fixed' &&
      (position === 0 || position === beam)
    ) {
      isValid = true;
    }
    if (
      beam &&
      !hasSupport &&
      (showSupportAlert.type === 'pin' || showSupportAlert.type === 'roller') &&
      position >= 0 &&
      position <= beam
    ) {
      isValid = true;
    }

    return isValid;
  };

  useEffect(() => {
    setSupportPosition(getInitialValue(supports));
  }, [supports]);

  const onDidDismiss = () => {
    setItemInactive({ list: supports, callback: setSupports });
    setShowSupportAlert({ open: false, type: undefined });
    setSupportPosition(undefined);
  };

  return (
    <IonModal onDidDismiss={onDidDismiss} isOpen={showSupport}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('support')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position="floating">
            {showSupportAlert.type === 'fixed'
              ? `${t('provide position')} (0 ${t('or')} ${beam}m)`
              : `${t('provide position')} (${t('between')} 0 ${t(
                  'and'
                )} ${beam}m)`}{' '}
          </IonLabel>
          <IonInput
            value={supportPosition}
            type="number"
            inputMode="numeric"
            placeholder={
              showSupportAlert.type === 'fixed'
                ? `${t('position')} (0 ${t('or')} ${beam}m)`
                : `${t('position')} (${t('beetwen')} 0 ${t('and')} ${beam}m)`
            }
            onIonChange={(e: any) => setSupportPosition(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonContent>
      <S.Footer>
        {isActive ? (
          <>
            <IonButton color="primary" onClick={onDidDismiss}>
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              color="danger"
              onClick={(): void => {
                setSupports(
                  supports.filter(({ isActive }) => isActive !== true)
                );
                setShowSupportAlert({ open: false, type: undefined });
                ReactGa.event({
                  category: 'XBeam - Support Modal',
                  action: 'User pressed "remove" button'
                });
              }}
            >
              <IonIcon slot="start" icon={trashOutline} />
              {t('remove')}
            </IonButton>
            <IonButton
              color="tertiary"
              onClick={() => {
                const isValid = supportPosition
                  ? allowSupport(supportPosition)
                  : false;
                if (supportPosition && isValid) {
                  const itemIndex = supports.findIndex(
                    (sup: SupportProps) => sup.isActive === true
                  );
                  const newSupports = [...supports];

                  newSupports[itemIndex] = {
                    ...supports[itemIndex],
                    position: Math.round(supportPosition * 100) / 100
                  };

                  setSupports(newSupports);
                  setShowSupportAlert({ open: false, type: undefined });
                }
                ReactGa.event({
                  category: 'XBeam - Support Modal',
                  action: 'User pressed "edit" button'
                });
              }}
            >
              <IonIcon slot="start" icon={pencilOutline} />
              {t('edit')}
            </IonButton>
          </>
        ) : (
          <>
            <IonButton color="danger" onClick={onDidDismiss}>
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              onClick={(): void => {
                const isValid =
                  supportPosition && allowSupport(supportPosition);
                if (isValid && showSupportAlert && showSupportAlert.type) {
                  setSupports([
                    ...supports,
                    {
                      position: Math.round(supportPosition * 100) / 100,
                      type: showSupportAlert.type,
                      id: Math.floor(Math.random() * Date.now()),
                      reactionForce: 0,
                      reactionMoment: 0,
                      force: 0,
                      moment: 0
                    }
                  ]);
                } else {
                  alert(t('beam error three'));
                }
                onDidDismiss();
                ReactGa.event({
                  category: 'XBeam - Support Modal',
                  action: 'User pressed "add" button'
                });
              }}
            >
              <IonIcon slot="start" icon={addOutline} />
              {t('add')}
            </IonButton>
          </>
        )}
      </S.Footer>
    </IonModal>
  );
};

export default BeamModal;
