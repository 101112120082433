import { IonContent, IonLabel } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import ReactGa from 'react-ga';
import { setupConfig } from '@ionic/react';
import Footer from 'components/Footer/Footer';
import Button from 'components/Button/Button';
import xbeamImage from './assets/xbeam.png';
import * as S from './styles';

setupConfig({
  swipeBackEnabled: false,
  hardwareBackButton: false
});

const UpdateApp = (): JSX.Element => {
  const { t } = useTranslation();

  const handleAppUpdate = (): void => {
    const store = Capacitor.getPlatform() === 'ios' ? 'apple' : 'google';

    ReactGa.event({
      category: 'XBeam - Update Screen',
      action: `User pressed update button`
    });

    if (store === 'apple') {
      window.open('itms-apps://itunes.apple.com/app/id1620236044', '_blank');
    }

    if (store === 'google') {
      window.open(
        'https://play.google.com/store/apps/details?id=app.xbeam&hl=en_US&gl=US',
        '_blank'
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>XBeam</title>
      </Helmet>
      <IonContent className="ion-padding">
        <S.Container>
          <S.Image src={xbeamImage} alt="XBeam" />
          <h1>New Version Available</h1>
          <IonLabel>
            There is a newer version of this app available. Please download the
            latest version to continue.
          </IonLabel>
        </S.Container>
        <Button
          onClick={handleAppUpdate}
          color="light"
          expand="full"
          shape="round"
        >
          Update
        </Button>
      </IonContent>
      <Footer />
    </>
  );
};

export default UpdateApp;
