import React, { useContext, useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonCardContent,
  IonItem,
  IonLabel,
  IonIcon
} from '@ionic/react';
import { arrowBack, cloudUploadOutline, documentOutline } from 'ionicons/icons';
import ReactGa from 'react-ga';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import usePDF from 'hooks/usePDF';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import Image from 'components/Image/Image';
import BeamCanvas from 'components/BeamCanvas/BeamCanvas';
import Document from 'components/Document2/Document';
import BeamSaveModal from 'components/BeamSaveModal/BeamSaveModal';
import { UserContext } from 'providers/AuthProvider';
import { addBeam } from 'providers/BeamProvider';
import NoResultsImage from './assets/placeholder.png';
import * as S from './style';
import Cards from './Cards';
import { findHighestValue2, getUnitLabel } from '../../utils';
import { SupportProps, DistributesProps, LoadProps } from '../../interfaces';
import { Link } from 'react-router-dom';

const findHighestValue = (
  supports: SupportProps[],
  type: string
): { pos: string | undefined; value: string } => {
  const maxValue = Math.max(...supports.map((sup: any) => sup[type]));
  const minValue = Math.min(...supports.map((sup: any) => sup[type]));

  const value = Math.abs(maxValue) > Math.abs(minValue) ? maxValue : minValue;
  const sup = supports.find((n: any) => n[type] === value);

  return {
    pos: sup?.position.toFixed(2),
    value: value.toFixed(2)
  };
};

const getSWLText = (
  maxValue: string | undefined,
  value: string,
  type: string
): JSX.Element => {
  const unit: string = getUnitLabel(type);

  if (maxValue && maxValue !== '0') {
    if (Math.abs(parseFloat(value)) > Math.abs(parseFloat(maxValue))) {
      return (
        <span>
          {`(SWL =+/-${maxValue}${unit})`}{' '}
          <span className="red-color">Failed!</span>
        </span>
      );
    } else {
      return (
        <>
          {`(SWL =+/-${maxValue}${unit})`}{' '}
          <span className="success-color">Pass!</span>
        </>
      );
    }
  }

  return <></>;
};

const BeamResults: React.FC = (): JSX.Element => {
  const { state } = useLocation() as {
    state: {
      global_Q: number[][];
      global_M: number[][];
      global_V: number[][];
      beamSupports: SupportProps[];
      beam: number;
      properties: {
        inertia: string;
        elastic: string;
        area?: string;
        maxMoment?: string;
        maxReaction?: string;
        maxShearForce?: string;
      };
      maxAllowedDeflection: string;
      maxDeflection: string;
      activeSectionId: string;
      distributes: DistributesProps[];
      loads: LoadProps[];
    };
  };

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { uploadFile, fileUrl } = usePDF();
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [drawGrid, setDrawGrid] = useState<boolean>(true);
  const [drawReactions, setDrawReactions] = useState<boolean>(true);
  const [drawSupports, setDrawSupports] = useState<boolean>(true);
  const [drawPointLoads, setDrawPointLoads] = useState<boolean>(true);
  const [drawDistLoads, setDrawDistLoads] = useState<boolean>(true);
  const [canvasImage, setCanvasImage] = useState<string>('');
  const [images, setImages] = useState<Array<{ name: string; image: string }>>(
    []
  );

  const global_Q = state?.global_Q;
  const global_M = state?.global_M;
  const global_V = state?.global_V;
  const beam = state?.beam;
  const supports = state?.beamSupports;
  const elasticModulus = state?.properties.elastic;
  const inertiaMoment = state?.properties.inertia;
  // const area = state?.properties.area;
  const maxMomentValue = state?.properties.maxMoment;
  const maxReactionValue = state?.properties.maxReaction;
  const maxShearForceValue = state?.properties.maxShearForce;
  const maxAllowedDeflection = state?.maxAllowedDeflection;
  const loads = state?.loads;
  const distributes = state?.distributes;
  const maxDeflection = state?.maxDeflection;
  const activeSectionId = state?.activeSectionId;
  const maxQ =
    global_Q && global_Q.length > 0 ? findHighestValue2(global_Q) : undefined;
  const maxM =
    global_M && global_M.length > 0 ? findHighestValue2(global_M) : undefined;
  const maxV =
    global_V && global_V.length > 0 ? findHighestValue2(global_V) : undefined;
  const maxReaction =
    supports && supports.length > 0
      ? findHighestValue(supports, 'reactionForce')
      : undefined;
  const maxMoment =
    supports && supports.length > 0
      ? findHighestValue(supports, 'reactionMoment')
      : undefined;
  const hasFixedSupport =
    supports && supports.length > 0
      ? supports.some((sup: SupportProps) => sup?.type === 'fixed')
      : false;

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGa.set({ page: window.location.pathname });
      ReactGa.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const onPDFRender = (props: { blob?: Blob }): void => {
    if (props.blob) {
      uploadFile(props.blob, `Beam${new Date().getTime()}`);
    }
  };

  const handleBackButton = (): void => {
    navigate('/beam', {
      state: {
        beam,
        supports: supports.map(sup => ({
          ...sup,
          reactionForce: 0,
          reactionMoment: 0
        })),
        loads,
        activeSectionId,
        distributes,
        elasticModulus,
        inertiaMoment,
        maxMomentValue,
        maxReactionValue,
        maxShearForceValue,
        maxDeflection
      }
    });
  };

  const handleSaveBeam = (name: string): void => {
    if (user) {
      const E = parseFloat(elasticModulus);
      const I = parseFloat(inertiaMoment);

      addBeam(user?.uid, {
        date: new Date().toISOString(),
        loads,
        name,
        distributes,
        sups: supports.sort(
          (a: SupportProps, b: SupportProps) => a.position - b.position
        ),
        E,
        I,
        L: beam
      });

      alert('Beam successfully saved');
    }

    ReactGa.event({
      category: 'XBeam',
      action: 'User pressed "save beam" button'
    });
  };

  return (
    <>
      <BeamSaveModal
        onSave={handleSaveBeam}
        isActive={showSaveModal}
        setShowSaveModal={setShowSaveModal}
      />
      <Helmet>
        <title>{t('meta title results')}</title>
        <meta name="description" content={t('meta description results')} />
      </Helmet>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleBackButton}>
              <IonIcon slot="start" icon={arrowBack}></IonIcon>
              {t('edit beam')}
            </IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">XBeam</IonTitle>
          <IonButtons slot="end">
            <IonMenuButton style={{ color: '#000' }} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <S.Content className="ion-padding-horizontal">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t('summary')}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {maxMoment !== undefined && hasFixedSupport && (
              <>
                <b>{t('max moment reaction')}</b> {maxMoment.value}kN.m @{' '}
                {maxMoment.pos}m{' '}
                {getSWLText(
                  maxMomentValue,
                  maxMoment.value,
                  'max-moment-reaction'
                )}
                <br />
                <br />
              </>
            )}
            {maxReaction !== undefined && (
              <>
                <b>{t('max force reaction')}</b> {maxReaction.value}kN @{' '}
                {maxReaction.pos}m{' '}
                {getSWLText(
                  maxReactionValue,
                  maxReaction.value,
                  'max-force-reaction'
                )}
                <br />
                <br />
              </>
            )}
            {maxQ !== undefined && (
              <>
                <b>{t('max cutting effort')}</b> {maxQ.value}kN @ {maxQ.pos}m{' '}
                {getSWLText(
                  maxShearForceValue,
                  maxQ.value,
                  'max-cutting-effort'
                )}
                <br />
                <br />
              </>
            )}
            {maxM !== undefined && (
              <>
                <b>{t('max bending moment')}</b>{' '}
                {Number(maxM.value) > 0
                  ? `-${maxM.value}kN.m`
                  : `${Math.abs(Number(maxM.value))}kN.m`}{' '}
                @ {maxM.pos}m{' '}
                {getSWLText(maxMomentValue, maxM.value, 'max-bending-moment')}
                <br />
                <br />
              </>
            )}
            {maxV !== undefined && (
              <>
                <b>{t('max displacement')}</b> {maxV.value}mm @ {maxV.pos}m{' '}
                {maxDeflection > '0' &&
                  getSWLText(
                    maxAllowedDeflection,
                    maxV.value,
                    'max-displacement'
                  )}
                <br />
                <br />
              </>
            )}
            {fileUrl && (
              <S.PDFLink
                expand="full"
                shape="round"
                color="dark"
                onClick={(): void => {
                  window.open(fileUrl, '_blank');
                }}
              >
                <S.DocumentIcon icon={documentOutline} />{' '}
                {t('pdf file download')}
              </S.PDFLink>
            )}
            {images.length > 2 && (
              <S.DocumentContainer
                detail={false}
                lines="none"
                className="ion-text-center"
              >
                <IonLabel>
                  <IonIcon icon={documentOutline} />{' '}
                  <S.PDFButton
                    document={
                      <Document
                        graphs={images}
                        canvasImage={canvasImage}
                        inertiaMoment={inertiaMoment}
                        elasticModulus={elasticModulus}
                        beam={beam}
                        onPDFRender={onPDFRender}
                        loads={loads}
                        distributes={distributes}
                        supports={supports}
                        maxMoment={maxMoment}
                        maxReaction={maxReaction}
                        maxReactionValue={maxReactionValue}
                        maxShearForceValue={maxShearForceValue}
                        maxMomentValue={maxMomentValue}
                        maxDeflection={maxDeflection}
                        maxAllowedDeflection={maxAllowedDeflection}
                        maxQ={maxQ}
                        maxM={maxM}
                        maxV={maxV}
                      />
                    }
                    fileName="xbeam.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'loading document...' : t('pdf file download')
                    }
                  </S.PDFButton>
                </IonLabel>
              </S.DocumentContainer>
            )}
            <S.SaveBeamButton
              color="tertiary"
              onClick={(): void => setShowSaveModal(true)}
              expand="full"
              shape="round"
              disabled={user === null}
            >
              <S.ButtonIcon icon={cloudUploadOutline} />
              {t('beam-save')}
            </S.SaveBeamButton>
          </IonCardContent>
        </IonCard>
        <IonItem lines="none">
          <IonLabel color="danger">{t('found an error')}</IonLabel>
          <Link to="/contact" className="link-button-primary">
            {t('contact')}
          </Link>
        </IonItem>
        {state === undefined && (
          <IonCard className="ion-text-center">
            <IonCardHeader>
              <IonCardTitle>{t('no beams were found')}</IonCardTitle>
            </IonCardHeader>
            <S.CardContent>
              <Image
                src={NoResultsImage}
                alt={t('no beams were found')}
                type="image/png"
              />
              <IonButton
                expand="full"
                shape="round"
                routerLink="beam"
                fill="solid"
                onClick={(): void => {
                  ReactGa.event({
                    category: 'Beam Results - No Results',
                    action: 'User pressed "solve beam" button'
                  });
                }}
              >
                {t('solve beam')}
              </IonButton>
            </S.CardContent>
          </IonCard>
        )}
        {state !== undefined && (
          <>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{t('filters')}</IonCardTitle>
              </IonCardHeader>
              <S.ButtonsCardContent>
                <IonButton
                  onClick={(): void => {
                    ReactGa.event({
                      category: 'Beam Results',
                      action: 'User pressed "draw grid" button'
                    });
                    setDrawGrid(prev => !prev);
                  }}
                  color={drawGrid ? 'primary' : 'danger'}
                >
                  {t('grid')}
                </IonButton>
                <IonButton
                  onClick={() => setDrawSupports(prev => !prev)}
                  color={drawSupports ? 'primary' : 'danger'}
                >
                  {t('supports')}
                </IonButton>
                {loads && loads.length > 0 && (
                  <IonButton
                    onClick={(): void => {
                      ReactGa.event({
                        category: 'Beam Results',
                        action: 'User pressed "draw point loads" button'
                      });
                      setDrawPointLoads(prev => !prev);
                    }}
                    color={drawPointLoads ? 'primary' : 'danger'}
                  >
                    {t('pointed loads')}
                  </IonButton>
                )}
                {distributes && distributes.length > 0 && (
                  <IonButton
                    onClick={(): void => {
                      ReactGa.event({
                        category: 'Beam Results',
                        action: 'User pressed "draw distributes loads" button'
                      });
                      setDrawDistLoads(prev => !prev);
                    }}
                    color={drawDistLoads ? 'primary' : 'danger'}
                  >
                    {t('distributes')}
                  </IonButton>
                )}
                {
                  <IonButton
                    onClick={(): void => {
                      ReactGa.event({
                        category: 'Beam Results',
                        action: 'User pressed "draw reactions" button'
                      });
                      setDrawReactions(prev => !prev);
                    }}
                    color={drawReactions ? 'primary' : 'danger'}
                  >
                    {t('reactions')}
                  </IonButton>
                }
              </S.ButtonsCardContent>
            </IonCard>
            <BeamCanvas
              showDistLoads={drawDistLoads}
              setCanvasImage={setCanvasImage}
              showPointLoads={drawPointLoads}
              showSupports={drawSupports}
              showGrid={drawGrid}
              showReactions={drawReactions}
              beam={beam}
              supports={supports}
              loads={loads}
              distributes={distributes}
            />
            <Cards
              maxMomentValue={maxMomentValue}
              maxShearForceValue={maxShearForceValue}
              maxAllowedDeflection={maxAllowedDeflection}
              global_Q={global_Q}
              global_M={global_M?.map(globalM => [globalM[0], -globalM[1]])}
              global_V={global_V}
              setImages={setImages}
            />
          </>
        )}
      </S.Content>
      <Footer />
    </>
  );
};

export default BeamResults;
