export const setItemActive = ({
  item,
  list,
  callback
}: {
  item: { id: number };
  list: Array<any>;
  callback?: React.Dispatch<any>;
}): void => {
  const itemIndex = list.findIndex((i: { id: number }) => i.id === item.id);
  let newList = [...list];
  newList[itemIndex] = {
    ...newList[itemIndex],
    isActive: !newList[itemIndex].isActive
  };

  if (callback) {
    callback(newList);
  }
};

export const isNumberOdd = (number: number): boolean => {
  return number % 2 === 0;
};

export const setItemInactive = ({
  list,
  callback
}: {
  list: Array<any>;
  callback?: React.Dispatch<any>;
}): void => {
  const itemIndex = list.findIndex(
    (i: { isActive: boolean }) => i.isActive === true
  );

  if (itemIndex !== -1) {
    let newList = [...list];
    newList[itemIndex] = {
      ...newList[itemIndex],
      isActive: false
    };

    if (callback) {
      callback(newList);
    }
  }
};

export const isItemActive = (list: Array<any>): boolean =>
  list.some((item: { isActive: boolean }) => item.isActive === true);

export const findHighestValue2 = (
  list: number[][]
): { pos: string; value: string } => {
  const maxValue = Math.max(...list.map(r => r[1]));
  const minValue = Math.min(...list.map(r => r[1]));

  const value = Math.abs(maxValue) > Math.abs(minValue) ? maxValue : minValue;
  const pos = list.find((n: number[]) => n[1] === value);

  return {
    pos: pos ? pos[0].toFixed(2) : '0',
    value: value.toFixed(2)
  };
};

export const getUnitLabel = (type: string): string => {
  switch (type) {
    case 'max-moment-reaction':
    case 'max-bending-moment':
      return 'kN.m';
    case 'max-displacement':
      return 'mm';
    default:
      return 'kN';
  }
};
