import { FC, memo, createRef } from 'react';
import { IonCard } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import * as S from './style';
import { Figure } from 'react-plotly.js';

const svg64 = require('svg64');

function svgString2Image(
  svgString: string,
  width: number,
  height: number,
  format: string,
  callback: any
): void {
  format = format ? format : 'png';
  const svgData = svgString;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  const image = new Image();

  image.onload = function () {
    context?.clearRect(0, 0, width, height);
    context?.drawImage(image, 0, 0, width, height);
    const pngData = canvas.toDataURL('image/' + format);
    callback(pngData);
  };

  image.src = svgData;
}

interface Props {
  title: string;
  swlValue?: string;
  data: number[][];
  setImages?: React.Dispatch<
    React.SetStateAction<Array<{ name: string; image: string }>>
  >;
  reversed?: boolean;
}

const getChartValues = (
  data: number[][],
  swlValue: string | undefined
): {
  x: number[];
  y: number[];
  swlValuesPositive: number[];
  swlValuesNegative: number[];
} => {
  const x: number[] = [];
  const y: number[] = [];
  const swlValuesPositive: number[] = [];
  const swlValuesNegative: number[] = [];

  for (let z = 0; z < data.length; z++) {
    x.push(Math.round(data[z][0] * 100) / 100);
    y.push(Math.round(data[z][1] * 100) / 100);
    if (swlValue) {
      swlValuesPositive.push(parseFloat(swlValue));
      swlValuesNegative.push(-parseFloat(swlValue));
    }
  }

  return {
    x,
    y,
    swlValuesPositive,
    swlValuesNegative
  };
};

const ScatterChartCard: FC<Props> = ({
  title,
  data,
  swlValue,
  setImages,
  reversed = false
}): JSX.Element => {
  const { t } = useTranslation();
  const { x, y, swlValuesPositive, swlValuesNegative } = getChartValues(
    data,
    swlValue
  );
  const responsiveChartRef: any = createRef();

  const setImage = (image: string): void => {
    if (setImages) {
      setImages(prev => [
        ...prev,
        {
          name: t(title),
          image
        }
      ]);
    }
  };

  const getChartImage = (figure: Figure, graphDiv: Readonly<HTMLElement>) => {
    const svgImg =
      graphDiv.firstElementChild?.firstElementChild?.firstElementChild;

    if (svgImg) {
      const base64fromSVG = svg64(svgImg);
      svgString2Image(
        base64fromSVG,
        500,
        300,
        'png',
        function (pngData: string) {
          setImage(pngData);
        }
      );
    }
  };

  const chartdata: any =
    swlValue !== '0'
      ? [
          {
            x,
            y,
            type: 'scatter',
            // marker: { color: '#808080' },
            name: 'Values',
            fill: 'tonexty',
            // colorbar: 'blue',
            line: { width: 2 }
          },
          {
            x,
            y: swlValuesPositive,
            type: 'scatter',
            marker: { color: '#f54248' },
            name: 'SWL',
            line: { width: 2 }
          },
          {
            x,
            y: swlValuesNegative,
            type: 'scatter',
            marker: { color: '#f54248' },
            name: 'SWL',
            showlegend: false,
            line: { width: 2 }
          }
        ]
      : [
          {
            x,
            y,
            type: 'scatter',
            // marker: { color: '#808080' },
            name: 'Values',
            fill: 'tonexty',
            // colorbar: 'blue',
            line: { width: 2 }
          }
        ];

  return (
    <IonCard ref={responsiveChartRef}>
      <S.LineChartContent>
        <S.PlotChart
          onInitialized={(figure, graphDiv) => getChartImage(figure, graphDiv)}
          data={chartdata}
          layout={{
            autosize: true,
            title: t(title),
            yaxis: { autorange: reversed ? 'reversed' : undefined }
          }}
          useResizeHandler
        />
      </S.LineChartContent>
    </IonCard>
  );
};

// prevents the component from rendering.
const areEqual = (prevProps: Props, nextProps: Props) => true;

export default memo(ScatterChartCard, areEqual);
