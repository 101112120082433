import styled from 'styled-components';
import { IonImg } from '@ionic/react';

export const Image = styled(IonImg)`
  width: 256px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;
