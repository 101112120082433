import {
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import ReactGa from 'react-ga';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import * as S from './style';
import EditAccountForm from './EditAccountForm';
import Button from 'components/Button/Button';

interface Props {
  showModal: boolean;
  name: string | null;
  onDidDismiss: () => void;
  onDone: (name: string | null) => void;
}

const EditAccountModal: React.FC<Props> = ({
  showModal,
  onDidDismiss,
  onDone,
  name
}) => {
  const { t } = useTranslation();
  const handleDone = (name: string | null): void => {
    onDone(name);
    onDidDismiss();

    ReactGa.event({
      category: 'XBeam - Edit Account Modal',
      action: 'User pressed "dismiss" button'
    });
  };

  return (
    <IonModal
      swipeToClose={true}
      onDidDismiss={onDidDismiss}
      isOpen={showModal}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('edit-account')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <EditAccountForm name={name} onDone={handleDone} />
        <Button
          type="button"
          color="danger"
          expand="block"
          onClick={onDidDismiss}
        >
          {t('close')}
        </Button>
      </IonContent>
    </IonModal>
  );
};

export default EditAccountModal;
