import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonGrid,
  IonIcon,
  IonRow
} from '@ionic/react';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { MaterialProps } from '../../interfaces';
import * as S from './styles';

interface SavedMaterialsCardProps {
  onDelete: (materialId: string) => void;
  onEdit: (materialId: MaterialProps) => void;
  materials: MaterialProps[];
}

const SavedMaterialsCard: React.FC<SavedMaterialsCardProps> = ({
  materials,
  onDelete,
  onEdit
}) => {
  const { t } = useTranslation();

  return (
    <IonCard>
      <IonCardHeader>{t('edit the values')}</IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <S.HeaderNameCol>{t('name')}</S.HeaderNameCol>
            <S.HeaderAttrCol>{t('moment of inertia (mm4)')}</S.HeaderAttrCol>
            <S.HeaderAttrCol>{t('elastic modulus (GPa)')}</S.HeaderAttrCol>
            <S.HeaderActionsCol>{t('actions')}</S.HeaderActionsCol>
          </IonRow>
          {materials.map(
            (material, i): JSX.Element => (
              <IonRow key={material.id}>
                <S.FirstCol colored={i % 2 === 0}> {material.name} </S.FirstCol>
                <S.MiddleCol colored={i % 2 === 0}>
                  {material.inertiaMoment}
                </S.MiddleCol>
                <S.MiddleCol colored={i % 2 === 0}>
                  {material.elasticModulus}
                </S.MiddleCol>
                <S.LastCol colored={i % 2 === 0}>
                  <S.EditButton
                    size="small"
                    color="tertiary"
                    fill="clear"
                    onClick={(): void => onEdit(material)}
                  >
                    <IonIcon slot="start" icon={pencilOutline} />
                  </S.EditButton>
                  <S.DeleteButton
                    size="small"
                    color="danger"
                    fill="clear"
                    onClick={(): void => onDelete(material.id)}
                  >
                    <IonIcon slot="start" icon={trashOutline} />
                  </S.DeleteButton>
                </S.LastCol>
              </IonRow>
            )
          )}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default SavedMaterialsCard;
