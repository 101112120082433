import styled from 'styled-components';
import { IonButton, IonCol } from '@ionic/react';

export const FirstCol = styled(IonCol)<{
  colored?: boolean;
}>`
  background-color: ${({ colored }) => (colored ? 'aliceblue' : '#fff')};
`;

export const MiddleCol = styled(IonCol)<{
  colored?: boolean;
}>`
  text-align: center;
  background-color: ${({ colored }) => (colored ? 'aliceblue' : '#fff')};
`;

export const LastCol = styled(IonCol)<{
  colored?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ colored }) => (colored ? 'aliceblue' : '#fff')};
  padding: 0;
`;

export const HeaderNameCol = styled(IonCol)`
  text-align: left;
  font-weight: bold;
`;

export const HeaderDateCol = styled(IonCol)`
  text-align: center;
  font-weight: bold;
`;

export const HeaderActionsCol = styled(IonCol)`
  text-align: right;
  font-weight: bold;
`;

export const EditButton = styled(IonButton)`
  margin: 0;
`;

export const DeleteButton = styled(IonButton)`
  margin: 0;
`;
