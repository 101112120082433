import styled from 'styled-components';
import { IonFooter, IonInput, IonLabel } from '@ionic/react';

export const Footer = styled(IonFooter)`
  text-align: center;
  padding-bottom: 40px;
`;

export const NoSections = styled(IonLabel)`
  display: block;
  text-align: center;
  margin-top: 20px;
`;

export const Label = styled(IonLabel)`
  max-width: 220px !important;
`;
