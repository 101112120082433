import { createContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { auth } from '../firebase';

export const UserContext = createContext<{
  user: firebase.User | null;
  loading: boolean;
}>({ user: null, loading: false });

export const AuthProvider = ({
  children
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const [user, setUser] = useState<{
    user: firebase.User | null;
    loading: boolean;
  }>(() => {
    const user = auth.currentUser;
    return {
      loading: !user,
      user
    };
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      (currentUser: firebase.User | null) => {
        setUser({ user: currentUser, loading: false });
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ ...user }}>{children}</UserContext.Provider>
  );
};
