import { useEffect, useState } from 'react';
import { app } from '../firebase';

const usePDF = (): {
  fileUrl: string;
  uploadFile: (file: Blob, fileName: string) => void;
} => {
  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect(() => {}, [fileUrl]);

  const uploadFile = (file: Blob, fileName: string): void => {
    const storageRef = app.storage().ref(fileName);

    storageRef.put(file).then(async snapShot => {
      const url = await snapShot.ref.getDownloadURL();

      setFileUrl(url);
    });
  };

  return {
    fileUrl,
    uploadFile
  };
};

export default usePDF;
