import { db } from '../firebase';

const beamsRef = db.collection('beams');

export const addBeam = async (userId: string, fields: any): Promise<void> => {
  try {
    await beamsRef.add({
      userId,
      id: `${userId}${fields.date}`,
      ...fields
    });
  } catch (error) {
    throw error;
  }
};

export const getBeams = async (userId: string): Promise<any> => {
  try {
    const beams = await beamsRef.where('userId', '==', userId).get();

    return beams.docs;
  } catch (error) {
    throw error;
  }
};

export const deleteBeam = async (beamId: string): Promise<any> => {
  try {
    await beamsRef
      .where('id', '==', beamId)
      .get()
      .then(querySnapshot => {
        querySnapshot.docs[0].ref.delete();
      });
  } catch (error) {
    throw error;
  }
};
