import { useContext, useEffect, useState } from 'react';
import { IonContent, IonItem } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import SavedMaterialsCard from 'components/SavedMaterialsCard';
import MaterialsModal from 'components/MaterialsModal/MaterialsModal';
import MaterialsAddModal from 'components/MaterialsAddModal/MaterialsAddModal';
import StoreCards from 'components/StoreCards/StoreCards';
import { UserContext } from 'providers/AuthProvider';
import { getMaterials, updateUser } from 'providers/UserProvider';
import Button from 'components/Button/Button';

type MaterialType = {
  name: string;
  id: string;
  inertiaMoment: string;
  elasticModulus: string;
  area?: string;
  maxShearForce?: string;
  maxMoment?: string;
  maxReaction?: string;
};

const SavedMaterials = (): JSX.Element => {
  const { user, loading } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeMaterial, setActiveMaterial] = useState<
    MaterialType | undefined
  >(undefined);
  const [activeAddMaterial, setActiveAddMaterial] = useState<boolean>(false);
  const [materials, setMaterials] = useState<MaterialType[]>([]);

  useEffect((): void => {
    if (loading === false && user === null) {
      navigate('/beam');
    }
  }, [user, loading, navigate]);

  useEffect((): void => {
    if (user) {
      getMaterials(user?.uid).then(res => {
        const data = res.data();

        if (data.materials) {
          setMaterials(data.materials);
        }
      });
    }
  }, [user]);

  const handleDelete = (materialId: string): void => {
    if (user) {
      const filteredMaterials = materials.filter(
        material => material.id !== materialId
      );

      updateUser(user?.uid, {
        materials: filteredMaterials
      }).then(() => {
        setMaterials(filteredMaterials);
      });
    }
  };

  const handleEdit = (material: MaterialType): void => {
    setActiveMaterial(material);
  };

  const handleMaterialChangeDismiss = (): void => {
    setActiveMaterial(undefined);
  };

  const handleMaterialAddDismiss = (): void => {
    setActiveAddMaterial(false);
  };

  const handleMaterialsChange = (
    inertiaMoment: string,
    elasticModulus: string,
    name: string,
    area: string | undefined,
    maxShearForce: string | undefined,
    maxMoment: string | undefined,
    maxReaction: string | undefined
  ): void => {
    const id = activeMaterial?.id;
    // const name = activeMaterial?.name;

    if (user && id && name) {
      const filteredMaterials = materials.filter(
        material => material.id !== id
      );

      const editedMaterial: MaterialType = {
        name,
        id,
        inertiaMoment,
        elasticModulus,
        area: area !== undefined ? area : '0',
        maxShearForce: maxShearForce !== undefined ? maxShearForce : '0',
        maxMoment: maxMoment !== undefined ? maxMoment : '0',
        maxReaction: maxReaction !== undefined ? maxReaction : '0'
      };

      updateUser(user?.uid, {
        materials: [editedMaterial, ...filteredMaterials]
      }).then(() => {
        setMaterials([editedMaterial, ...filteredMaterials]);
      });
    }
  };

  const handleAddMaterial = (
    inertiaMoment: string,
    elasticModulus: string,
    name: string,
    area: string | undefined,
    maxShearForce: string | undefined,
    maxMoment: string | undefined,
    maxReaction: string | undefined
  ): void => {
    try {
      if (user !== null) {
        const material = {
          name,
          id: new Date().toISOString(),
          inertiaMoment,
          elasticModulus,
          area: area !== undefined ? area : '0',
          maxShearForce: maxShearForce !== undefined ? maxShearForce : '0',
          maxMoment: maxMoment !== undefined ? maxMoment : '0',
          maxReaction: maxReaction !== undefined ? maxReaction : '0'
        };

        const materialsFiltered = [material, ...materials];

        updateUser(user?.uid, {
          materials: materialsFiltered
        }).then(() => {
          setMaterials(materialsFiltered);
        });
        alert(t('beam material successfully'));
      }
    } catch (error) {
      alert(t('beam material unsuccessfully'));
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('saved-materials')} - XBeam</title>
      </Helmet>
      <MaterialsModal
        onDone={handleMaterialsChange}
        onDidDismiss={handleMaterialChangeDismiss}
        showModal={activeMaterial !== undefined}
        elasticModulusInitialValue={activeMaterial?.elasticModulus}
        inertiaMomentInitialValue={activeMaterial?.inertiaMoment}
        nameInitialValue={activeMaterial?.name}
        areaInitialValue={activeMaterial?.area}
        momentInitialValue={activeMaterial?.maxMoment}
        maxReactionInitialValue={activeMaterial?.maxReaction}
        maxShearForceInitialValue={activeMaterial?.maxShearForce}
      />
      <MaterialsAddModal
        onDone={handleAddMaterial}
        onDidDismiss={handleMaterialAddDismiss}
        showModal={activeAddMaterial}
      />
      <Header />
      <IonContent className="ion-padding">
        <IonItem lines="none">
          <h1>{t('saved-materials')}</h1>
        </IonItem>
        {materials && materials.length > 0 && (
          <SavedMaterialsCard
            materials={materials}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )}
        <Button
          onClick={(): void => {
            setActiveAddMaterial(true);
          }}
          color="tertiary"
          expand="full"
          shape="round"
        >
          {t('saved-add-new-material')}
        </Button>
        <StoreCards />
      </IonContent>
      <Footer />
    </>
  );
};

export default SavedMaterials;
