import { useContext, useEffect } from 'react';
import { IonContent, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactGa from 'react-ga';
import { UserContext } from 'providers/AuthProvider';
import { Capacitor } from '@capacitor/core';
import { Link, useNavigate } from 'react-router-dom';
import RegisterForm from 'components/RegisterForm/RegisterForm';
import Footer from 'components/Footer/Footer';
import SecondaryHeader from 'components/SecondaryHeader/SecondaryHeader';
import Button from 'components/Button/Button';
import { logoGoogle } from 'ionicons/icons';
import { createUserWithGoogle } from '../../providers/UserProvider';

const Register = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      navigate('/');
    }
  }, [navigate, user]);

  const loginWithGoogle = async (): Promise<void> => {
    try {
      await createUserWithGoogle();

      navigate('/beam');

      ReactGa.event({
        category: 'XBeam - Register',
        action: 'User pressed "sign in with google" button'
      });
    } catch (error) {
      // TODO: error handling
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('register')} - XBeam</title>
        <meta name="description" content={t('meta description - register')} />
      </Helmet>
      <SecondaryHeader />
      <IonContent className="ion-padding">
        <IonItem lines="none">
          <h1>{t('new-account')}</h1>
        </IonItem>
        <RegisterForm />
        {Capacitor.getPlatform() !== 'ios' && (
          <Button expand="block" fill="outline" onClick={loginWithGoogle}>
            <IonIcon slot="start" icon={logoGoogle} />
            {t('new-account-goole')}
          </Button>
        )}

        <IonItem lines="none" className="ion-text-center">
          <IonLabel>{t('OR')}</IonLabel>
        </IonItem>
        <Link
          className="link-button-secondary"
          to="/login"
          onClick={(): void => {
            ReactGa.event({
              category: 'XBeam - Register',
              action: 'User pressed "login" button'
            });
          }}
        >
          {t('login')}
        </Link>
      </IonContent>
      <Footer />
    </>
  );
};

export default Register;
