import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import ReactGa from 'react-ga';
import {
  addOutline,
  closeCircleOutline,
  pencilOutline,
  trashOutline
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setItemInactive } from '../../utils';
import { LoadProps } from '../../interfaces';

import * as S from './style';

interface Props {
  beam?: number;
  isActive: boolean;
  loads: LoadProps[];
  setLoads: React.Dispatch<React.SetStateAction<LoadProps[]>>;
  showLoadAlert: boolean;
  setShowLoadAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const getInitialValue = (
  loads: LoadProps[],
  prop: 'position' | 'force' | 'moment'
): any => {
  const activeItem =
    loads && loads.find((sup: LoadProps) => sup.isActive === true);

  return activeItem !== undefined ? activeItem[prop] : undefined;
};

const LoadModal: React.FC<Props> = ({
  beam,
  isActive,
  loads,
  setLoads,
  showLoadAlert,
  setShowLoadAlert
}) => {
  const { t } = useTranslation();
  const [supportPosition, setLoadPosition] = useState<number | undefined>(
    getInitialValue(loads, 'position')
  );
  const [forceValue, setForceValue] = useState<number | undefined>(
    getInitialValue(loads, 'force')
  );
  const [valueMoment, setValueMoment] = useState<number | undefined>(
    getInitialValue(loads, 'moment')
  );

  const onDidDismiss = (): void => {
    setShowLoadAlert(false);
    setItemInactive({ list: loads, callback: setLoads });
    setLoadPosition(undefined);
    setForceValue(undefined);
    setValueMoment(undefined);
  };

  useEffect(() => {
    setLoadPosition(getInitialValue(loads, 'position'));
    setForceValue(getInitialValue(loads, 'force'));
    setValueMoment(getInitialValue(loads, 'moment'));
  }, [loads]);

  return (
    <IonModal onDidDismiss={onDidDismiss} isOpen={showLoadAlert}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('load')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position="floating">{`${t('position')} (m)`}</IonLabel>
          <IonInput
            value={supportPosition}
            type="number"
            inputMode="numeric"
            placeholder={`${t('position')} (m)`}
            onIonChange={(e: any) => setLoadPosition(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">{`${t('insert load')} (kN)`}</IonLabel>
          <IonInput
            value={forceValue}
            type="number"
            inputMode="numeric"
            placeholder={`${t('insert load')} (kN)`}
            onIonChange={(e: any) => setForceValue(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">
            {`${t('feature five')} (kN.m)`}
          </IonLabel>
          <IonInput
            value={valueMoment}
            type="number"
            inputMode="numeric"
            placeholder={`${t('feature five')} (kN.m)`}
            onIonChange={(e: any) => setValueMoment(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonContent>
      <S.Footer>
        {isActive ? (
          <>
            <IonButton color="primary" onClick={onDidDismiss}>
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              color="danger"
              onClick={(): void => {
                setLoads(loads.filter(({ isActive }) => isActive !== true));
                setShowLoadAlert(false);
                ReactGa.event({
                  category: 'XBeam - Load Modal',
                  action: 'User pressed "remove" button'
                });
              }}
            >
              <IonIcon slot="start" icon={trashOutline} />
              {t('remove')}
            </IonButton>
            <IonButton
              color="tertiary"
              onClick={() => {
                const position =
                  typeof supportPosition === 'string'
                    ? parseFloat(supportPosition)
                    : supportPosition;
                const force =
                  typeof forceValue === 'string'
                    ? parseFloat(forceValue)
                    : forceValue;
                const moment =
                  typeof valueMoment === 'string'
                    ? parseFloat(valueMoment)
                    : valueMoment;

                if (
                  beam &&
                  position !== undefined &&
                  position <= beam &&
                  position >= 0 &&
                  showLoadAlert
                ) {
                  if (force || moment) {
                    const itemIndex = loads.findIndex(
                      (i: LoadProps) => i.isActive === true
                    );
                    let newLoads: LoadProps[] = [...loads];

                    newLoads[itemIndex] = {
                      ...loads[itemIndex],
                      position,
                      moment: moment ? Math.round(moment * 100) / 100 : 0,
                      force: force ? Math.round(force * 100) / 100 : 0
                    };
                    setLoads(newLoads);
                  }
                } else {
                  alert(t('beam error three'));
                }
                setShowLoadAlert(false);
                ReactGa.event({
                  category: 'XBeam - Load Modal',
                  action: 'User pressed "edit" button'
                });
              }}
            >
              <IonIcon slot="start" icon={pencilOutline} />
              {t('edit')}
            </IonButton>
          </>
        ) : (
          <>
            <IonButton color="danger" onClick={onDidDismiss}>
              <IonIcon slot="start" icon={closeCircleOutline} />
              {t('cancel')}
            </IonButton>
            <IonButton
              onClick={(): void => {
                const position =
                  typeof supportPosition === 'string'
                    ? parseFloat(supportPosition)
                    : supportPosition;
                const force =
                  typeof forceValue === 'string'
                    ? parseFloat(forceValue)
                    : forceValue;
                const moment =
                  typeof valueMoment === 'string'
                    ? parseFloat(valueMoment)
                    : valueMoment;

                if (
                  beam &&
                  position !== undefined &&
                  position <= beam &&
                  position >= 0
                ) {
                  if (moment || force) {
                    setLoads((prevLoads: LoadProps[]): LoadProps[] => [
                      ...prevLoads,
                      {
                        position,
                        moment: moment ? Math.round(moment * 100) / 100 : 0,
                        force: force ? Math.round(force * 100) / 100 : 0,
                        id: Math.floor(Math.random() * Date.now())
                      }
                    ]);
                  }
                } else {
                  alert(t('beam error three'));
                }
                onDidDismiss();
                ReactGa.event({
                  category: 'XBeam - Load Modal',
                  action: 'User pressed "add" button'
                });
              }}
            >
              <IonIcon slot="start" icon={addOutline} />
              {t('add')}
            </IonButton>
          </>
        )}
      </S.Footer>
    </IonModal>
  );
};

export default LoadModal;
