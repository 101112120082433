import React, { useContext } from 'react';
import ReactGa from 'react-ga';
import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { logInOutline } from 'ionicons/icons';
import { UserContext } from 'providers/AuthProvider';
import AccountDropdown from 'components/AccountDropdown';
import * as S from './styles';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { user, loading } = useContext(UserContext);

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton style={{ color: '#000' }} />
        </IonButtons>
        <IonTitle>XBeam</IonTitle>
        <IonButtons slot="end">
          {loading === false && user !== null && (
            <AccountDropdown name={user?.displayName} />
          )}
          {loading === false && user === null && (
            <S.Link
              className="link black-color"
              to="/login"
              onClick={(): void => {
                ReactGa.event({
                  category: 'XBeam - Header',
                  action: 'User pressed "login" button'
                });
              }}
            >
              <IonText>{t('login')}</IonText>
              <S.LoginIcon icon={logInOutline} />
            </S.Link>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
