import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { addOutline, closeCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './style';

interface Props {
  onSave: (name: string) => void;
  isActive: boolean;
  setShowSaveModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BeamSaveModal: React.FC<Props> = ({
  onSave,
  isActive,
  setShowSaveModal
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');

  const handleDismiss = (): void => setShowSaveModal(false);

  return (
    <IonModal onDidDismiss={handleDismiss} isOpen={isActive}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('beam-save')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position="floating">{t('name')}</IonLabel>
          <IonInput
            value={name}
            type="text"
            onIonChange={(e: any) => setName(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonContent>
      <S.Footer>
        <IonButton color="danger" onClick={handleDismiss}>
          <IonIcon slot="start" icon={closeCircleOutline} />
          {t('cancel')}
        </IonButton>
        <IonButton
          onClick={(): void => {
            if (name) {
              onSave(name);
              handleDismiss();
              setName('');
            }
            // TODO: Error handling
          }}
        >
          <IonIcon slot="start" icon={addOutline} />
          {t('save')}
        </IonButton>
      </S.Footer>
    </IonModal>
  );
};

export default BeamSaveModal;
